.completed {
  border: 2px solid currentcolor;
  z-index: 1;
  width: 24px;
  height: 24px;
  font-size: 18px;
  border-radius: 50%;
}

.outter-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid currentcolor;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root-dot {
  display: flex;
  height: 22px;
  align-items: center;
  color: var(--gray-200);
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: currentcolor;
  display: inline-block;
}

.alternativeLabel {
  top: 10px;
  left: calc(-50% + 12px);
  right: calc(50% + 12px);
}
