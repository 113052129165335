.user-info-container {
  position: relative;

  .user-info-wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    cursor: pointer;

    .user-account-no-avatar {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: relative;
      background-color: var(--primary-25);

      svg {
        width: 55%;
      }

      .cart-indicator {
        width: 8px;
        height: 8px;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50%;
      }
    }

    .user-first-name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-left: 8px;
      margin-right: 2px;
      color: var(--gray-600);
    }

    .user-account {
      cursor: pointer;
      max-width: 220px;
      z-index: 5;
      margin-left: 20px;
      font-size: 16px;
      border-radius: 10px;
      padding: 10px;
      font-weight: 300;
      color: white;
      display: flex;
      width: 37px;
      height: 31px;
      justify-content: center;
      position: relative;
      align-items: center;
      //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-user-select: none;
      user-select: none;

      .icon-arrow-down {
        display: inline !important;
        font-size: 20px;
      }
    }
  }
}

.user-info-wrapper {
  display: flex;
  align-items: center;
  text-align: center;

  .user-account-no-avatar {
    background-color: var(--primary-25);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user-account {
    cursor: pointer;
    max-width: 220px;
    z-index: 5;
    margin-left: 20px;
    font-size: 16px;
    border-radius: 10px;
    padding: 10px;
    font-weight: 300;
    color: white;
    display: flex;
    width: 37px;
    height: 31px;
    justify-content: center;
    position: relative;
    align-items: center;
    //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-user-select: none;
    user-select: none;

    .icon-arrow-down {
      display: inline !important;
      font-size: 20px;
    }
  }
}

.options {
  z-index: 6;
  display: flex;
  flex-direction: column;
  height: max-content;
  box-shadow: 0 20px 24px -4px rgba(16 24 40 / 10%),
    0 8px 8px -4px rgba(16 24 40 / 4%);
  border-radius: 5px;
  background-color: white;
  width: max-content;
  position: absolute;
  right: 2px;
  top: 40px;
  padding: 16px 0;

  .option {
    padding: 8px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    height: 48px;
    border-radius: 5px;
    color: var(--gray-600);

    .user-menu-icon {
      height: 24px;
      width: 24px;
      min-width: 24px;
      margin-right: 20px;

      .user-menu-icon-sizer {
        height: 20px;
        width: 20px;

        svg {
          width: 100%;
          height: 100%;
          color: var(--primary-600);
        }
      }
    }

    .option-text {
      width: 100%;
    }
  }

  .option:hover {
    color: var(--primary-600);
  }
}
