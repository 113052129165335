.app-mobile-topbar {
  display: none;
}

.drawer-wrapper {
  display: none;
}

@media (max-width: 834px) {
  .app-mobile-topbar {
    box-shadow: 0 12px 16px -4px rgba(16 24 40 / 10%),
      0 4px 6px -2px rgba(16 24 40 / 5%);
    position: sticky;
    z-index: 4;
    top: 0;
    width: 100%;
    flex: 1;
    height: 80px;
    padding: 0 20px;
    background: var(--blue-gray-50);
    border-bottom: 1px solid var(--gray-25);
    display: flex;
    align-items: center;

    .topbar-logo {
      margin-right: 24px;
      display: flex;
      align-items: center;

      .event-logo-wrapper {
        display: flex;
        max-height: 32px;
        min-height: 32px;
        min-width: 32px;
        height: 32px;

        img {
          border-radius: 4px;
        }

        .event-logo {
          height: 100%;
          align-self: center;
          object-fit: contain;
          object-position: left;
        }
      }

      .event-name {
        padding-left: 12px;
        font-size: 14px;
        text-align: left;
        font-weight: 600;
        color: var(--gray-900);
        overflow: hidden;
        flex: 1;
        text-overflow: clip;
      }
    }

    .topbar-actions {
      margin-left: 42px;
      display: flex;
      justify-content: flex-end;
      flex: 1;
      align-items: center;
      position: relative;

      .close-indicator-wrapper {
        cursor: pointer;
        margin-left: 24px;
      }

      .cart-indicator-wrapper {
        cursor: pointer;
        margin-left: 24px;

        .cart-indicator {
          width: 8px;
          height: 8px;
          position: absolute;
          top: 5px;
          right: -1px;
          border-radius: 50%;
        }
      }
    }
  }

  .drawer-wrapper {
    display: initial;
    height: 100%;
    position: relative;
    overflow: auto;

    .top-drawer-section {
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;

      &.logged-in {
        height: calc(100% - 86px);
      }

      &.logged-out {
        height: calc(100% - 140px);
      }

      .account-drawer {
        top: 0;
        width: 100%;
        background-color: white;
        z-index: 1;
        border-bottom: 2px solid var(--gray-50);
        padding: 10px 0;

        div:first-child {
          box-shadow: none;
        }
      }

      .nav-drawer {
        background-color: white;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        &.logged-in {
          height: calc(100% - 86px);
        }

        &.logged-out {
          height: calc(100% - 140px);
        }

        .nav-link {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--gray-600);
          width: 100%;
          padding: 16px;
          cursor: pointer;

          &:hover,
          &:focus {
            text-decoration: underline;
            text-decoration-color: var(--gray-900);
            color: var(--gray-900);
          }
        }
      }
    }

    .actions-drawer {
      background-color: white;
      bottom: 0;
      z-index: 3;
      position: fixed;
      width: 100%;
      border-top: 2px solid var(--gray-50);
      display: flex;
      flex-direction: column;
      padding: 20px 16px;
      align-items: center;
      row-gap: 10px;

      .login-nav {
        width: 100%;
        cursor: pointer;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        height: 44px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center !important;
        color: white;
        background-color: var(--primary-600);
        text-transform: none;
        box-shadow: none;
        padding: 10px 18px;
      }

      .registration-nav {
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 8px;
        height: 44px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center !important;
      }

      .contact-button {
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center !important;
      }
    }
  }

  .MuiDrawer-root {
    &.MuiDrawer-modal {
      z-index: 3 !important;

      .MuiBackdrop-root {
        background-color: initial;
      }

      .MuiPaper-root {
        &.MuiDrawer-paper {
          height: 100%;
          padding-top: 80px;
        }
      }
    }
  }
}
