.Collapsible {
  color: #646471;
  margin: 15px 0;
  margin: 15px auto;
}

.Collapsible__trigger {
  padding: 0 10px;
  cursor: pointer;
}

.component-billing-payments {
  .Collapsible {
    border-bottom: 1px solid var(--gray-25);
  }
}

.terms-of-service {
  margin-top: 14px;
  color: var(--gray-500);
  text-align: center;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-top: 8px;
  margin-bottom: 20px;
  input {
    z-index: unset;
  }
  .terms-of-service-url {
    color: var(--primary-600);
    text-decoration: underline;

    &:hover {
      color: var(--primary-700);
    }
  }
}

.terms-not-agreed-error {
  margin-top: -14px;
  color: var(--error-500);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.total-costs {
  align-self: baseline;
  flex: 1;

  .total-costs-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: var(--gray-700);
    padding-bottom: 31px;
  }

  .individual-costs {
    th,
    td {
      font-size: 14px;
      font-weight: 400;
    }

    th {
      font-weight: 500;
    }

    td {
      text-align: left;
    }

    .kmb-paidAmount {
      font-weight: 500;
      text-align: right;

      div {
        display: inline;
      }
    }
  }

  .subscription,
  .products {
    display: flex;
    justify-content: right;
    gap: 40px;
  }

  th,
  td {
    font-size: 20px;
    font-weight: bold;
  }

  td {
    text-align: right;
  }
}

.billing-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .navigate-back-payments {
    width: 85%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-500);
    margin-bottom: 28px;

    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }

    &:hover {
      color: var(--primary-600);
      text-decoration: underline;
    }
  }

  .inner-centering-wrapper {
    width: 85%;
    padding: 15px;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    min-height: 400px;
    row-gap: 24px;
    column-gap: 32px;

    .payment-section-header {
      width: 100%;

      .payment-section-title {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
        color: var(--gray-700);
      }

      .payment-section-text {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: var(--gray-500);
        padding-bottom: 32px;
      }
    }

    .total-costs {
      flex: 1 1;
    }

    .all-payment-ways {
      flex-basis: 45%;
      align-self: baseline;
      display: flex;
      flex-direction: column;
    }

    .total-costs,
    .all-payment-ways {
      .section-title {
        display: none;
      }
    }
  }
}

.all-payment-ways {
  .agree-to-terms-error {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: var(--error-500);
  }

  .total-costs-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: var(--gray-700);
    padding-bottom: 16px;
  }

  .payment-settings {
    margin-left: 30px;
  }

  #submit-payment {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .radio-positioner {
    .MuiFormControlLabel-root {
      .MuiButtonBase-root {
        align-self: baseline;
        margin-top: 12px;
      }
    }
  }

  .warning-message {
    width: 100%;
    border: 1px solid var(--warning-500);
    background-color: var(--warning-50);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 16px;

    .warning-icon-wrapper {
      display: flex;
      align-items: baseline;

      .warning-icon {
        color: var(--gray-400);
      }
    }

    .warning-message-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding-left: 16px;
      color: var(--gray-500);
    }
  }
}

.submit-payment {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: none !important;
  display: flex;
  justify-content: center !important;
  align-items: center;
}

.payment-info-text {
  width: 100%;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: var(--gray-500);
  padding-bottom: 32px;
}

.email-support {
  display: inline;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--blue-800);
  }
}

.payment-ways {
  label {
    margin-top: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-700);
  }

  .payment-way-title {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .payment-icon {
      padding-right: 8px;
    }
  }

  .card-images {
    margin-left: -15px;
    margin-top: 26px;
    margin-bottom: 8px;
    display: flex;
  }

  p {
    color: #9b9b9b;
  }

  .payment-way-explanation {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-500);
    margin-bottom: 4px;
  }
}

.iban-wrapper {
  color: var(--gray-700);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 24px;

  .iban-copy-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    width: 16px;
    height: 16px;
    cursor: pointer;

    .iban-copy-icon {
      width: 16px;
      height: 16px;
    }
  }
}

.bank-policy-message {
  border: 1px solid;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  margin-bottom: 24px;
  color: var(--gray-500);

  .bank-policy-message-icon {
    margin-right: 12px;
  }
}

.bank-message {
  color: var(--gray-700);
  margin-bottom: 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

#card-option {
  .form-group {
    padding: 0 3px;

    & > input {
      outline: none !important;
      border: 1px solid var(--gray-300) !important;
      border-radius: 4px;
    }

    div {
      .css-yk16xz-control {
        border: 1px solid var(--gray-300) !important;
      }
    }
  }

  * {
    outline: none !important;
  }

  label {
    font-size: 12px;
  }
}

.component-billing {
  .electronic-payments {
    padding: 50px 32px 25px !important;
  }

  .payment-info-box {
    padding: 10px;
    background: #fcb138;
    margin-bottom: 20px;
    text-align: center;
    color: white;
    font-family: Lato;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      margin-right: 15px;
      font-size: 30px;
      color: white;
      width: 35px;
      height: 35px;
      border-radius: 35px;
      text-align: center;
      padding: 5px;
      margin-bottom: 8px;
    }
  }

  .payment-title {
    color: #404f54;
    font-family: Lato;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 45px;
    padding-top: 35px;
  }

  .payment-subtitle {
    color: #404f54;
    font-family: Lato;
    font-size: 20px;
    font-weight: bold;
    margin-right: 20px;
  }

  .payments-container {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .payment-option {
      .form-control {
        border: none;
        padding: 0;
      }

      display: flex;
      width: 270px;
    }
  }

  .collapsible-title {
    color: #404f54;
    font-family: Lato;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;

    span {
      font-size: 30px;
    }
  }

  .payments-table {
    background-color: white;
    margin-top: -10px;
    padding: 80px 0;
  }
}

.Collapsible__contentOuter {
  color: #646471;
}

.Collapsible__contentInner {
  padding: 15px;
}

p {
  white-space: pre-line;
}

.component-payments {
  max-width: 100%;
  color: #404040;

  .table-container {
    overflow: auto;
  }

  .Collapsible {
    width: 90%;
  }
}

.kmb-paidAmount {
  font-weight: 700;
}

.kmb-receipt {
  .receipt {
    font-size: 13px;
    color: #02a9f4;
    cursor: pointer;
    border: 1px solid #02a9f4;
    padding: 4px;
    margin: auto;
    text-align: center;
    transition: background-color 0.2s ease;
    border-radius: 4px;
  }

  .receipt:hover {
    background-color: rgba(2, 167, 244, 0.126);
  }
}

.kmb-type {
  text-transform: capitalize;
}

@media (max-width: 834px) {
  .billing-wrapper {
    .inner-centering-wrapper {
      flex-direction: column;

      .total-costs,
      .all-payment-ways {
        width: 100%;
        flex: 1;
      }

      .all-payment-ways {
        flex-basis: 0% !important;
      }
    }
  }
}

@media (max-width: 1175px) {
  .kmb-dueAmount,
  .kmb-transactionId {
    display: none;
  }
}

@media (max-width: 834px) {
  .payment-info-text {
    padding-bottom: 0;
    margin-bottom: 44px;
  }
}

@media (max-width: 800px) {
  .card-info-container {
    .col {
      flex-basis: 33%;
    }
  }
}

@media (max-width: 834px) {
  .component-billing {
    .electronic-payments {
      padding: 50px 20px 25px !important;
    }
  }

  .component-billing-payments {
    padding: 0 20px 25px !important;

    .mobile-table {
      display: inline-block;
      width: 100%;

      .card {
        padding: 20px;
        margin-bottom: 20px;

        .button {
          height: 45px;
          border-radius: 4px;
          background-color: var(--primary-600);
          font-size: 17px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 25px;
          text-align: center;
          color: white;
          font-family: Lato;
        }

        .card-name {
          height: 25px;
          color: #404f54;
          font-family: Lato;
          font-size: 18px;
          letter-spacing: 0;
          font-weight: bold;
          line-height: 25px;
          padding-bottom: 40px;
        }

        .card-info-container {
          display: flex;
          flex-wrap: wrap;

          .name {
            display: none;
          }

          .col {
            padding-left: 0;
            flex-basis: 15%;
          }

          .cosmetic {
            display: none;
          }

          .col-name {
            color: #404f54;
            font-family: Lato;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 17px;
            padding: 10px 10px 10px 0;
          }

          .col-entry {
            height: 50px;
            color: #919da4;
            font-family: Lato;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 21px;
          }

          .icon-agenda-added1,
          .icon-agenda-added,
          .icon-no-video,
          .icon-video {
            font-size: 30px;
          }

          .icon-attach-no,
          .icon-attach {
            font-size: 37px;
          }

          .icon-agenda-added1,
          .icon-agenda-added {
            cursor: pointer;
          }
        }
      }
    }

    .Collapsible {
      border-bottom: 1px solid var(--gray-25);
    }
  }
}

.costs-table {
  display: flex;
  flex-direction: column;
  width: 100%;

  .product-cost {
    width: 100%;
    display: block;
    padding: 16px 0;
    border-top: 1px solid var(--gray-25);

    .item-general-info {
      display: flex;
      justify-content: space-between;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      .item-title {
        color: var(--gray-700);
        max-width: 75%;
      }

      .item-price {
        color: var(--gray-700);
      }
    }

    .item-payment-info {
      margin-top: 16px;

      .payments-wrapper {
        color: var(--blue-900);

        .payment-icon {
          margin-right: 6px;
        }
      }
    }
  }
}

.final-cost-sum {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 32px 0;
  padding: 16px 24px;
  background-color: var(--blue-50);
  border-radius: 4px;

  .cost-sum-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: var(--gray-700);
    padding-right: 0;

    .tax-warning {
      color: var(--gray-500);
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-decoration: underline;
    }
  }

  .cost-sum-total {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: var(--gray-700);
  }
}
