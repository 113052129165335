.search-media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: calc(26% - 15px);
  flex-grow: 1;
}

.mini-calendar {
  margin: 0;
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 5px;
  align-items: center;

  & > div {
    flex: 1;
  }

  p {
    margin: 0;
    font-weight: 300;
    color: #404040;
    font-size: 20px;
    line-height: normal;

    &:first-child {
      font-size: 25px;
    }
  }

  li {
    display: inline-block;
    text-align: center;
    padding-right: 8px;
    width: 14.28%;
    height: auto;

    > div::before {
      display: none;
    }
  }

  .top {
    font-size: 17px;
    font-weight: bold;
  }

  .bottom {
    font-size: 12px !important;
  }

  .arrow-card-wrapper {
    .arrow-card {
      border: 1px solid var(--gray-50);
      background-color: white;
      border-radius: 4px;
      height: 44px;
      width: 27px;
      display: flex;
      align-items: center;
      font-size: 25px;
      cursor: pointer;
    }

    .disabled {
      cursor: default;
      color: var(--gray-50);
    }
  }

  .day-wrapper {
    flex-basis: 14%;
    border-radius: 4px;

    &.disabled {
      pointer-events: none;

      .date {
        background-color: var(--gray-50);

        p {
          color: white;
        }
      }
    }

    &.active {
      border: none;

      .date {
        background-color: var(--primary-600);

        p {
          color: white;
        }
      }
    }

    .date-container {
      width: 100%;
      height: 44px;
    }

    .date {
      border-radius: 4px;
      border: 1px solid var(--gray-50);
      cursor: pointer;
      background-color: white;
      //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-user-select: none;
      user-select: none;
      width: 100%;
      height: 44px;
      font-family: Lato;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}

.agenda {
  .session-list {
    padding-left: 50px;
  }

  .sessions {
    padding: 0;
    list-style-type: none;
    margin-top: 30px;

    thead {
      display: none;
    }

    tr:hover {
      td {
        background-color: white;
      }
    }

    td {
      background-color: white;
    }
  }

  .table-container {
    position: relative;

    &::before {
      height: 100%;
      position: absolute;
      content: '';
      width: 2px;
      left: -39px;
      background: #7c7772;
    }
  }

  tbody td.kmb-cosmetic {
    position: relative;

    &::before {
      position: absolute;
      left: -10px;
      top: 19px;
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid white;
    }

    &::after {
      position: absolute;
      left: -57px;
      top: 10px;
      content: '';
      width: 40px;
      height: 40px;
      background-color: var(--primary-600);
      border-radius: 50%;
      border: 10px solid #f9f8f6;
    }
  }
}

.agenda-session {
  border-radius: 2px;
  background-color: white;
}

.select-week {
  margin: 0 0 8px;
  color: #404f54;
  font-size: 16px;

  .navigation {
    padding-left: 15px;

    span {
      cursor: pointer;
      font-size: 35px;

      &:first-child {
        margin-right: 50px;
      }
    }
  }
}

@media (max-width: 1260px) {
  .control-container {
    .agenda {
      width: 50%;
    }

    .rooms {
      width: calc(50% - 15px);
    }

    .search-media-container {
      width: 100%;
    }
  }
}

@media (max-width: 991px) {
  .control-container {
    .agenda,
    .rooms,
    .search-media-container {
      width: 100%;
    }
  }

  .agenda {
    .table-container::before {
      display: none;
    }

    .session-list {
      padding-left: 0;
    }
  }
}
