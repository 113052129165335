.app-topbar {
  position: sticky;
  z-index: 4;
  top: 0;
  width: 100%;
  flex: 1;
  height: 80px;
  background: var(--blue-gray-50);
  border-bottom: 1px solid var(--gray-25);
  align-items: center;
  display: flex;
  justify-content: center;

  .centering-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: var(--desktop-view);

    &.login-appbar {
      max-width: unset;
      padding: 0 20px;
    }

    .topbar-logo {
      margin-right: 24px;
      display: flex;
      align-items: center;

      .event-logo-wrapper {
        display: flex;
        max-height: 32px;
        min-height: 32px;
        min-width: 32px;
        height: 32px;

        img {
          border-radius: 4px;
        }

        .event-logo {
          height: 100%;
          align-self: center;
          object-fit: contain;
          object-position: left;
        }
      }

      .event-name {
        margin-left: 12px;
        font-size: 18px;
        text-align: left;
        font-weight: 600;
        color: var(--gray-900);
        overflow: hidden;
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .topbar-navigation {
      align-self: flex-start;
      display: flex;
      align-items: center;
      height: 80px;

      .navigation-wrapper {
        row-gap: 1px;
        justify-content: space-around;
        overflow: hidden;
        column-gap: 24px;
      }

      .navigation-more {
        margin-left: 26px;
      }

      .navigation-wrapper,
      .navigation-more {
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .nav-item {
          cursor: pointer;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;

          &.extra-feature-nav {
            justify-content: flex-start;
            height: 50px;
          }

          p,
          .extra-features-wrapper > p {
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: inherit;
            margin: 0;

            &.extra-features {
              margin-right: 6px;
            }
          }

          .extra-features-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            .expand-features {
              width: 20px;
              height: 20px;
            }
          }

          &:hover {
            p,
            .extra-features-wrapper > p {
              color: var(--gray-900);
            }
          }

          .extra-features-panel {
            width: max-content;
            padding: 0 24px;
            min-width: 190px;
            position: absolute;
            top: 64px;
            background-color: white;
            z-index: 6;
            border-radius: 8px;
            box-shadow: 0 12px 16px -4px rgba(16 24 40 / 10%),
              0 4px 6px -2px rgba(16 24 40 / 5%);
          }
        }
      }
    }

    .topbar-actions {
      margin-left: 42px;
      display: flex;
      justify-content: flex-end;
      flex: 1;
      column-gap: 24px;

      .login-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: white;
        cursor: pointer;
        background-color: var(--primary-600);
        text-transform: none;
        box-shadow: none;
        height: 44px;
        padding: 10px 18px;
      }

      .registration-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-transform: none;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary-600);
        border: 1px solid var(--primary-600);
        background-color: var(--blue-gray-50);
        padding: 10px 18px;
      }
    }
  }
}

.click-away {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  cursor: default;

  &.notification {
    z-index: 1;
  }
}

.centering-wrapper {
  padding: 0 32px !important;

  &.login-appbar {
    padding: 0 32px !important;
  }
}

@media (max-width: 834px) {
  .centering-wrapper {
    padding: 0 16px !important;
  }
}

@media (max-width: 834px) {
  .app-topbar {
    display: none;
  }
}
