.gm-ui-hover-effect {
  display: none !important;
}

.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  background: var(--primary-600);
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.circle-clusterer {
  width: 28px;
  height: 28px;
  background-color: var(--primary-600);
  border-radius: 50%;
  border: none;
}

.hotel-info-window {
  display: flex;
  align-items: center;
  padding: 0 12px 12px 0;

  .price-wrapper-info {
    display: flex;
    align-items: center;
    padding-right: 14px;
    flex-direction: column;

    .from-wrapper-info {
      font-weight: 500;
      color: white;
    }

    .currency-wrapper-info {
      font-weight: 500;
      color: white;
      font-size: 16px;
    }
  }

  .kmb-stars {
    padding-top: 5px;
  }
}

.map-container {
  * {
    outline: none !important;
    border: none !important;
  }

  div {
    border-radius: 10px;
  }
}
