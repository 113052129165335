.hotel-card {
  display: flex;
  background-color: var(--gray-25);
  margin-bottom: 40px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;

  .hotel-image-wrapper {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &.image-missing {
      height: initial;
    }

    .hotel-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .image-placeholder {
      background-color: var(--gray-25);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      .icon-presentation {
        color: var(--gray-200);
        width: 100px;
        height: 100px;
      }
    }
  }

  .hotel-info {
    flex: 3;
    display: flex;
    flex-direction: column;
    padding: 20px;
    row-gap: 4px;

    .hotel-card-name {
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 29px;
      color: var(--gray-700);
      margin: 0px !important;
    }

    .hotel-stars {
      display: flex;
      justify-content: flex-start;

      .filter-single-star {
        color: goldenrod;
      }
    }

    .address-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: var(--primary-600);
      column-gap: 6px;

      .icon-location {
        color: var(--primary-600);
        width: 20px;
        height: 24px;
      }

      .address {
        color: var(--primary-600);
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
      }
    }

    .hotel-facilities {
      color: var(--gray-500);
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
    }

    .hotel-description {
      margin-top: 6px;
      color: var(--gray-300);
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      height: 100px;
      //TODO: Once line-clamp receives official CSS support, this should be changed to not use webkit
      /* stylelint-disable-next-line value-no-vendor-prefix */
      display: -webkit-box;
      max-height: 100px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;

      span {
        padding-left: 0;
      }
    }

    .end-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;

      .price-per-night {
        font-style: italic;
        color: var(--gray-500);
        font-size: 16px;
        line-height: 25px;
      }

      .proceed-to-booking {
        margin-top: 5px;
        height: 45px;
        background-color: var(--primary-600);
      }

      .icon-right-down {
        position: relative;
        top: 3px;
        right: -4px;
        display: inline-flex;
        font-size: 16px !important;
      }

      .price-per-night {
        font-size: 18px;
        font-weight: bold;
        font-style: italic;
        color: var(--gray-700);
      }
    }
  }
}

@media (max-width: 1200px) {
  .hotel-card {
    flex-direction: column;
    width: 100%;
    height: max-content;
    row-gap: 24px;
    border-radius: 16px;
    padding: 20px;
    background-color: var(--gray-25);

    .hotel-image-wrapper {
      width: 100%;
      height: max-content;

      .hotel-image {
        max-height: 300px;
        height: 300px;
      }
    }

    .hotel-info {
      width: 100%;
      height: max-content;
    }
  }
}
