.account-accordion-wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  .user-account-no-avatar {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    background-color: var(--primary-25);

    svg {
      width: 55%;
    }

    .cart-indicator {
      width: 8px;
      height: 8px;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 50%;
    }
  }

  .user-information-wrapper {
    margin-left: 12px;
  }

  .user-first-name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-right: 2px;
    color: var(--gray-700);
  }

  .user-email {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-right: 2px;
    color: var(--gray-500);
  }
}

.account-options {
  display: flex;
  flex-direction: column;
  align-items: center;

  .account-option {
    width: 100%;
    padding: 0 20px;
    color: var(--gray-600);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 18px;

    .option-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}
