.speeches {
  .speeches-header {
    border-bottom: 1px solid #d7d2cd;
    color: #64656e;
    margin: 50px 0 20px;
  }

  .speech {
    background-color: white;
    margin-bottom: 10px;
    position: relative;
    transition: transform 0.2s ease-in-out;

    .top-area {
      position: relative;
      cursor: pointer;
      padding: 15px;
      box-shadow: 1px 1px 1px #eee;
      //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-user-select: none;
      user-select: none;
    }

    .vod {
      border-top: 1px solid #eee;
      margin-top: 15px;
      padding: 15px 0 0;
      text-align: right;

      a {
        &:hover {
          text-decoration: none;
        }
      }

      i {
        font-size: 25px;
        height: 25px;
        color: var(--primary-600);
        cursor: pointer;
        display: block;
      }
    }

    &:hover {
      transform: translateY(-2px);
    }

    p {
      color: #64656e;
      font-size: 14px;
      margin: 0;

      &.speakers {
        color: #a6a7ac;
      }
    }

    .toggle {
      position: absolute;
      right: 15px;
      font-size: 20px;
      top: 50%;
      margin-top: -10px;
      color: #84858c;
    }

    .inner-area {
      padding: 30px 15px 15px;

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }

      h3 {
        color: #64656e;
        font-size: 14px;
        margin-bottom: 15px;
      }

      li.speaker {
        &.clickable {
          cursor: pointer;
          text-decoration: underline;
        }

        color: var(--primary-600);
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.mobile-table,
.kmb-mediaFiles {
  .media-col {
    margin: auto;

    .media-list {
      padding-left: 0;

      .media-file {
        height: auto;
        padding: 10px 0 0;

        .icon-upload {
          padding-bottom: 5px !important;
        }
      }
    }
  }
}

.kmb-mediaFiles {
  text-align: center;

  a {
    font-size: 12px !important;
  }

  span {
    font-size: 25px;
  }
}

.kmb-videoUrl,
.kmb-stream,
.kmb-addToAgenda {
  text-align: center;

  a {
    &.disabled {
      pointer-events: all;
    }
  }
}

.kmb-videoUrl {
  i {
    position: relative;
    top: 5px;
  }
}

tbody {
  .kmb-name {
    color: #404f54;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 25px;
  }
}

.program-info {
  border-radius: 4px;
  border: 1px solid var(--gray-50);
  width: 110px;
  height: 44px;
  text-decoration: none;
  background-color: white;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-500);

  p {
    font-size: 14px;
    padding-left: 4px;
  }

  &:hover {
    text-decoration: none;
    color: var(--primary-600);
  }
}

.program {
  width: 50px;
  height: 44px;
  background-color: white;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 5%;
  display: flex;
  color: #404040;
  font-family: Lato;
  font-size: 14px;
  text-align: center;
  justify-content: center;

  .icon-pdf {
    font-size: 25px;
    line-height: 1;
  }
}

.schedule-clear {
  text-align: right;
  min-height: 40px;
  font-size: 14px;
  padding-right: 0;
  flex-basis: 100%;
}

.control-container {
  padding-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  .selected-filters {
    padding: 5px 12px;
    margin-right: 3px;
    background-color: white;
    border-radius: 20px;
    font-size: 13px;
  }

  .agenda {
    width: calc(40% - 15px);
  }

  .rooms {
    width: calc(33% - 15px);

    .room-title {
      color: #404f54;
      font-family: Lato;
      font-size: 16px;
      letter-spacing: 0;
      margin-bottom: 8px;
    }

    .rooms-container {
      padding-top: 1px;
      display: flex;
      position: relative;
      gap: 5px;

      .arrow-card-wrapper {
        position: relative;

        .arrow-card {
          border: 1px solid var(--gray-50);
          background-color: white;
          border-radius: 4px;
          height: 44px;
          width: 27px;
          display: flex;
          align-items: center;
          font-size: 25px;
          cursor: pointer;
        }

        .disabled {
          color: var(--gray-50);
          cursor: default;
        }
      }

      .room-card-wrapper {
        flex: 1;

        .room-card {
          border: 1px solid var(--gray-50);
          border-radius: 4px;
          text-align: center;
          height: 44px;
          font-size: 12px;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .active {
          border: none;
          background-color: var(--primary-600);
          color: white;
        }
      }
    }
  }
}

.session-list {
  table {
    a:hover {
      text-decoration: none;
    }

    i {
      font-size: 30px;
      color: var(--primary-600);
      height: 30px;
    }
  }
}

.kmb-cosmetic {
  > span {
    border-radius: 50%;
    color: #404040;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    font-size: 26px;
    position: relative;

    &::before {
      position: relative;
    }
  }
}

.media-list {
  padding: 0;
  list-style-type: none;
  display: flex;
  gap: 8px;

  li.media-file {
    text-align: center;
    height: 120px;

    span.fa {
      color: var(--primary-600);
      position: absolute;
      right: 20px;
      font-size: 20px;
    }

    a {
      display: block;
      color: #a6a7ac;
      font-size: 14px;
      padding: 5px;
      transition: 0.15s ease-in-out;

      .icon-pdf {
        font-size: 30px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.speaker-col {
  .speaker {
    color: #2c5aba;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
  }

  .clickable {
    cursor: pointer;
  }
}

.event-media {
  margin: 0 0 8px;
  color: #404f54;
  font-size: 16px;
}

.component-session-inner {
  h1 {
    margin: 0;
    color: #404f54;
    font-family: Lato;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
  }

  .session-additional {
    padding: 5px 0 0;

    > p {
      margin: 0;
      font-size: 15px;

      > i {
        padding-right: 5px;
      }
    }

    .date {
      padding-right: 10px;
      color: #919da4;
      font-family: Lato;
      font-size: 15px;
    }

    .time {
      color: #919da4;
      font-family: Lato;
      font-size: 15px;
    }

    .room-wrapper {
      color: #919da4;
      font-family: Lato;
      font-size: 15px;
    }
  }

  .status-holder {
    text-align: right;

    span {
      font-size: 13px;
      text-transform: capitalize;

      &::after {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-left: 5px;
      }

      &.status-completed {
        &::after {
          background-color: #1eefb0;
        }
      }

      &.status-running {
        &::after {
          background-color: #ff5d00;
        }
      }

      &.status-upcoming {
        &::after {
          background-color: #1da4cf;
        }
      }
    }
  }

  .description {
    color: #919da4;
    font-family: Lato;
    font-size: 15px;
    padding-top: 0;
    padding-bottom: 15px;
  }

  .session-media {
    margin-top: 20px;
    max-width: 850px;

    h2 {
      color: #64656e;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    .file-inner {
      color: #a6a7ac;
      font-size: 14px;
      height: 100%;

      a {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      div {
        padding-top: 20px;
      }

      h2 {
        padding-top: 40px;
        position: absolute;
        bottom: 0;
        right: 1%;
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
}

@media (max-width: 991px) {
  .control-container {
    flex-flow: column unset;
  }

  .component-schedule ul.top-menu {
    margin-bottom: 30px;

    li {
      display: block;

      button.btn {
        padding: 15px;
      }
    }
  }

  .component-abstractmanagement ul.top-menu {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 30px;
    padding-left: 10px;

    li {
      display: block;

      button.btn {
        padding: 15px;
      }
    }
  }

  .session-list {
    .table-container {
      .kmb-date,
      .kmb-stream,
      .kmb-sessionFiles {
        display: none;
      }

      .kmb-name {
        > p > span {
          font-size: 13px;
          color: #999;
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .control-container {
    .rooms {
      padding-right: 0;
    }
  }
}

@media (max-width: 450px) {
  .control-container {
    .agenda {
      .mini-calendar {
        .bottom {
          font-size: 12px !important;
        }

        .top {
          font-size: 20px !important;
        }

        .date {
          padding: 0 !important;
        }
      }
    }
  }
}
