.toolbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 80px;
  background-color: #f6f8fc;
  z-index: 1;
  padding-top: 24px;
  padding-bottom: 12px;

  .viewing-tools-wrapper {
    position: static;
    width: max-content;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 44px;
    overflow: hidden;
    column-gap: 32px;

    .MuiInputBase-root,
    .MuiOutlinedInput-root {
      width: calc(33% - 12px);
      max-width: calc(33% - 12px);

      > div {
        background-color: white;
      }

      * {
        color: var(--gray-700);
      }

      > fieldset {
        border-color: var(--gray-100);
      }
    }

    .search-bar {
      max-width: calc(33% - 12px);
      width: calc(33% - 12px);

      .MuiFormControl-root,
      .MuiTextField-root {
        height: 44px;
        width: 100%;
        max-width: 100%;

        .MuiInputBase-root,
        .MuiOutlinedInput-root,
        .MuiInputBase-formControl,
        .MuiInputBase-adornedStart,
        .MuiOutlinedInput-adornedStart {
          height: 44px;
          width: 100%;
          max-width: 100%;
          background-color: white;
          color: var(--gray-900);

          > input {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.paginator-container {
  &.sticky {
    position: sticky;
    top: 80px;
    z-index: 2;
    background: #f6f8fc;
    flex: 1;
    width: 100%;
    margin-bottom: 24px;
    box-shadow: -16px 0 0 0 #f6f8fc, 16px 0 0 0 #f6f8fc;
  }

  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 196px;

  .paginator-wrapper {
    height: 44px;
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;

    .backdrop-focuser {
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 5;
      left: 0;
      top: 0;
    }

    .page-indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 18px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray-700);
      height: 40px;
      padding: 2px 16px;
      border: none;
      border-radius: 4px;

      .view-wrapper {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.in-menu {
          cursor: pointer;

          &.selected {
            color: var(--gray-200);
          }
        }
      }

      &:hover {
        background-color: white;
      }

      &.open {
        background-color: white;
        z-index: 6;
        flex-direction: column;
        justify-content: flex-start;
        height: max-content;
        align-self: flex-start;
      }
    }

    .mobile-back {
      display: none;
    }

    .page-navigation {
      display: flex;
      justify-content: center;
      align-items: center;

      > button {
        padding: 0;
        height: 26px;
        width: 26px;
        color: var(--gray-700);

        &.Mui-disabled {
          color: var(--gray-100);
        }
      }

      .mobile-next {
        display: none;
      }
    }

    > button {
      padding: 0;
      height: 26px;
      width: 26px;
      color: var(--gray-700);

      &.Mui-disabled {
        color: var(--gray-100);
      }
    }
  }
}

@media (max-width: 1400px) {
  .toolbar-container {
    .viewing-tools-wrapper {
      column-gap: 32px;
    }
  }
}

@media (max-width: 1100px) {
  .toolbar-container {
    row-gap: 16px;
    flex-flow: column wrap;

    .viewing-tools-wrapper {
      width: 100%;
      overflow: initial;
      flex-wrap: wrap;
      height: max-content;
      row-gap: 16px;

      .MuiInputBase-root,
      .MuiOutlinedInput-root,
      .search-bar {
        width: 100%;
        min-width: 100%;
        height: 44px;
      }

      .MuiInputBase-root,
      .MuiOutlinedInput-root {
        .MuiSelect-root,
        .MuiSelect-select,
        .MuiSelect-selectMenu,
        .MuiSelect-outlined,
        .MuiInputBase-input,
        .MuiOutlinedInput-input {
          padding: 12.5px 32px 12.5px 14px;
          margin: 6px 0;

          .selected-checkmark {
            display: none !important;
          }
        }
      }
    }
  }

  .paginator-container {
    width: 100%;

    .paginator-wrapper {
      width: 100%;
      justify-content: space-between;

      .page-indicator {
        margin-right: 0;
      }

      .mobile-back {
        display: unset;
      }

      .page-navigation {
        .mobile-next {
          display: unset;
        }

        .desktop-back,
        .desktop-next {
          display: none;
        }
      }
    }
  }
}
