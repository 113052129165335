.tabs {
  > ul.header-area {
    padding-left: 0;
    list-style-type: none;

    li {
      display: inline-block;
      border-bottom: 4px solid transparent;

      &.active {
        border-bottom: 4px solid var(--primary-600);

        button {
          font-weight: 500;
        }
      }

      button {
        font-weight: 400;
        border-radius: 0;
        color: #64656e;
        background-color: transparent;
        box-shadow: none;
        font-size: 14px;
        padding: 10px 30px;
      }
    }
  }
}
