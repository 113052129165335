.kmb-loader {
  padding-left: 0;
  margin: 0;

  > li {
    border-radius: 8px;
    display: block !important;
    padding: 0 !important;
    animation-name: fade;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    background: linear-gradient(-45deg, #e9edf1, #dfe4eb, #e9edf1, #dfe4eb);
    background-size: 400% 400%;
    height: 80px;
  }

  @keyframes fade {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}
