.logoutter {
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(75 75 75 / 30.1%);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);

  .logoutter-prompt {
    width: 430px;
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;

    .warning-container {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 8px;
      margin-bottom: 12px;
    }

    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-bottom: 8px;

      .logoutter-button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: white;
        cursor: pointer;
        background-color: var(--primary-600);
        text-transform: none;
        box-shadow: none;
        height: 44px;
        padding: 10px 18px;
      }
    }
  }
}
