.main-area {
  position: relative;

  .icon-attach,
  .icon-attach-no {
    font-size: 37px;
  }

  button {
    outline: none;
  }

  .kmb-loader {
    padding-top: 50px;
    flex-grow: 1;
  }

  max-width: 100%;
  min-width: 100px;
  padding-left: 0;
  flex: 1;
  background-color: #f6f8fc;
  min-height: calc(100vh - 80px);

  .color-warning {
    color: #ff8d00;
  }

  .color-success {
    color: #5bd880;
  }

  > header {
    min-height: 72px;
    background-color: #368cfe;
    align-items: center;
    text-align: center;
    display: none;

    > i {
      color: #333741;
    }

    > .container {
      position: relative;
    }

    .inner {
      cursor: pointer;
      display: inline-block;
      margin: 0 60px 0 35px;

      span {
        color: white;
        vertical-align: middle;
        font-size: 16px;
        letter-spacing: 1px;
        font-weight: 700;
        position: relative;
        top: 4px;
      }
    }

    img {
      filter: grayscale(100%);
    }
  }
}

.page-container {
  padding: 90px 120px;
}
.icon-close {
  cursor: pointer;
}
.session-search {
  color: #435670;
  padding-left: 15px;
  font-size: 14px;
  width: 100%;
  border: 1px solid var(--gray-50);
  border-radius: 4px;
  height: 44px;
  outline: none;
}

.empty-results {
  &.border-top {
    border-top: 1px solid var(--gray-25);
    margin-top: -20px;
  }

  min-height: 430px;
  width: 100%;
  background-color: white;
  padding: 50px;

  .receipt {
    cursor: pointer;
    font-size: 20px;
    color: var(--primary-600);
  }

  text-align: center;
  color: #b8b8b8;
  font-size: 20px;
  font-family: Lato;
  font-weight: bold;

  .icon-warning-2 {
    font-size: 80px;
  }

  .icon-check {
    font-size: 80px;
    color: #00b64c;

    &.big {
      font-size: 160px;
    }
  }

  .icon-close {
    font-size: 80px;
    color: #f13f3f;
  }

  > span {
    margin-bottom: 25px;
    color: #000;
  }

  > span.fa-check-square {
    color: green;
    margin-bottom: 25px;
  }

  > p {
    font-size: 25px;
    margin: 0;
    margin-bottom: 30px;
  }

  button {
    border-radius: 5px;
    background-color: var(--primary-600);
  }
}

.content-info {
  padding: 0 32px 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .col-sm-12 {
    padding-left: 0;
    padding-right: 0;
  }

  .description {
    word-break: break-word;
  }

  .oval {
    display: none;
    background-color: #008cfe;
    opacity: 0.3;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 50%;
  }

  h2 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 1.8;
  }
}

.footer-section {
  padding: 0 50px 10px;
  margin: 0;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .footer-color {
    width: 200%;
    height: 100%;
    position: absolute;
    min-width: 2000px;
  }

  .footer-container {
    display: flex;
    align-items: center;
    flex-flow: column;
    max-width: 1280px;
    padding: 90px 32px 30px;

    .flex-item {
      padding-left: 10%;

      img {
        padding-bottom: 20px;
      }
    }

    .footer-sponsor-logo-item {
      margin: 5px;
      object-fit: contain;
      background: white;
      margin-bottom: 20px;
      height: 120px;
      padding: 5px;
    }

    .footer-event-info {
      padding-top: 50px;
      display: flex;
      justify-content: flex-start !important;
      width: 100%;
    }

    .footer-sponsors {
      width: 60%;
    }

    .footer-sponsors,
    .footer-event-info {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-start;

      .logo-item {
        object-fit: contain;
        background: white;
        margin-bottom: 10px;
        padding: 5px;
        box-shadow: 0 0 2px 0 grey;
        width: 100px;
        height: 100px;
        cursor: pointer;

        &.round-image {
          border-radius: 100%;
        }
      }

      &,
      * {
        color: white;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 32px;
      }

      .footer-flex-item {
        flex-basis: 33.3%;
        text-align: center;
        flex-grow: 1;

        .footer-subtext {
          min-height: 256px;
        }
      }
    }

    .footer-event-info > :only-child {
      text-align: center;
    }
  }

  .best-company {
    color: white;
    font-family: Lato;
    font-size: 13px;
    letter-spacing: 1;
    line-height: 26px;
    text-align: center;
    font-weight: 300;
  }
}

.main-component {
  padding: 0;

  .container {
    min-height: 500px;

    .mobile-table {
      display: none;
    }
  }

  h1 {
    margin: 10px 0 25px;
    font-size: 24px;
    color: #404040;
  }

  .layout-item {
    margin-bottom: 10px;

    p {
      font-size: 16px;
      color: rgba(0 0 0 / 54%);
    }
  }
}

.loader-wrapper {
  height: 90px;

  .loader-track,
  .loader-path {
    position: absolute;
  }
}

.component-sponsors,
.component-ebag {
  .sponsor-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 18px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 30px;
    align-items: center;

    .sponsor {
      border-color: #a7b6c2;
      border-width: 1;
    }
  }
}

.component-generalinfo {
  .jumbo-container {
    max-width: 100% !important;
    position: relative;
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .cta-container {
      padding-top: 20px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      a {
        cursor: pointer;
        margin: 10px;
        background-color: white;
        color: black;
        z-index: 2;
        padding: 9px 30px;
        border-radius: 20px;
        border: none;
        min-width: 250px;
        font-size: 1.2rem;
        margin-bottom: 20px;
        flex-basis: 40%;
        place-self: center;
        text-align: center;
        text-decoration: none;
      }
    }

    &.background {
      min-height: 695px;
    }
  }

  .row {
    margin-right: 0;
    margin-left: 0;
  }

  .component-inner {
    > div {
      background-color: white;
    }

    .component-inner-child {
      align-items: center;
      display: flex;
      flex-direction: column;

      > div {
        max-width: 1280px;
        width: 100%;
        padding: 0 32px;
        row-gap: 32px;
      }
    }

    .period-container {
      display: flex;
      justify-content: center;

      .period-name {
        padding: 10px 20px;
        font-family: Lato;
        color: #404040;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 2px solid white;
        cursor: pointer;

        &.active {
          border-bottom: 2px solid var(--primary-600);
          color: var(--primary-600);
          transition: 150ms;
        }
      }
    }

    .speaker-cards-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .section {
      padding: 0 7% 40px;
      justify-content: center;

      .hotel-mobile {
        display: none;
      }

      .hotel-col {
        padding-left: 0;

        .hotel-info-container {
          height: 220px;
        }

        .hotel-name {
          height: 68px;
          color: #404f54;
          font-family: Lato;
          font-size: 28px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 35px;
        }

        .hotel-info {
          height: 25px;
          color: #404f54;
          font-family: Lato;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 25px;
          font-weight: 400;
        }
      }
    }

    .section-title {
      color: #404f54;
      font-family: Lato;
      font-size: 29px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 41px;
      text-align: center;
    }

    .section-subtitle {
      color: #404f54;
      font-family: Lato;
      font-size: 17px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
    }

    .video-holder {
      position: relative;
      width: 100%;
      aspect-ratio: 16 / 9;
      margin-bottom: 40px;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .accommodation-container {
      padding-bottom: 40px;
    }

    .hotel-img-container {
      padding: 0;

      img {
        max-width: 100%;
        height: 500px;
        object-fit: cover;
        object-position: top;
      }
    }

    .card-container.pricing {
      display: flex;
      flex-flow: row wrap;
      align-content: flex-start;
      padding-top: 25px;
      overflow-x: auto;
    }

    .card-container {
      padding-top: 50px;
      display: flex;
      justify-content: center;
      align-items: baseline;
      flex-wrap: wrap;
      column-gap: 50px;

      .pricing-card-wrapper {
        width: 250px;
        height: 280px;

        .pricing-card {
          width: 250px;
          max-width: 250px;
          height: 100%;
          min-height: 280px;
          padding: 30px 10px;
          text-align: center;
          border-radius: 10px;
          background-color: #f5f5f5;
          border: 1px solid var(--primary-600);

          &.disabled {
            border: 1px solid var(--primary-400);
            opacity: 0.6;
          }

          .price-group {
            color: #404f54;
            font-size: 20px;
            letter-spacing: 0;
            width: 80%;
            height: 40px;
            margin: auto;
            line-height: 20px;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .price {
            color: #404f54;
            font-size: 56px;
            font-weight: bold;
            padding: 30px 0px;
            letter-spacing: -2px;
            margin: 0;
          }

          .price-notice {
            color: var(--error-600);
            font-size: 16px;
            font-weight: 500;
          }

          .availability-button {
            background-color: var(--primary-600);
            border-radius: 5px;
            color: white;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0;
            text-align: center;
            padding: 8px;
            padding-left: 20px;
            padding-right: 20px;
            border: none;

            &.disabled {
              background-color: var(--primary-600);
            }
          }
        }
      }

      .speaker-card {
        transition: 0.3s;
        min-height: 350px;
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        align-items: center;

        .card-content {
          max-width: 250px;
          display: flex;
          flex-flow: row wrap;
          align-content: center;
          align-items: center;
          margin: 3px;
          cursor: pointer;

          .speaker-img {
            width: 250px;
            object-fit: cover;
            height: 250px;
            align-self: center;
            border-radius: 5px;
          }

          .card-header-custom {
            color: #404f54;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 30px;
            padding-top: 20px;

            h4 {
              line-height: 30px;
              font-size: 22px;
            }

            .card-subheader-custom {
              color: #8d9599;
              font-size: 16px;
              letter-spacing: 0;
              padding-top: 10px;
              line-height: 26px;
              font-weight: 400;
              min-height: 36px;
              margin-bottom: 1rem;

              p {
                line-height: 26px;
              }
            }
          }
        }
      }
    }

    .pricing {
      width: 100%;
      padding: 10%;
      padding-top: 40px;
    }

    .sponsor-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 40px 10% 200px;

      .sponsor {
        text-align: center;
        border-color: #a7b6c2;
        border-width: 1;
        flex: 1 0 21%;
      }
    }
  }

  .banner {
    overflow: hidden;

    * {
      padding: 0 !important;
      margin: 0 !important;
    }

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .poster {
      width: 100%;
    }

    h2.header {
      position: absolute;
      bottom: 20%;
      font-size: 40px;
      font-weight: 500;
      color: #008cfe;
      max-width: 80%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;

      p {
        font-size: 32px;
        font-weight: bold;
        margin-top: 30px 0;
        color: #404f54;
        max-width: 750px;
        text-align: center;
      }
    }
  }

  .date-inner {
    label {
      font-weight: 500;
      font-size: 14px;
      float: left;
      width: 100px;
    }

    span.date-wrapper {
      margin-top: -50px;
      text-align: center;
      width: auto;
      display: block;
      color: #8d8d8d;
      font-size: 16px;

      > span {
        padding-left: 30px;
      }
    }
  }

  .description {
    overflow-x: auto;
    padding: 0 !important;
    margin-top: 50px;

    h2 {
      font-size: 24px;
      text-align: center;
    }

    p {
      font-size: 14px;
      color: #606060;
    }

    .layout-item {
      padding: 0 !important;
    }
  }

  .layout-item {
    padding: 15px;
    padding-left: 12%;
    padding-right: 10%;

    h2.header-info {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      color: #008cfe;
      padding: 0;
      text-align: left;
    }
  }

  .poster {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding: 0;
    border-bottom: none;

    .title {
      top: 37%;
      position: absolute;
      text-align: center;
      z-index: 4;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img.blur {
      object-fit: cover;
      object-position: top;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: white;
      opacity: 0.2 !important;
      filter: blur(10px);
    }

    .fadeaway {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      z-index: 2;
    }
  }
}

i.animated {
  display: inline-block;

  &:hover {
    animation: wrench 0.5s ease;
  }
}

a,
button,
span {
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}

.component-schedule {
  max-width: 100%;

  .component-inner {
    .component-inner-child {
      > div {
        > .container {
          margin: 0;
          width: 100%;
          max-width: 100%;
          padding: 0 32px;
        }
      }
    }
  }

  .session {
    padding: 25px 25px 25px 125px;
    background-color: #ededed;
    box-shadow: 0 0 6px 0 rgba(0 0 0 / 20%);
    margin-bottom: 20px;
    position: relative;
    transition: transform 0.15s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: translateY(-4px);
    }

    h2 {
      margin-bottom: 12px;
      font-size: 20px;
    }

    p {
      color: rgba(0 0 0 / 54%);
      font-size: 16px;
      margin: 0;
      line-height: 1;

      &.date {
        color: rgba(0 0 0 / 54%);
        background-color: #949494;
        font-size: 16px;
        margin: 0;
        width: 90px;
        height: 70px;
        position: absolute;
        left: 17px;
        top: 50%;
        margin-top: -34px;
        padding: 10px;
        text-align: left;

        > span {
          color: white;
          font-size: 13px;
          display: block;

          &.day {
            margin-bottom: 5px;
          }

          &.time {
            font-size: 15px;
            font-weight: 500;

            > span {
              font-size: 11px;
              font-weight: 300;
              width: 32px;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  main {
    h1 {
      margin-top: 0;
    }
  }

  ul.top-menu {
    display: flex;
    padding: 0;
    list-style-type: none;
    border-bottom: 1px solid #ddd;
    padding-left: 42px;

    li {
      text-align: center;
      display: inline-block;
      position: relative;
      margin-right: 20px;

      &.active {
        &::after {
          content: "";
          height: 1px;
          bottom: -2px;
          width: 100%;
          position: absolute;
          display: block;
        }

        button.btn {
          color: var(--primary-600);
        }
      }

      button.btn {
        display: block;
        padding: 28px 10px;
        box-shadow: none;
        background-color: transparent;
        outline: none;
        border: none;
        font-weight: 500;
        color: rgba(33 33 33 / 55%);
        font-size: 14px;
      }
    }
  }
}

.component-abstractmanagement {
  max-width: 100%;

  .session {
    padding: 25px 25px 25px 125px;
    background-color: #ededed;
    box-shadow: 0 0 6px 0 rgba(0 0 0 / 20%);
    margin-bottom: 20px;
    position: relative;
    transition: transform 0.15s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: translateY(-4px);
    }

    h2 {
      margin-bottom: 12px;
      font-size: 20px;
    }

    p {
      color: rgba(0 0 0 / 54%);
      font-size: 16px;
      margin: 0;
      line-height: 1;

      &.date {
        color: rgba(0 0 0 / 54%);
        background-color: #949494;
        font-size: 16px;
        margin: 0;
        width: 90px;
        height: 70px;
        position: absolute;
        left: 17px;
        top: 50%;
        margin-top: -34px;
        padding: 10px;
        text-align: left;

        > span {
          color: white;
          font-size: 13px;
          display: block;

          &.day {
            margin-bottom: 5px;
          }

          &.time {
            font-size: 15px;
            font-weight: 500;

            > span {
              font-size: 11px;
              font-weight: 300;
              width: 32px;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .component-inner {
    > .kmb-loader {
      padding: 25px 32px;
    }
  }

  main {
    padding: 25px 32px 0;

    h1 {
      margin-top: 0;
    }
  }

  ul.top-menu {
    display: flex;
    padding: 0;
    list-style-type: none;
    border-bottom: 1px solid #ddd;
    padding-left: calc(8% + 10px);

    li {
      text-align: center;
      display: inline-block;
      position: relative;
      margin-right: 20px;

      &.active {
        &::after {
          content: "";
          height: 1px;
          bottom: -2px;
          width: 100%;
          position: absolute;
          display: block;
        }

        button.btn {
          color: var(--primary-600);
        }
      }

      button.btn {
        display: block;
        padding: 0 0 8px;
        box-shadow: none;
        background-color: transparent;
        outline: none;
        border: none;
        font-weight: 500;
        color: rgba(33 33 33 / 55%);
        font-size: 14px;
      }
    }
  }
}

.header-logo-mobile {
  display: none;
}

.bt-hidden {
  overflow: hidden;
}

.mobile-only-header {
  display: none;
}

@media (max-width: 834px) {
  .jumbo-container {
    &.background {
      min-height: 400px !important;
    }
  }

  .content-info {
    padding-bottom: 45px;
    padding-left: 20px;
    padding-right: 20px;

    .oval {
      display: none;
    }
  }

  .hotel-col {
    padding-right: 0 !important;
  }
}

@media (max-width: 834px) {
  .page-container {
    padding: 25px;
  }

  .display-mobile {
    display: none;
  }

  .mobile-only-header {
    display: flex;
    height: 72px;
    position: relative;
    justify-content: center;
    align-items: center;

    .user-account {
      position: relative;
      cursor: pointer;
      max-width: 220px;
      z-index: 3;
      font-family: Lato;
      margin-top: 3px;
      font-size: 16px;
      border-radius: 10px;
      padding: 10px;
      font-weight: 300;
      color: white;
      display: flex;
      width: 31px;
      height: 31px;
      justify-content: center;
      align-items: center;
      //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-user-select: none;
      user-select: none;
      margin-right: 5%;

      .cart-indicator {
        font-size: 13px;
        background-color: black;
        position: absolute;
        color: white;
        top: -7px;
        right: -9px;
        font-weight: 900;
        padding-left: 6px;
        padding-right: 6px;
        border-radius: 100%;
      }

      .icon-arrow-down {
        display: inline !important;
        font-size: 20px;
      }
    }

    .options {
      z-index: 3;
      display: flex;
      flex-direction: column;
      max-height: 300px;
      box-shadow: 0 2px 5px 0 rgba(82 97 115 / 50%);
      font-size: 14px;
      border-radius: 4px;
      background-color: white;
      width: 170px;
      position: absolute;
      right: 40px;
      top: 60px;

      .option {
        display: flex;
        justify-content: space-between;
        color: #757575;
        font-size: 14px;
        text-align: right;
        border-radius: 4px;
        border-bottom: 1px solid #ddd;
        cursor: pointer;
        padding: 11px 20px;
        //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-user-select: none;
        user-select: none;
      }

      .option:hover {
        background-color: #f3f3f3;
      }
    }

    i {
      right: 0;
      font-size: 30px;
    }

    .container {
      height: 72px;
      background-color: white;
    }

    .header-logo-mobile {
      display: inline;
      height: 25px;
      max-width: 90%;
      object-fit: contain;
      margin: auto;
    }
  }

  .session-search {
    padding-right: 8px;
  }

  header {
    display: flex;
    background-color: transparent;
    min-height: 0;

    i {
      margin: 0;
      top: 15px;
      left: 5%;
      font-size: 19px;
    }
  }

  .empty-results {
    background-color: white;
    padding: 40px;

    .receipt {
      cursor: pointer;
      font-size: 18px;
      color: var(--primary-600);
    }

    text-align: center;
    color: #b8b8b8;
    font-size: 18px;
    font-family: Lato;
    font-weight: bold;

    .icon-warning-2 {
      font-size: 50px;
    }

    .icon-check {
      font-size: 50px;
      color: #00b64c;
    }

    .icon-close {
      font-size: 50px;
      color: #f13f3f;
    }

    > p {
      font-size: 30px;
      margin: 0;
      margin-bottom: 30px;
    }
  }

  .video-container {
    .empty-results {
      width: 100%;
    }
  }

  .main-component {
    .container {
      padding-left: 20px;
      padding-right: 20px;
      min-height: 200px;

      .table-container {
        display: none;
      }

      .mobile-table {
        display: inline-block;
        width: 100%;

        .card {
          padding: 20px;
          margin-bottom: 20px;

          .button {
            margin-top: 20px;
            border: none;
            height: 36px;
            border-radius: 4px;
            background-color: var(--primary-600);
            font-size: 17px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 25px;
            text-align: center;
            color: white;
            font-family: Lato;
          }

          .card-name {
            height: 25px;
            color: #404f54;
            font-family: Lato;
            font-size: 18px;
            letter-spacing: 0;
            font-weight: bold;
            line-height: 25px;
            padding-bottom: 40px;
          }

          .card-info-container {
            display: flex;
            flex-wrap: wrap;

            .name {
              display: none;
            }

            .col {
              padding-left: 0;
            }

            .cosmetic {
              display: none;
            }

            .col-name {
              color: #404f54;
              font-family: Lato;
              font-size: 13px;
              letter-spacing: 0;
              line-height: 17px;
              padding: 10px 10px 10px 0;
            }

            .col-entry {
              color: #919da4;
              font-family: Lato;
              font-size: 15px;
              letter-spacing: 0;
              line-height: 21px;
            }

            .icon-agenda-added1,
            .icon-agenda-added,
            .icon-no-video,
            .icon-video {
              font-size: 30px;
            }

            .icon-attach-no,
            .icon-attach {
              font-size: 37px;
            }

            .icon-agenda-added1,
            .icon-agenda-added {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .main-area {
    align-items: initial;

    > header {
      display: flex;
      background-color: transparent;
      min-height: 0;

      > i {
        margin: 0;
        top: 15px;
        left: 5%;
        font-size: 19px;
      }
    }

    .component-generalinfo {
      .component-inner {
        .period-container {
          display: flex;
          justify-content: flex-start;
          overflow-x: auto;
          padding: 0 15px;
          margin-bottom: 20px;

          .period-name {
            flex: 0 0 auto;
            white-space: nowrap;
          }
        }
        .section {
          .card-container {
            .speaker-card {
              display: flex;
              flex-flow: column wrap;
              align-content: center;
              align-items: center;
              justify-content: center;

              .speaker-img {
                height: 250px;
                align-self: center;
              }
            }
          }
        }
      }

      .hotel-col {
        padding-left: 0;
        padding-right: 10px;
        padding-bottom: 20px;

        .hotel-name {
          height: auto !important;
        }
      }

      h2.header {
        top: 200px;
        font-size: 30px;

        p {
          font-size: 20px;
        }
      }

      .banner {
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        .poster {
          overflow: none;
          margin-bottom: 0;
          min-height: auto;
        }
      }
    }
  }
}

.transaction-success-icon {
  color: var(--success-600);
  margin-bottom: 16px;
}

.transaction-failed-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.transaction-failed-icon {
  color: var(--error-600);
  margin-bottom: 16px;
}

.trasnaction-failed-message .transaction-success-message {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  color: var(--gray-900);
}

.trasnaction-failed-info {
  background-color: white;
  max-width: 450px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--gray-900);
}

.trasnaction-failed-info-1 .trasnaction-success-info-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--gray-900);
}

.trasnaction-failed-info-2 .trasnaction-success-info-2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: var(--gray-900);
}

.receipt-icon {
  margin-bottom: 16px;
}

@media (max-width: 700px) {
  header {
    display: flex;
    background-color: transparent;
    min-height: 0;

    i {
      margin: 0;
      top: 15px;
      left: 5%;
      font-size: 19px;
    }
  }

  .footer-sponsor-logo-item,
  .footer-sponsors {
    width: 90% !important;
  }

  .component-schedule {
    .top-menu {
      padding-left: 10px !important;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  .sponsor-section {
    display: flex !important;
    justify-content: center;
    flex-flow: column wrap;
    padding-top: 20px;
  }

  .main-area {
    .footer-section {
      padding: 20px;
      border-top-left-radius: 25%;

      .footer-container {
        flex-direction: column;

        .flex-item {
          padding-left: 10px;

          img {
            padding-bottom: 20px;
          }

          .footer-title {
            padding-bottom: 5px;
            font-weight: 400;
          }
        }

        .footer-logo {
          padding-bottom: 10px;
        }

        .footer-subtext {
          width: auto !important;
          height: 82px !important;
        }
      }
    }

    > header {
      display: flex;
      background-color: transparent;
      min-height: 0;

      > i {
        margin: 0;
        top: 15px;
        left: 90%;
        font-size: 19px;
      }
    }

    .component-generalinfo {
      .banner {
        .poster {
          overflow: none;
          margin-bottom: 0;
          min-height: auto;

          img {
            opacity: 1;
          }

          .fadeaway {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparent;
            background: linear-gradient(
              white 0%,
              transparent 6%,
              transparent 95%,
              white 100%
            );
          }
        }
      }

      .component-inner {
        .period-container {
          display: flex;
          justify-content: flex-start;
          overflow-x: auto;
          padding: 0 15px;
          margin-bottom: 20px;

          .period-name {
            flex: 0 0 auto;
            white-space: nowrap;
          }
        }

        .content-info {
          padding-bottom: 100px;

          .oval {
            display: none;
            height: 350px;
            padding-bottom: 100px;
          }
        }

        .layout-item {
          padding: 15px;
        }

        h2.header {
          top: 350px;
          font-size: 30px;

          p {
            font-size: 20px;
          }
        }

        .section {
          padding: 10px;
          padding-bottom: 45px;

          .hotel-mobile {
            display: inline-block;

            .hotel-name {
              height: 89px;
              color: #404f54;
              font-family: Lato;
              font-size: 27px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 31px;
              text-align: start;
            }

            .hotel-info {
              height: 45px;
              color: #8e8e8e;
              font-family: Lato;
              font-size: 18px;
              letter-spacing: 0;
              line-height: 25px;
            }
          }

          .hotel-col {
            display: none;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 20px;
          }
        }

        .hotel-img-container {
          text-align: left;
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 15px;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .section-title {
          margin: 0;
          font-size: 26px;
          text-align: left;
        }

        .section-subtitle {
          padding-top: 0;
          font-size: 17px;
          font-weight: 500;
          text-align: left;
        }

        .price-title {
          padding-left: 10px;
        }

        .sponsors-title {
          padding-left: 10px;
        }

        .card-container {
          flex-direction: column;
          padding-top: 50px;

          .speaker-card {
            max-width: 100%;
            padding-right: 0;
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            align-content: center;
            align-items: center;
            justify-content: center;

            .speaker-img {
              height: 250px !important;
              align-self: center;
            }

            .card-header-custom {
              padding-top: 20px;
              max-width: 250px;

              h4 {
                font-size: 20px !important;
              }

              .card-subheader-custom {
                padding-top: 0;
              }
            }
          }
        }

        .pricing {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          overflow-x: auto;
          padding-bottom: 20px !important;
          gap: 10px;

          .pricing-card-wrapper {
            .pricing-card {
              box-shadow: 0 2px 13px 0 rgba(0 0 0 / 17%);
              .price-group {
                color: #8e989b;
              }

              .price-notice {
                color: #f4394d;
                font-family: Lato;
                letter-spacing: 0;
              }
            }
          }
        }

        .sponsor-section {
          flex-direction: column;
          padding-top: 20px;
        }
      }
    }
  }
}
