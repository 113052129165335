.mail-form {
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
  overflow: hidden;
  height: 100%;

  .form-group {
    width: 100%;
    padding: 0 !important;
    overflow: hidden;

    .message-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: var(--gray-700);
      margin-bottom: 12px;
    }

    .mail-input-label {
      color: var(--gray-700);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 6px;
    }

    form {
      .form-control:focus {
        box-shadow: none;
      }

      label {
        width: 100%;

        span {
          float: right;
        }
      }

      input,
      textarea {
        resize: none;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
      }

      textarea {
        height: 110px;
        resize: none;
      }

      .sub-note-text {
        color: var(--gray-600);
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-top: 4px;
      }
    }
  }
}

.submit-loader {
  &.MuiCircularProgress-colorPrimary {
    color: white;
    width: 32px;
    height: 32px;
  }
}

.submit-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.backdrop-message {
  z-index: 6 !important;
  backdrop-filter: none;
  background-color: transparent !important;
}

.grow {
  z-index: 6;
  animation-name: grow;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  &.captcha {
    animation-name: grow-captcha;
  }
}

.shrink {
  animation: shrink 0.4s 1 ease-in-out forwards;

  &.captcha {
    animation: shrink-captcha 0.4s 1 ease-in-out forwards;
  }
}

.init-external {
  display: none !important;
}

.grow-external {
  z-index: 6;
  animation-name: grow-external;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  &.captcha {
    animation-name: grow-captcha-external;
  }
}

.shrink-external {
  animation: shrink-external 0.4s 1 ease-in-out forwards;

  &.captcha {
    animation: shrink-external-captcha 0.4s 1 ease-in-out forwards;
  }
}

.appear {
  animation: appear 0.4s 1 ease-in-out forwards;
}

.disappear {
  width: 0;
  overflow: hidden;
  animation: disappear 0.4s 1 ease-in-out forwards;
}

@keyframes grow {
  from {
    max-width: 48px;
    height: 48px;
    border-radius: 100%;
  }

  to {
    max-width: 75vw;
    width: 380px;
    height: 454px;
    border-radius: 10px;
    background-color: white;
  }
}

@keyframes grow-captcha {
  from {
    max-width: 48px;
    height: 48px;
    border-radius: 100%;
  }

  to {
    max-width: 75vw;
    width: 380px;
    height: 520px;
    border-radius: 10px;
    background-color: white;
  }
}

@keyframes shrink {
  from {
    max-width: 75vw;
    width: 380px;
    height: 424px;
    border-radius: 10px;
    background-color: white;
  }

  to {
    max-width: 48px;
    height: 48px;
    border-radius: 100%;
  }
}

@keyframes shrink-captcha {
  from {
    max-width: 75vw;
    width: 380px;
    height: 520px;
    border-radius: 10px;
    background-color: white;
  }

  to {
    max-width: 48px;
    height: 48px;
    border-radius: 100%;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes grow-external {
  from {
    max-width: 48px;
    height: 48px;
    max-height: 0;
    border-radius: 10px;
    opacity: 0;
    background-color: transparent;
    z-index: 6;
  }

  to {
    max-width: 75vw;
    width: 380px;
    max-height: 1500px;
    border-radius: 10px;
    background-color: white;
    z-index: 6;
    opacity: 1;
  }
}

@keyframes grow-captcha-external {
  from {
    max-width: 48px;
    height: 48px;
    border-radius: 10px;
    opacity: 0;
    background-color: transparent;
    z-index: 6;
  }

  to {
    max-width: 75vw;
    width: 380px;
    height: 520px;
    border-radius: 10px;
    background-color: white;
    z-index: 6;
    opacity: 1;
  }
}

@keyframes shrink-external {
  from {
    max-width: 75vw;
    width: 380px;
    height: 424px;
    z-index: 6;
    border-radius: 10px;
    background-color: white;
    opacity: 1;
  }

  to {
    background-color: transparent;
    opacity: 0;
    z-index: 6;
    max-width: 48px;
    border-radius: 10px;
    height: 48px;
  }
}

@keyframes shrink-external-captcha {
  from {
    max-width: 75vw;
    width: 380px;
    height: 520px;
    z-index: 6;
    border-radius: 10px;
    background-color: white;
    opacity: 1;
  }

  to {
    background-color: transparent;
    opacity: 0;
    z-index: 6;
    max-width: 48px;
    border-radius: 10px;
    height: 48px;
  }
}

@media (max-width: 670px) {
  @keyframes grow {
    from {
      max-width: 48px;
      height: 48px;
      border-radius: 100%;
    }

    to {
      height: 424px;
      border-radius: 10px;
      background-color: white;
      bottom: 0;
      right: 0;
      width: calc(100% - 32px);
      max-width: 100%;
      margin: 0 16px 16px;
    }
  }

  @keyframes grow-captcha {
    from {
      max-width: 48px;
      height: 48px;
      border-radius: 100%;
    }

    to {
      height: 520px;
      border-radius: 10px;
      background-color: white;
      bottom: 0;
      right: 0;
      width: calc(100% - 32px);
      max-width: 100%;
      margin: 0 16px 16px;
    }
  }

  @keyframes shrink {
    from {
      height: 424px;
      border-radius: 10px;
      background-color: white;
      bottom: 0;
      right: 0;
      width: calc(100% - 32px);
      max-width: 100%;
      margin: 0 16px 16px;
    }

    to {
      max-width: 48px;
      height: 48px;
      border-radius: 100%;
    }
  }

  @keyframes shrink-captcha {
    from {
      height: 520px;
      border-radius: 10px;
      background-color: white;
      bottom: 0;
      right: 0;
      width: calc(100% - 32px);
      max-width: 100%;
      margin: 0 16px 16px;
    }

    to {
      max-width: 48px;
      height: 48px;
      border-radius: 100%;
    }
  }

  @keyframes grow-external {
    from {
      max-width: 48px;
      max-height: 48px;
      border-radius: 10px;
      z-index: 6;
      opacity: 0;
      background-color: transparent;
    }

    to {
      max-height: 1200px;
      border-radius: 10px;
      background-color: white;
      z-index: 6;
      bottom: 0;
      right: 0;
      width: calc(100% - 32px);
      max-width: 100%;
      margin: 0 16px 16px;
      opacity: 1;
    }
  }

  @keyframes grow-captcha-external {
    from {
      max-width: 48px;
      height: 48px;
      border-radius: 10px;
      opacity: 0;
      background-color: transparent;
      z-index: 6;
    }

    to {
      max-width: 75vw;
      width: 380px;
      height: 520px;
      border-radius: 10px;
      background-color: white;
      z-index: 6;
      opacity: 1;
    }
  }

  @keyframes shrink-external {
    from {
      height: 424px;
      border-radius: 10px;
      background-color: white;
      bottom: 0;
      z-index: 6;
      right: 0;
      width: calc(100% - 32px);
      max-width: 100%;
      margin: 0 16px 16px;
      opacity: 1;
    }

    to {
      background-color: transparent;
      z-index: 6;
      max-width: 48px;
      border-radius: 10px;
      height: 48px;
      opacity: 0;
    }
  }

  @keyframes shrink-external-captcha {
    from {
      height: 520px;
      border-radius: 10px;
      background-color: white;
      bottom: 0;
      z-index: 6;
      right: 0;
      width: calc(100% - 32px);
      max-width: 100%;
      margin: 0 16px 16px;
      opacity: 1;
    }

    to {
      background-color: transparent;
      z-index: 6;
      max-width: 48px;
      border-radius: 10px;
      height: 48px;
      opacity: 0;
    }
  }
}
