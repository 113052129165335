@import "node_modules/react-image-gallery/styles/scss/image-gallery.scss";

.accomodation-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  margin-bottom: 30px;

  .accomodation-title {
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 40px;
    color: var(--gray-900);
  }

  .accomodation-found {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 24px;
    color: var(--gray-900);
    text-align: right;
  }
}

.accomodation-container-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  position: relative;
  top: 0;

  &.warning-bar-adjustment {
    margin-top: 0;
  }

  .topside-wrapper {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 40px;
    top: -40px;

    .topside-inner-wrapper {
      display: flex;
      padding: 40px 32px;
      width: 100%;
      max-width: var(--desktop-view);
      flex-wrap: wrap;

      .navigate-back-abstracts {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 10px;
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--gray-500);
        margin-bottom: 12px;

        .MuiSvgIcon-root {
          width: 18px;
          height: 18px;
        }

        &:hover {
          color: var(--primary-600);
          text-decoration: underline;
        }
      }

      .hotels-list {
        flex: 1;
        padding-left: 30px;

        .hotel-name {
          font-style: normal;
          font-weight: 500;
          font-size: 25px;
          line-height: 29px;
          color: var(--gray-700);
        }

        .hotel-address {
          flex: 1 0 100%;
          color: var(--gray-500);
          margin-bottom: 10px;
        }

        .hotel-visual-info {
          display: flex;

          .hotel-slider {
            width: 60%;
          }

          .hotel-map {
            width: 40%;
            padding-left: 14px;

            &.sole-component {
              width: 100%;
            }
          }
        }

        .description {
          flex: 1 0 100%;
          margin-top: 15px;
          color: var(--gray-500);
        }
      }
    }
  }

  .hotels-wrapper {
    margin: 0 32px;
    position: relative;

    &.inner {
      top: -40px;
      max-width: var(--desktop-view);
      justify-self: center;
      align-self: center;
      margin: 0;
      width: 100%;
      padding: 0 32px;
    }

    &.hotel-room-card-wrapper {
      width: 100%;
      margin-top: 50px;
    }

    &.hotel-room-card {
      display: flex;
      flex-direction: column;
      gap: 25px;

      .room-booking-header {
        display: flex;
        justify-content: space-between;

        .room-types {
          color: var(--gray-700);
          font-style: normal;
          font-weight: 500;
          font-size: 25px;
          line-height: 29px;
        }

        .results-found {
          color: var(--gray-700);
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 24px;
          text-align: right;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .accomodation-container-inner {
    flex-direction: column;

    .topside-wrapper {
      flex-direction: column;
      padding: 40px 32px 0;

      .topside-inner-wrapper {
        flex-direction: column;
        padding: 0;

        .hotels-list {
          flex-direction: column;
          padding-left: 0;

          .hotel-visual-info {
            row-gap: 20px;
            display: flex;
            flex-direction: column;

            .hotel-slider {
              width: 100%;
            }

            .hotel-map {
              padding-left: 0;
              width: 100%;
            }
          }
        }
      }
    }

    .hotels-wrapper {
      padding: 0 32px;
    }
  }
}

@media (max-width: 834px) {
  .accomodation-container-inner .topside-wrapper {
    padding: 40px 16px 0;
  }

  .accomodation-container-inner .hotels-wrapper.inner {
    padding: 0 16px;
  }
}

.image-gallery,
.image-gallery-content,
.image-gallery-slide-wrapper,
.image-gallery-slides,
.image-gallery-swipe,
.image-gallery-slide,
.image-gallery-image {
  width: 100%;
  border-radius: 10px;
}

.image-gallery {
  max-height: 300px;
}

.image-gallery-image img {
  object-fit: cover;
}

.image-gallery-fullscreen-button {
  font-size: 8px;
}

.image-gallery-thumbnail-inner {
  img {
    border-radius: 10px;
  }
}

@media (max-width: 1200px) {
  .image-gallery-image img {
    max-height: 300px;
  }
}
