.app-wrapper {
  .MuiChip-label {
    padding: 0;
  }

  .left-side .vertical-center {
    display: flex;
    align-items: center;
  }

  .registration-form {
    .all-payment-ways,
    .MuiFormControl-root {
      width: 100%;
    }

    .speech-bubble {
      text-align: center;
      box-shadow: 0 0 20px 0 #e9e9e9;
      font-weight: bold;
      color: #415560 !important;
    }

    .Collapsible__contentOuter {
      overflow: inherit !important;
    }

    .Collapsible {
      &.hidden {
        overflow: hidden;
      }
    }

    .Collapsible__trigger > span {
      color: #404f54 !important;
      font-weight: 500 !important;
    }

    width: 100%;
    max-width: 1024px;
    margin: auto;

    .kmb-totalPrice {
      min-width: 100px;
      font-weight: 700;
      background: #b8ddff;
      color: #415560;
    }

    .payment-radio {
      padding: 15px !important;
    }

    .payment-radio:hover {
      box-shadow: 0 5px 20px #e6f4f9;
    }

    .receipt-products {
      background-color: rgb(253 253 253);
      border: 1px solid #e6f4f9;
      padding: 15px;
    }

    .shopping-cart {
      position: relative;
      background: white;
      top: -19px;
      font-size: 23px;
      color: #415560;
      font-style: italic;
      padding-left: 10px;
      font-weight: 700;
    }

    .shopping-cart > span {
      margin-right: 10px;
    }
  }

  .add-remove {
    //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-user-select: none;
    user-select: none;
    min-width: 95px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;

    button:focus {
      outline: 0;
    }

    .MuiIconButton-root {
      padding: 0;
    }

    span.fas:hover {
      color: #2db5d4;
    }
  }

  > .splash {
    height: 100%;
  }

  .signup {
    .container {
      text-align: left;
    }
  }

  .pay-method .radio-wrapper {
    border-top: 1px solid #f7f7f7;
    padding: 45px 0;

    label {
      cursor: pointer;
    }
  }

  .bottom-area {
    position: relative;
  }

  .products-table {
    min-width: 500px;
    overflow: auto;

    .product-input {
      transform: scale(1.5);
      pointer-events: none;
    }

    .product-quantity {
      background-color: whitesmoke;
      border-radius: 10px;
      padding: 5px;
      margin: 0 10px;
    }

    .product-quantity-alt {
      display: inline-block;
      width: 42px;
    }
  }

  .card-logo {
    border: 1px solid var(--gray-100);
    margin: 0 2px 0 15px;
  }

  .card-logo-maesto {
    margin: 0 2px;
    border: 1px solid var(--gray-100);
    padding: 3px;
  }

  .card-logo-mastercard {
    margin: 0 2px;
    border: 1px solid var(--gray-100);
    padding: 1px;
  }

  .card-logo-visa {
    margin: 0 2px;
    border: 1px solid var(--gray-100);
    padding: 5px;
  }

  .login-screen,
  .signup-screen,
  .forgotpassword-screen,
  .resetpassword-screen,
  .questionnaire-screen {
    .container {
      height: 100%;
      text-align: left;

      .form-group {
        .input-label {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--gray-500);
        }
      }

      .form-group > span > label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray-500);
      }

      .form-group > span > .kmb-radio {
        margin: 0;
      }
    }
  }

  .login-screen .Collapsible {
    width: 100% !important;
  }

  .signup-screen {
    .disclaimer {
      background-color: whitesmoke;
      border: 1px solid #999;
      padding: 20px;
    }

    .container {
      .password-visibility {
        margin-top: 5px;
      }
    }
  }

  .splash {
    height: 100%;
    margin: auto;

    .pay-method {
      .radio-wrapper-selected {
        background-color: #e5f8ff !important;
      }

      .radio-wrapper {
        padding: 0 15px;
      }
    }

    .bank-data {
      text-align: left;

      input,
      .upper-area,
      select {
        font-size: 14px;
        padding: 3px !important;
        height: 30px !important;
      }

      select {
        background: white;
        width: 100%;
        border-radius: 4px;
        color: #4a4a4a;
      }

      label {
        margin-bottom: 2px !important;
        font-size: 14px;
      }

      .form-group {
        margin-bottom: 5px !important;
      }
    }

    .alert {
      &.danger {
        text-align: center;
        color: var(--error-600);
        background-color: transparent;
        padding: 0.75rem 0;
        border: none;
      }
    }

    &.forgotPassSuccess {
      > .vertical-center {
        height: '100%';
      }
    }

    &.signup-screen {
      .password-visibility {
        right: 35px;
      }

      .complete-or-validate {
        min-width: 100%;

        .agree {
          max-width: 100%;
          width: 400px;
          margin: auto;
          text-align: center;
          padding: 50px 0;
        }
      }

      .invalid-token {
        padding-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
          margin-bottom: 50px;
        }

        .btn {
          margin-top: 50px;
          min-width: 200px;
        }

        .warning-message {
          width: 100%;
          max-width: 400px;
          border: 1px solid var(--warning-500);
          background-color: var(--warning-50);
          border-radius: 8px;
          display: flex;
          align-items: center;
          padding: 16px;

          .warning-icon-wrapper {
            display: flex;
            align-items: baseline;

            .warning-icon {
              color: var(--gray-400);
            }
          }

          .warning-message-text {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            padding-left: 16px;
            color: var(--gray-500);
          }
        }
      }
    }

    .change-screen {
      color: var(--primary-600);
      font-size: 14px;
      font-weight: 500;
      border-bottom: 1px solid;
      display: inline-block;
      word-break: break-word;
      cursor: pointer;

      &:not(.go-signup) {
        max-width: max-content;
      }

      &.go-signup {
        margin-right: 15px;
        text-align: center;
        background-color: var(--primary-600);
        line-height: 35px;
        color: white;
        border-radius: 4px;
      }
    }

    h1 {
      color: #404040;
      font-weight: 500;
      font-size: 28px;
    }

    .screen-description {
      font-size: 24px;
      color: #919191;
      margin-bottom: 40px;
    }

    .select-advanced {
      &.multiselect {
        padding: 6px 0;
      }
    }

    .select-advanced.multiselect .options-wrapper li {
      color: var(--gray-500);
    }

    input,
    .upper-area {
      border: 1px solid var(--gray-100);
      border-radius: 5px;
      height: 48px;
      font-size: 14px;
      color: var(--gray-300);
      padding: 0 20px;
      box-shadow: none;
    }

    input.radio-input {
      height: auto;
    }

    .upper-area {
      padding: 13px 20px;
    }

    .form-group {
      position: relative;
      align-self: flex-start;
    }

    .password-visibility {
      position: absolute;
      bottom: 5px;
      left: 91%;
      margin-top: -7px;
      cursor: pointer;
    }

    .presentational {
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      text-align: right;
      top: 0;
      left: 0;

      .presentational-inner {
        width: 100%;
        height: 100%;
        background-color: whitesmoke;
      }

      .event-banner {
        overflow: hidden;

        img.blur {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          object-fit: cover !important;
          filter: blur(100px) !important;
          opacity: 0.5;
        }

        &::before {
          margin: 0;
        }

        img.real {
          width: 100%;
          height: 100%;
          position: relative;
          object-fit: contain;
        }
      }
    }

    .left-side {
      flex-direction: column;
      background-color: white;

      .event-header {
        margin: 32px 0 0 17px;
        width: calc(100% - 32px);
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 64px;

        .event-logo-wrapper {
          height: 64px;
          object-fit: contain;

          img {
            height: 100%;
          }
        }

        .event-title {
          margin-left: 12px;
          color: var(--gray-900);
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 24px;
        }
      }

      > div {
        text-align: right;
      }
    }

    .right-side {
      padding: 0;
      height: 100%;
      max-height: 100%;
      position: fixed;
      top: 0;
      right: 0;
    }

    .form-container {
      .form-inner-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      max-width: 850px;
      text-align: left !important;

      form {
        padding: 24px 0;
        max-width: 500px;
      }
    }

    .btn-primary {
      padding: 10px 55px;
    }
  }

  .contact-button {
    text-transform: none;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .submit-container {
    margin-top: 24px;

    .btn {
      width: 100%;
    }
  }

  .splash-container {
    width: 100%;
    height: 100%;

    .container {
      padding: 0 32px;
      width: 100%;
      max-width: 100%;
    }

    img {
      max-width: 100%;
    }
  }
}

.splash-header {
  position: sticky;
  z-index: 4;
  top: 0;
  width: 100%;
  height: 80px;
  gap: 12px;
  background: var(--blue-gray-50);
  border-bottom: 1px solid var(--gray-25);
  display: flex;
  align-items: center;
  justify-content: center;

  .splash-header-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    max-width: 1280px;
    padding: 0 32px;
  }
}

.login-form {
  .expired-token {
    color: var(--error-600);
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .splash-screen-title {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: var(--gray-700);
  }

  .login-info-wrapper {
    color: var(--gray-500);
    margin-bottom: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .login-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .forgot-pass-wrapper {
      z-index: 1;
      color: var(--primary-600);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;

      &:hover {
        color: var(--primary-700);
        text-decoration: underline;
      }
    }
  }

  .submit-container {
    button {
      height: 44px;
    }

    &.forgot-password {
      button {
        margin-top: 24px;
        padding: 10px 0;
      }
    }
  }

  .login-helper-header {
    display: flex;
    align-items: center;
    flex-direction: column;

    .login-helper-icon {
      margin-bottom: 24px;
    }

    .login-helper-title {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: var(--gray-700);
      margin-bottom: 12px;
      text-align: center;
    }

    .login-helper-message {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--gray-500);
      margin-bottom: 32px;
      text-align: center;
    }

    .login-helper-finalize-text {
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray-500);

      &.recaptcha-adjustment {
        margin-top: 16px;
      }

      .redo-link {
        cursor: pointer;
        color: var(--primary-600);

        &:hover {
          color: var(--primary-700);
          text-decoration: underline;
        }
      }
    }
  }

  .go-to-screen-link {
    margin-top: 32px;
    text-align: center;

    .non-navigational {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: var(--gray-700);
      line-height: 20px;
    }

    .navigational {
      color: var(--primary-600);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;

      &.back-to-login {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 12px;

        .go-back-icon {
          height: 18px;
        }
      }

      &:hover {
        color: var(--primary-700);
        text-decoration: underline;
      }
    }
  }
}

.signup-screen {
  .fomr-container {
    max-width: unset;
  }
}

@media (max-width: 834px) {
  .signup-screen {
    .vertical-center {
      height: calc(100% - 260px) !important;
    }
  }

  .app-wrapper {
    .submit-container {
      .btn {
        width: 100%;
      }
    }

    .splash {
      .right-side {
        &.public {
          top: 80px;
        }

        position: relative;
        top: 0;
        right: 0;
        z-index: 1;
      }
    }
  }

  .component-register {
    .row {
      flex-direction: column;

      .right-side {
        max-height: 80px !important;
        height: 80px;
      }

      .left-side {
        flex: 1;
      }
    }
  }

  .splash-container {
    .container {
      padding-left: 0 !important;

      .row {
        flex-direction: column;
      }
    }
  }

  .app-wrapper .splash-container {
    .submit-container {
      .btn {
        width: 100%;
      }
    }

    .right-side {
      max-height: 80px !important;
      max-width: 100% !important;
      height: 80px;

      .event-banner .vertical-center {
        background-color: white;
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .left-side {
      padding: 32px;
      flex: 1;
      max-width: 100% !important;
    }

    .presentational {
      text-align: center;

      .event-banner {
        width: 100% !important;
        margin-bottom: 30px;
      }

      .presentational-inner {
        transform: translateX(0);
      }
    }
  }
}

@media (max-width: 834px) {
  .right-side {
    order: unset !important;
  }

  .splash-header-wrapper {
    padding: 0 16px !important;
  }

  .app-wrapper {
    .splash {
      .form-container {
        .form-inner-wrapper {
          justify-content: flex-start;
        }
      }
    }
  }

  .splash-container {
    .container {
      width: 100%;
      max-width: 100%;
      padding: 32px 16px !important;
    }
  }

  .app-wrapper .splash-container {
    .registration-form {
      width: 100%;
    }
  }
}
