.abstract-card {
  &:hover {
    box-shadow: 0 3px 14px 0 var(--gray-25), 0 4px 6px -2px var(--gray-25);
  }

  width: 100%;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 3px 14px 0 var(--gray-25), 0 4px 6px -2px var(--gray-25);
  padding: 32px;
  display: flex;
  justify-content: center;
  column-gap: 24px;

  &.abstract-card-list-item {
    .thumbnail-wrapper,
    .abstract-title {
      cursor: pointer;
    }

    .abstract-title {
      &:hover {
        text-decoration: underline;
        color: var(--primary-600);
      }
    }
  }

  .thumbnail-wrapper {
    width: 230px;
    min-width: 230px;
    max-width: 230px;
    height: 320px;
    max-height: 320px;
    min-height: 320px;
    background-color: var(--gray-50);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .icon-presentation {
      color: var(--gray-25);
      width: 100px;
      height: 100px;
    }

    .image-wrapper {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .abstract-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 230px);

    &.no-thumbnail {
      max-width: 100%;
    }

    .abstract-main-text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
      color: var(--gray-500);
      //TODO: Once line-clamp receives official CSS support, this should be changed to not use webkit
      /* stylelint-disable-next-line value-no-vendor-prefix */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-wrap: break-word;
      word-break: break-all;
      max-width: 100%;
      width: 100%;

      &.show-full {
        display: flex;
        -webkit-line-clamp: initial;
      }

      &.single-abstract-preview {
        margin-top: 16px;
      }
    }

    .abstract-action-bar {
      display: flex;
      column-gap: 8px;
      margin-bottom: 16px;

      &.single-abstract-preview {
        justify-content: space-between;
        align-items: center;

        .left-side-actions {
          display: flex;
          flex-direction: column;
          row-gap: 4px;

          .top-actions {
            display: flex;
            justify-content: center;
            column-gap: 8px;

            .download-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            }
          }
        }
      }

      button {
        height: 36px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;
        text-transform: none;
        box-shadow: none;

        &.toggle-more,
        &.share-abstract {
          border: 1px solid var(--gray-100);
          background-color: white;
          color: var(--gray-700);
        }

        &.view-file,
        &.download-file {
          background-color: var(--primary-600);
          color: white;
        }

        &.Mui-disabled {
          background-color: var(--primary-100);
        }

        &.share-abstract {
          &.Mui-disabled {
            border: 1px solid var(--gray-50);
            color: var(--gray-200);
            background-color: white;
          }

          .MuiSvgIcon-root {
            width: 18px;
            height: 18px;
          }

          .MuiButton-label {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 8px;
          }
        }
      }
    }

    .card-section-divider {
      background-color: var(--gray-25);
      height: 1px;
      margin-bottom: 6px;
    }
  }


}

.file-size-text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-300);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  &.preview-mobile {
    display: none;
  }

  &.preview {
    display: flex;
  }
}



@media (max-width: 834px) {
  .abstract-card {
    padding: 16px;
    flex-direction: column;
    row-gap: 24px;

    .thumbnail-wrapper {
      width: 100%;
      min-width: none;
      max-width: none;
      height: 300px;
      max-height: 300px;
      min-height: 300px;

      img {
        object-fit: contain;
        max-height: 300px;
      }
    }

    .abstract-info {
      width: 100%;
      max-width: 100%;

      .abstract-action-bar {
        flex-wrap: wrap;
        row-gap: 8px;

        button {
          height: 44px;
          display: flex;
          justify-content: center !important;
          align-items: center;
        }

        .toggle-more {
          width: 100%;
        }

        .view-file,
        .download-file {
          width: 100%;
        }

        > div {
          width: calc(50% - 4px);
        }

        .left-side-actions {
          width: 100%;

          .top-actions {
            flex-wrap: wrap;

            .view-file {
              width: calc(50% - 4px);
            }

            .download-wrapper {
              width: calc(50% - 4px);

              .download-file {
                width: 100%;
              }
            }

            .share-abstract {
              width: 100%;
            }
          }
        }

        .file-size-text {
          width: 100%;
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          &.preview-mobile {
            display: flex;
            margin: 8px 0;
          }

          &.preview {
            display: none;
          }
        }
      }
    }
  }
}
