.event-info {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 10px;

  .event-logo-wrapper {
    display: flex;
    max-height: 38px;
    height: 38px;

    img {
      border-radius: 4px;
    }

    .event-logo {
      height: 100%;
      align-self: center;
      object-fit: contain;
      object-position: left;
    }
  }

  .event-name {
    margin-left: 12px;
    font-size: 18px;
    color: var(--gray-900);
    text-align: left;
    font-weight: 600;
    overflow: hidden;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.radio-coloring {
  padding-right: 16px;

  .MuiButtonBase-root {
    color: var(--gray-300);
    margin-right: 8px;
  }
}

.color-label {
  color: var(--gray-700);
}

.color-label > .MuiStepLabel-labelContainer > .MuiStepLabel-label {
  color: currentcolor;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.step-container {
  width: 85%;
  align-self: center;
}

.horizontal-stepper {
  margin-top: 30px;
}

.vertical-stepper {
  padding: 0 24px;
}

.section-title {
  padding: 46px 0 16px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  color: var(--gray-700);
}

.step-card {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  justify-content: space-between;
  min-height: 400px;
  row-gap: 24px;
  column-gap: 32px;

  .product-table .normal-table {
    width: 100%;

    table {
      background: white;

      tr {
        box-shadow: none !important;
        cursor: default;
      }
    }
  }

  .form-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0;
  }

  .section-wrapper {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: var(--gray-900);
    width: 100%;
    margin-bottom: 2px;
    margin-top: 24px;
  }

  .error-msg {
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    display: none;
    color: var(--error-500);

    .btn-container {
      padding-top: 40px;
    }
  }

  .info {
    text-align: left;
    justify-content: center;
    flex-basis: 100%;
    padding-top: 10px;
    font-size: 12px;
  }

  @media (max-width: 834px) {
    .info {
      padding-bottom: 8px;
    }
  }

  .product-quantity {
    border: 1px solid;
    border-radius: 100%;
    padding-right: 4px;
    padding-left: 4px;
    padding-bottom: 1px;
    cursor: pointer;
  }

  .minus {
    padding-right: 6px;
    padding-left: 6px;
  }

  .field {
    display: flex;
    flex-wrap: wrap;
    flex-basis: calc(50% - 16px);
    align-items: flex-start;
    justify-content: flex-start;

    .kmb-radio {
      margin: 0;
      display: flex;
      width: 100%;
      height: 62px;

      .MuiFormGroup-root {
        display: flex;
        flex-flow: row nowrap;
      }

      .MuiFormControlLabel-root {
        margin: 0;
      }
    }

    .icon-wrapper {
      position: absolute;
      top: 38.33px;
      right: 18px;

      &.error-icon {
        color: var(--error-500);
      }
    }

    .required-field-message {
      color: var(--error-500);
      border: 0;
      width: 100%;
      height: 18px;
      position: absolute;
      top: 80px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;

      &.text-area-required-message {
        top: auto;
        bottom: -26px;
      }
    }

    label {
      position: static;
      left: 0;
      top: 0;
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-bottom: 0;
      width: 100%;
      height: max-content;
    }

    .select-advanced {
      flex-basis: 100%;
    }

    .field-input {
      outline: none;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 16px;
      position: static;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      margin-top: 6px;
      height: 48px;
      left: 0;
      top: 26px;
      border: 1px solid;
      box-sizing: border-box;
      border-radius: 5px;
      color: var(--gray-500);
      background-color: white;
      border-color: var(--gray-100);
    }

    .text-area {
      resize: none;
      outline: none;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 16px;
      position: static;
      flex: 0 0 auto;
      order: 1;
      align-self: stretch;
      margin: 6px 0;
      left: 0;
      top: 26px;
      border-width: 1px;
      border-style: solid;
      border-image: initial;
      box-sizing: border-box;
      border-radius: 5px;
      color: var(--gray-500);
      background-color: white;
      border-color: var(--gray-100);
    }

    .field-error {
      color: var(--error-500);
      background-color: var(--error-100);
      border-color: var(--error-100);
    }

    .field-input-disabled {
      background-color: var(--blue-50);
    }
  }

  .yes-no-label {
    width: 100%;
  }

  .general-info-text,
  .login-info-text {
    width: 100%;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: var(--gray-500);
    padding-bottom: 26px;

    &.full-margin {
      margin-bottom: -34px;
      width: 100%;
    }
  }

  .btn-container {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
    gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 40px;
    box-shadow: 0 0 0 rgba(0 0 0 / 0%);
    width: 100%;

    .complete-button {
      background-color: var(--primary-600);
      align-items: center;
      justify-content: center;
    }

    &.flex-end {
      justify-content: flex-end;
    }

    .step-btn-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .fill-in-fields-mobile {
      display: none;
    }

    .bottom-info {
      width: 100%;
      display: flex;

      .cancel-registration {
        width: 50%;
        align-self: flex-start;
        text-decoration: underline;
        cursor: pointer;
      }

      .fill-in-fields {
        width: 50%;
        display: none;
        align-self: flex-end;
        text-align: end;
        color: var(--error-500);

        &.visible-fill-in-fields {
          display: initial;
        }
      }
    }

    button {
      border-radius: 4px;
      color: white;
      font-size: 18px;
      padding: 9px 7px;
      min-width: 250px;
      border: 0;
      cursor: pointer;
    }

    .reverse {
      background-color: white;
      border: 1px solid;
    }

    .step-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: Lato;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      &.row-reverse {
        align-items: flex-end;
      }

      .btn-next-wrapper {
        display: flex;
        align-items: center;
      }

      .arrow-container {
        height: 100%;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        margin-right: 15px;
      }

      .rest-of-btn {
        width: 80%;
        flex-grow: 1;
      }

      .btn-txt-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(92% - 23px);
        margin-left: 7px;
      }

      .btn-txt {
        text-align: left;
        width: 100%;
      }

      .txt-mobile {
        font-size: 16px;
        display: none;
      }

      .txt-1 {
        font-size: 16px;
        display: initial;
      }

      .txt-2 {
        font-size: 14px;
        display: initial;
      }

      &.back-btn {
        border-color: var(--gray-700);
        color: var(--gray-700);
      }
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  @media (min-width: 835px) {
    .cost-sum-mobile {
      display: none;
    }
  }

  @media (max-width: 834px) {
    .fill-in-fields-mobile {
      justify-content: center;
      display: none;

      &.fill-in-fields-mobile-visible {
        display: flex;
      }
    }

    .fill-in-fields {
      display: none;
    }

    .error-msg-desktop {
      display: none !important;
    }

    .cost-sum-desktop {
      display: none;
    }

    .cost-sum {
      margin-right: 10%;
    }

    .btn-container {
      position: sticky;
      bottom: 0;
      background: white;
      width: calc(100% + 32px);
      margin-bottom: 0;
      left: 0;
      padding-top: 30px;
      padding-bottom: 23px;
      height: auto;
      display: flex;
      justify-content: space-around;
      box-shadow: -20px 0 white, 20px 0 white, 0 -20px 24px rgb(0 0 0 / 13%);

      .step-btn-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0 16px;
        gap: 10px;
      }

      .cost-sum-mobile {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .cost-wrapper {
          display: flex;
          margin-right: 16px;

          .text-wrapper {
            .overall-price-title {
              font-style: normal;
              font-weight: bold;
              font-size: 20px;
              line-height: 30px;
              color: var(--gray-900);
            }

            .taxes-message-mobile {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: var(--gray-500);
            }
          }

          .overall-price {
            font-weight: bold;
            font-size: 20px;
            line-height: 30px;
            color: var(--gray-900);
          }
        }
      }

      .bottom-info {
        display: flex;
        justify-content: center;

        .cancel-registration {
          width: auto;
          text-align: center;
          height: 20px;
        }

        .fill-in-fields {
          display: none;

          &.visible-fill-in-fields {
            display: none;
          }
        }

        .fill-in-fields-mobile {
          justify-content: center;
          display: none;

          &.fill-in-fields-mobile-visible {
            display: flex;
          }
        }
      }

      .fill-in-fields-mobile {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        align-items: center;
        justify-content: center;
        color: var(--error-500);
      }

      button {
        border-radius: 4px;
        color: white;
        font-size: 18px;
        padding: 9px 7px;
        min-width: 150px;
        height: 48px;
        border: 0;
        cursor: pointer;
        display: flex;
      }

      .step-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        .btn-next-wrapper {
          display: flex;
          align-items: center;
        }

        &.single {
          flex: 1;
          justify-content: center;
          align-items: center;
        }

        .arrow-container {
          height: 100%;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 8px;
          margin-right: 15px;
        }

        .rest-of-btn {
          width: 80%;
          flex-grow: 1;
        }

        .btn-txt-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-left: 7px;
        }

        .btn-txt {
          text-align: center;
          width: 100%;
        }

        .txt-mobile {
          font-size: 16px;
          display: initial;
        }

        .txt-1 {
          font-size: 16px;
          display: none;
        }

        .txt-2 {
          font-size: 14px;
          display: none;
        }
      }

      .full-back-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .arrow-container {
          margin-right: 15px;
          margin-left: 0;
          width: auto;
        }

        .txt-container {
          max-width: 200px;
        }
      }

      &.single-btn-container {
        padding: 30px 16px 23px;
      }
    }
  }

  .padding-bot {
    padding-bottom: 25px;
  }

  .product-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mobile-table {
      display: none;
    }
  }

  .all-payment-ways {
    flex-basis: 45%;
    align-self: baseline;
    display: flex;
    flex-direction: column;

    .bank-data {
      display: flex;
      flex-wrap: wrap;

      input {
        height: 42px !important;
        padding: 0 10px !important;
      }
    }

    .terms-of-service {
      padding-left: 10px;
    }
  }
}

.form-group {
  & label {
    color: var(--gray-700);

    &.has-error {
      color: var(--error-500);
    }
  }
  &.has-error > div > div:first-of-type:not(.select-checkboxes-outer) {
    color: var(--error-500);
    div[class*="-placeholder"] {
      color: var(--error-400);
    }
    border: 1px solid var(--error-500) !important;
    background-color: var(--error-100);
  }
  #react-select-2-listbox > div > div {
    color: #212529 !important;
  }
  .has-error {
    label {
      color: var(--error-500);
    }
    input {
      border: 1px solid var(--error-500) !important;
      background-color: var(--error-100);
      &::placeholder {
        color: var(--error-400);
      }
    }
  }

  & input {
    width: 100%;
    height: 41px;
    border: 1px solid var(--gray-100);
    padding: 5px;
  }

  &.has-error {
    > input,
    > textarea,
    > div:not(.form-control-topics, .kmb-radio, .select-advanced, .select-checkboxes-outer) {
      border: 1px solid var(--error-500) !important;
      color: var(--error-500);
    }
    .css-1okebmr-indicatorSeparator {
      background-color: var(--error-500);
    }
    svg {
      color: var(--error-500);
    }
    > .kmb-radio {
      border: none !important;

      fieldset {
        div {
          label {
            span:first-child {
              color: var(--error-500);
            }
          }
        }
      }
    }

    .multiselect {
      border: none !important;

      .select-checkboxes-outer {
        .upper-area {
          color: var(--error-500);
          background-color: var(--error-100);
          border: 1px solid var(--error-500) !important;

          .icon-arrow-down,
          .icon-arrow-up {
            right: 24px;
            position: relative;
          }
        }
      }
    }

    > textarea {
      background-color: var(--error-100) !important;
      color: var(--error-500) !important;
    }

    textarea::placeholder {
      color: var(--error-400);
    }

    .rs-header {
      border: 1px solid var(--error-500);
    }

    > label {
      color: var(--error-500);
    }
  }
}

.component-register {
  height: 100%;

  .left-side {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;

    .form-container {
      max-width: 440px;

      .form-inner-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .splash-screen-title {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;
        color: var(--gray-700);
        margin-bottom: 12px;
      }

      p {
        text-align: left;
        padding: 0 !important;
      }

      button {
        margin-top: 40px;
      }

      .go-to-screen-link {
        margin-top: 32px;
        text-align: center;

        .non-navigational {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: var(--gray-700);
          line-height: 20px;
        }

        .navigational {
          color: var(--primary-600);
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          cursor: pointer;

          &.back-to-login {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 12px;

            .go-back-icon {
              height: 18px;
            }
          }

          &:hover {
            color: var(--primary-700);
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.event-support {
  color: black;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 835) {
  .vertical-stepper {
    display: none;
  }
}

@media (max-width: 834px) {
  .btn-container {
    padding: 30px 16px 0;
  }

  .right-side {
    max-width: 100% !important;
  }

  .section-title {
    padding: 16px 0 0;
  }

  .horizontal-stepper {
    display: none;
  }

  .step-container {
    width: 100%;
    padding: 0 1px;
  }

  .step-card {
    .product-table {
      .normal-table {
        display: none;
      }

      .mobile-table {
        display: inline;
        width: 100%;

        .card {
          padding: 20px;
          border-radius: 16px;
          margin-bottom: 20px;

          .button {
            height: 45px;
            border-radius: 4px;
            font-size: 17px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 25px;
            text-align: center;
            color: white;
            font-family: Lato;
          }

          .card-id {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: var(--gray-500);
            padding-bottom: 4px;
          }

          .card-name {
            letter-spacing: 0;
            padding-bottom: 4px;
            color: var(--gray-900);
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
          }

          .card-price {
            color: var(--gray-600);
            padding-bottom: 4px;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 4px;
          }

          .card-description {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: var(--blue-900);
            overflow: hidden;
            margin-bottom: 14px;
          }

          .card-payments {
            display: flex;
          }

          .payment-icon {
            width: 20px;
            margin-right: 6px;
          }

          .card-quantity {
            margin-top: 14px;
            width: 100%;
            display: flex;
            justify-content: space-between;

            .quantity-change {
              width: 40%;
              min-width: 110px;
              display: flex;
              align-items: center;
            }

            .price-view {
              display: flex;
              align-items: center;
              text-align: right;
              font-style: normal;
              font-weight: bold;
              font-size: 30px;
              line-height: 38px;
              color: var(--gray-900);
            }

            .price-view.unavailable-price {
              color: var(--gray-300);
            }
          }

          .card-quantity-warning {
            .not-available-message {
              max-width: 100%;
              color: var(--gray-500);
            }
          }

          .card-info-container {
            display: flex;
            flex-wrap: wrap;

            .name {
              display: none;
            }

            .col {
              padding-left: 0;
            }

            .cosmetic {
              display: none;
            }

            .col-name {
              font-family: Lato;
              font-size: 13px;
              letter-spacing: 0;
              line-height: 17px;
              padding: 10px 10px 10px 0;
            }

            .col-entry {
              font-family: Lato;
              font-size: 15px;
              letter-spacing: 0;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 834px) {
  .step-card {
    .cost-sum {
      text-align: left;
    }

    .product-table {
      width: 100%;

      .cost-sum-wrapper {
        .cost-sum-desktop {
          display: flex;
        }
      }
    }

    .all-payment-ways {
      margin-bottom: 170px;
    }

    .all-payment-ways,
    .total-costs {
      padding-right: 0;
      flex-basis: 100%;

      .terms-of-service {
        padding-left: 10px;
      }

      .card-images {
        margin: 0;

        img:first-child {
          margin: 0 2px !important;
        }
      }
    }

    .field {
      flex-basis: 100%;
    }

    .full-margin {
      margin-bottom: 15px;
    }
  }

  .individual-costs {
    display: none;
  }

  .component-register {
    .left-side {
      padding: 5%;
    }
  }

  .btn-container.single-return {
    display: flex;
    justify-content: flex-start;
    padding: 14px;
  }
}
