.message-list {
  overflow-y: auto;
  padding: 4px;
  font-family: Inter;
}

.message-form .input-container {
  flex: 1;
  margin: 1px;
  display: flex;
  background-color: white;
  box-shadow: 0 1px 10px 0 rgba(0 0 0 / 20%);
  height: 45px;
  border: none;
  align-items: flex-end;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;

  button {
    border: none;
  }

  overflow: hidden;

  input {
    border: none;
  }
}

.message-form .button-container {
  flex: 0 0 6em;
  margin: 1px 1px 1px 0;
}

.message-form input,
.message-form button {
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: inherit;
  height: 100%;
  outline: none;
  width: 100%;
}

.message-form input {
  padding: 0 0 0 4px;
}

.message {
  line-height: 1.5em;
  margin-bottom: 14px;
  padding: 9px 12px;
  min-width: 120px;
  border-radius: 15px;
  background-color: #eff3f7;
  display: table;
  max-width: 80%;

  button {
    margin: 5px;
  }

  .main-3 {
    text-align: right;
  }

  .timestamp {
    text-align: right;
    font-size: 10px;
  }
}

.message.log {
  color: black;
  margin-left: auto;
  max-width: 80%;
  margin-right: 20px;
}

.message .author {
  font-weight: bold;
  color: #899;
  margin: 0 5px 0 0;
  white-space: nowrap;
}

.message .fa {
  color: rgba(117 117 117 / 100%);
  cursor: pointer;
  float: left;
  font-weight: 500;
  font-size: 13px;
  padding-right: 2px;
  padding-top: 2px;
}

.message.me .author {
  color: #368cfe;
}

.message.me {
  border-radius: 15px;
  background-color: var(--primary-600);
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.chat-container {
  border-radius: 10px;
  background-color: white;
  padding: 10px 5px 15px;
}

.kmb-chat {
  height: 95%;
  width: 100%;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.kmb-chat .message-list {
  box-sizing: content-box;
  overflow-y: scroll;
  width: 100%;
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
}

.download-container {
  text-align: right;

  span.download {
    color: cornflowerblue;
    cursor: pointer;
    font-size: 13px;
    padding-right: 5px;
  }
}

.kmb-chat .message-form {
  box-sizing: border-box;
  flex: 0 0 30px;
  display: flex;
  border-radius: 10px;
  padding: 10px;
  padding-right: 5px;
  padding-bottom: 10px;

  i {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .chat-container {
    box-shadow: 0 -0 43px 0 rgba(0 0 0 / 20%);
  }
}
