div.main-menu {
  height: 100%;
  margin: 0;
  position: relative;
  padding-top: 25px;
  font-family: Lato;
  width: 100%;

  > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
  }

  li {
    float: left;
    padding-bottom: 10px;
    padding-right: 20px;
    line-height: 20px;
    letter-spacing: 0;

    button.btn {
      color: #404040;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      box-shadow: none;
      background-color: transparent;
      outline: none;
      padding: 0;
      text-transform: uppercase;
    }

    &.active {
      &::before {
        content: '';
        width: 4px;
        height: 100%;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        color: var(--primary-600);
      }

      button.btn {
        font-size: 15px;
        font-weight: 400;
        color: var(--primary-600);
      }
    }
  }
}

@media (max-width: var(--desktop-view)) {
  div.main-menu {
    ul {
      li {
        padding-right: 10px;
      }
    }
  }
}

@media (max-width: 1300px) {
  div.main-menu {
    ul {
      li {
        padding-left: 0;
        padding-right: 10px;
      }
    }
  }
}

@media (max-width: 1260px) {
  div.main-menu {
    ul {
      li {
        padding-left: 0;
        padding-right: 12px;

        p {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  div.main-menu {
    padding-left: 45px;

    ul {
      max-width: 400px;
      flex-direction: column;

      li {
        padding-bottom: 0;

        button.btn {
          font-size: 18px;
          letter-spacing: 3px;
        }

        &.active {
          button.btn {
            font-size: 18px;
          }
        }
      }
    }
  }
}
