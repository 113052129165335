@import 'node_modules/react-image-gallery/styles/scss/image-gallery.scss';

.main-component.component-hotelmanagement {
  max-width: 100% !important;
  width: 100%;
  display: flex;
  justify-content: center;

  .component-inner {
    width: 100%;
  }
}

.hotel-management-wrapper {
  position: relative;
  max-width: 1216px;
  display: flex;
  column-gap: 32px;
  flex: 1;
}

.accomodation-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  margin-bottom: 30px;

  .accomodation-title {
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 40px;
    color: var(--gray-900);
  }

  .accomodation-found {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 24px;
    color: var(--gray-900);
    text-align: right;
  }
}

.accomodation-container {
  width: 100%;
  justify-content: center;
  display: flex;
  padding: 0 32px;
  gap: 30px;
  margin-top: 20px;

  .right-side-wrapper {
    flex-grow: 1;

    .hotels-list {
      .hotels-wrapper {
        margin-top: 42px;
        position: relative;
      }

      .hotel-name {
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 29px;
        color: var(--gray-700);
      }

      .hotel-address {
        flex: 1 0 100%;
        color: var(--gray-500);
        margin-bottom: 10px;
      }

      .hotel-visual-info {
        display: flex;

        .hotel-slider {
          width: 60%;
        }

        .hotel-map {
          width: 40%;

          &.sole-component {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 834px) {
  .accomodation-container {
    padding: 0 16px;
  }
}

@media (max-width: 1200px) {
  .accomodation-container {
    .hotel-management-wrapper {
      flex-direction: column;

      .right-side-wrapper {
        .hotels-list {
          .hotel-visual-info {
            flex-direction: column;
            row-gap: 20px;

            .hotel-slider {
              width: 100%;
            }

            .hotel-map {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.image-gallery,
.image-gallery-content,
.image-gallery-slide-wrapper,
.image-gallery-slides,
.image-gallery-swipe,
.image-gallery-slide,
.image-gallery-image {
  width: 100%;
  height: 100%;
}

.image-gallery-thumbnail {
  border: none !important;
  margin-bottom: 5px;

  div > img {
    border: none !important;
  }
}

.image-gallery-thumbnail.active {
  border: none !important;

  div > img {
    border: 4px solid var(--primary-600) !important;
    border-radius: 10px;
  }
}

.full-slider {
  .image-gallery-content {
    .image-gallery-slide-wrapper {
      width: 100% !important;
    }
  }
}

.tab-content {
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    width: 100% !important;
  }
}

.gm-style .gm-style-iw-c {
  padding-top: 5px !important;
}

.image-gallery-image img {
  background-color: black;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-gallery-left-nav::before,
.image-gallery-right-nav::before {
  font-weight: bold;
  font-size: 20px;
}

.image-gallery-left-nav:hover {
  color: var(--primary-600);
}

.image-gallery-fullscreen-button,
.image-gallery-right-nav,
.image-gallery-left-nav {
  z-index: 1 !important;
}

.slider-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw-t::after,
.gm-style-iw-a,
.gm-style-iw {
  cursor: pointer !important;
}
