.kmb-dropzone {
  .file-info {
    font-size: 12px;
    display: block;
    max-width: 300px;
    margin: 30px auto 0;

    p {
      margin-bottom: 0;
    }
  }

  .wrap {
    margin-top: 40px;
  }

  &.simple {
    .zone-area {
      cursor: pointer;
      border-color: black !important;
      background-color: transparent !important;
    }

    .wrap {
      max-width: 220px;
      position: relative;
      margin: 0 auto;
    }

    .vertical-center {
      padding: 0;
    }

    .text-area {
      position: relative;
    }

    .prompt-message {
      position: absolute;
      top: -8px;
      left: 0;

      span {
        font-size: 30px;
      }
    }

    .details {
      color: #404f54;
      font-family: Lato;
      font-size: 14px;
      padding-left: 40px;
      margin: 0;
      font-weight: bold;
    }
  }

  .selected-files {
    h5 {
      padding: 25px 0 10px;
      font-size: 18px;
      color: #404040;
      font-weight: 600;
    }

    p.file {
      position: relative;
      background-color: rgb(245 249 253);
      display: inline-block;
      padding: 10px;
      padding-right: 20px;
      margin-right: 10px;
      font-size: 12px;

      a.file-name {
        display: inline-block;
        padding-left: 20px;
      }

      span.file-name {
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-left: 20px;
        display: block;
      }

      i {
        position: absolute;
        font-size: 14px;
        padding-right: 5px;

        &.delete-file {
          position: absolute;
          right: -3px;
          top: -7px;
          font-size: 8px;
          padding: 3px;
          border: 1px solid #eee;
          border-radius: 50%;
          cursor: pointer;
          transition: 0.15s ease-in-out;

          &:hover {
            background-color: #1da4cf;
            color: white;
            border-color: transparent;
          }
        }
      }
    }
  }

  .file-names {
    margin: 15px 0 0;
    text-align: left;

    > span {
      font-size: 11px;
      display: block;
      margin-bottom: 2px;
      position: relative;
      padding: 4px;
      background-color: rgba(255 255 255 / 80%);

      &:last-child {
        margin-bottom: 0;
      }

      > span {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        font-size: 10px;
        padding-left: 10px;
      }
    }
  }

  &.disabled {
    .zone-area {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .limit-reached {
      margin-top: 10px;
      color: #ff5d00;
      font-size: 12px;
    }
  }

  > div {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center 110px;
  }

  .vertical-center {
    height: 100%;
    text-align: center;
    padding-top: 60px;
  }

  .prompt-message {
    color: var(--primary-600);
    font-size: 16px;
  }

  .details {
    color: #96a1b0;
    font-size: 12px;

    span {
      color: #1da4cf;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
