.user-notifications {
  > .notifications-wrapper {
    .icon-bell-regular {
      color: var(--gray-600);
    }

    position: relative;
    cursor: pointer;

    > i {
      font-size: 20px;
      color: white;
    }

    div.count {
      position: absolute;
      top: -2px;
      left: 10px;
      padding: 1px 5px;
      color: white;
      height: 16px;
      min-width: 16px;
      width: max-content;
      border-radius: 50%;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 2px;
    }
  }

  .notification {
    font-size: 14px;
    margin-bottom: 5px;

    > a {
      display: block;
      color: #868686;
      text-decoration: none !important;
      padding: 11px 20px;
      border-bottom: 1px solid #ddd;
    }

    > a:hover {
      background-color: #f3f3f3;
    }

    &.viewed {
      opacity: 0.6;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .panel {
    position: absolute;
    width: 280px;
    z-index: 2;
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(82 97 115 / 50%);
    text-align: right;
    //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-user-select: none;
    user-select: none;
    font-size: 14px;
    max-height: 260px;
    overflow: auto;
    top: 60px;
    border-radius: 4px;
    right: 20px;
  }
}
.mobile-notification-drawer-paper {
  &.MuiPaper-root,
  &.MuiDrawer-paper,
  &.MuiDrawer-paperAnchorRight,
  &.MuiPaper-elevation16 {
    min-width: 100%;
  }
}
.desktop-notification-drawer-paper {
  &.MuiPaper-root,
  &.MuiDrawer-paper,
  &.MuiDrawer-paperAnchorRight,
  &.MuiPaper-elevation16 {
    min-width: 380px;
  }
}

.MuiPaper-root,
.MuiDrawer-paper,
.MuiDrawer-paperAnchorRight,
.MuiPaper-elevation16 {
  &.desktop-notification {
    top: 80px;
    height: calc(100% - 80px);
  }

  .mobile-notification-header {
    width: 100%;
    height: 70px;
    box-shadow: 0 4px 12px rgba(0 0 0 / 11%);
    padding: 20px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--gray-600);
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;

    .mark-as-read {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .mobile-notification-wrapper {
    z-index: 1;

    .notification {
      width: 100%;
      border-bottom: 2px solid var(--gray-25);
      display: flex;
      flex-direction: row;
      padding: 20px;
      cursor: pointer;

      .viewed-indicator {
        padding-top: 8px;

        .viewed-dot {
          height: 8px;
          width: 8px;
          border-radius: 50%;
        }
      }

      .notification-description {
        padding-left: 12px;

        .notification-title {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--gray-600);
        }

        .creation-date {
          padding-top: 4px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: var(--gray-200);
        }
      }
    }

    &.empty-notifications {
      .empty-notifications-text {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: var(--gray-300);
      }
    }
  }
}
