.notifications {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 115;
  width: 80%;
  max-width: 400px;
  overflow: hidden;

  .notification-item {
    padding: 15px;
    border-radius: 2px;
    font-size: 14px;
    margin-bottom: 1px;
    animation: slidein 0.3s;
    transition: transform 0.5s ease-in-out;

    &.removing {
      transform: translateX(400px);
    }

    &::before {
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -moz-osx-font-smoothing: grayscale;
      padding-right: 5px;
    }

    &.alert-warning,
    &.alert-danger {
      &::before {
        content: '\f06a';
      }
    }

    &.alert-success {
      &::before {
        content: '\f058';
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@keyframes slidein {
  from {
    transform: translateX(40px);
  }

  to {
    transform: translateX(0);
  }
}
