.backdrop-share {
  z-index: 5 !important;
  backdrop-filter: blur(3px) grayscale(35%);

  .share-card {
    height: max-content;
    width: 520px;
    background-color: white;
    box-shadow: 0 20px 24px -4px rgba(16 24 40 / 10%),
      0 8px 8px -4px rgba(16 24 40 / 4%);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    margin: 0 16px;

    .top-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .share-title {
        color: var(--gray-900);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
      }

      .close-share-wrapper:hover {
        background-color: unset;
        color: var(--primary-600);
      }
    }

    .share-body {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      .shareable-media-wrapper {
        margin-top: 12px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 18px;

        button {
          width: 30px;
          height: 30px;
        }
      }

      .url-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 24px;

        .url-title {
          color: var(--gray-700);
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 6px;
          width: 100%;
        }

        .url-copy-container {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .url-field {
            height: 44px;
            width: 100%;
            flex: 1;

            div {
              height: 100%;

              input {
                color: var(--gray-900);
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                cursor: text;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              fieldset {
                &.MuiOutlinedInput-root.Mui-disabled,
                &.MuiOutlinedInput-notchedOutline {
                  border: 1px solid;
                  border-color: var(--gray300);
                }
              }
            }
          }

          .copy-icon-btn {
            width: 44px;
            height: 44px;
            margin-left: 4px;
            margin-right: 2px;

            &:hover {
              background-color: unset;
            }

            .copy-icon {
              color: var(--primary-600);
            }
          }
        }
      }
    }
  }
}
