$md-radio-checked-color: var(--primary-600);
$md-radio-border-color: #d5d9df;
$md-radio-size: 18px;
$md-radio-checked-size: 8px;
$md-radio-ripple-size: 10px;

.kmb-radio {
  label {
    margin-bottom: 0;
  }
  fieldset {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  input[type="radio"] {
    display: none;

    &:checked + label::before {
      border-color: $md-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }

    &:checked + label::after {
      transform: scale(1);
    }

    &:checked + label {
      color: $md-radio-checked-color;
    }
  }

  .radio-wrapper {
    label {
      display: inline-block;
      height: $md-radio-size;
      position: relative;
      padding: 0 ($md-radio-size + 10px);
      margin-bottom: 0;
      cursor: pointer;
      vertical-align: bottom;

      &::after,
      &::before {
        position: absolute;
        content: "";
        border-radius: 50%;
        transition: all 0.3s ease;
        transition-property: transform, border-color;
      }

      &::before {
        left: 0;
        top: 0;
        width: $md-radio-size;
        height: $md-radio-size;
        border: 2px solid $md-radio-border-color;
      }

      &::after {
        top: calc($md-radio-size / 2) - calc($md-radio-checked-size / 2);
        left: calc($md-radio-size / 2) - calc($md-radio-checked-size / 2);
        width: $md-radio-checked-size;
        height: $md-radio-checked-size;
        transform: scale(0);
        background: $md-radio-checked-color;
      }
    }
  }
}
