.component-account {
  padding: 40px 32px !important;

  .mobile-top-menu {
    display: none;
  }

  ul.top-menu {
    display: flex;
    padding: 0;
    list-style-type: none;
    border-bottom: 1px solid #ddd;
    margin-bottom: 32px;

    .submenu-item {
      display: flex;
      border-bottom: 2px solid transparent;
      padding-bottom: 6px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      &.active {
        border-bottom: 2px solid var(--primary-600);
        border-radius: 0;
      }
    }

    li {
      text-align: center;
      display: inline-block;
      position: relative;
      margin-right: 24px;

      &.active {
        &::after {
          content: '';
          height: 1px;
          bottom: -2px;
          width: 100%;
          position: absolute;
          display: block;
        }

        button.btn {
          color: var(--primary-600);
        }
      }

      button.btn {
        display: block;
        padding: 0 0 8px;
        box-shadow: none;
        background-color: transparent;
        outline: none;
        border: none;
        font-weight: 500;
        color: rgba(33 33 33 / 55%);
        font-size: 14px;
      }

      .cart-number {
        background-color: var(--error-500);
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        height: 22px;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        border-radius: 16px;
        min-width: 28px;
        margin-left: 8px;
      }
    }
  }

  .account-container {
    display: flex;
    color: #404f54;

    .account-info {
      flex: 2;
      background-color: white;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding: 40px;

      .upper-part {
        border-bottom: 1px solid gray;
        padding-bottom: 20px;
      }

      .btn {
        border-radius: 5px;
        background-color: var(--primary-600);
        color: white;
        margin-right: 10px;
      }

      .btn:focus {
        box-shadow: none;
      }

      .field-couple {
        display: flex;
        flex: 100%;
      }

      .reset {
        position: absolute;
        left: 22px;
        bottom: 10px;
        cursor: pointer;
      }

      .field-container {
        width: 100%;

        .field-name {
          font-size: 16px;
          font-weight: 600;
        }

        .field-value {
          max-width: 80%;
          padding-bottom: 15px;

          .kmb-radio {
            margin: 0;
          }

          .form-control {
            border-radius: 0 !important;
            background-color: transparent !important;
            font-size: 20px;
            padding-left: 0;
            border: none;
            border-bottom: 1px solid black;
          }

          .form-control:focus {
            box-shadow: none;
          }

          .upper-area {
            height: 40px;
          }

          .bottom-area {
            position: absolute !important;
          }
        }

        .disabled {
          .form-control {
            border: none;
          }
        }
      }
    }

    .payments-info {
      flex: 1;

      .mobile-table {
        display: none;
      }
    }
  }
}

.payments-tab {
  > ul.header-area {
    padding-left: 0;
    list-style-type: none;

    li {
      display: inline-block;
      border-bottom: 4px solid transparent;

      &.active {
        border-bottom: 4px solid var(--primary-600);

        button {
          font-weight: 500;
        }
      }

      button {
        font-weight: 400;
        border-radius: 0;
        color: #64656e;
        background-color: transparent;
        box-shadow: none;
        font-size: 14px;
        padding: 10px 30px;
      }
    }
  }
}

@media (max-width: 992px) {
  .mobile-top-menu {
    display: flex !important;
    margin: 16px;
    width: 100%;
    height: 44px;

    .MuiInputBase-root.MuiOutlinedInput-root {
      width: calc(100% - 32px);
    }
  }

  .desktop-top-menu {
    display: none !important;
  }

  .component-account {
    padding: 0 !important;
  }
}

@media (max-width: 991px) {
  .desktop-only-visible {
    display: none !important;
  }

  .component-account {
    .account-container {
      padding: 25px 20px !important;
      flex-direction: column;

      .account-info {
        flex-direction: column;
        flex: 1;
        margin-bottom: 25px;

        .field-couple {
          .field-container {
            .field-name {
              font-size: 13px;
            }

            .field-value {
              .form-control {
                font-size: 17px;
              }
            }
          }
        }
      }

      .payments-info {
        padding-left: 0;

        .table-container {
          display: none;
        }

        .mobile-table {
          display: inline-block;
          width: 100%;

          .card {
            padding: 20px;
            margin-bottom: 20px;

            .button {
              height: 45px;
              border-radius: 4px;
              background-color: var(--primary-600);
              font-size: 17px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 25px;
              text-align: center;
              color: white;
              font-family: Lato;
            }

            .card-name {
              height: 25px;
              color: #404f54;
              font-family: Lato;
              font-size: 18px;
              letter-spacing: 0;
              font-weight: bold;
              line-height: 25px;
              padding-bottom: 40px;
            }

            .card-info-container {
              display: flex;
              flex-wrap: wrap;

              .name {
                display: none;
              }

              .col {
                padding-left: 0;
              }

              .receipt {
                text-decoration: none;
                cursor: pointer;
              }

              .cosmetic {
                display: none;
              }

              .col-name {
                color: #404f54;
                font-family: Lato;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 17px;
                padding: 10px 10px 10px 0;
              }

              .col-entry {
                height: auto;
                color: #919da4;
                font-family: Lato;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 21px;
              }

              .icon-agenda-added1,
              .icon-agenda-added,
              .icon-no-video,
              .icon-video {
                font-size: 30px;
              }

              .icon-attach-no,
              .icon-attach {
                font-size: 37px;
              }

              .icon-agenda-added1,
              .icon-agenda-added {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .top-menu {
      overflow: auto;

      li {
        margin-right: 38px !important;

        .cart-number {
          top: 3px !important;
          right: -20px !important;
        }
      }
    }
  }
}
