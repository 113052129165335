.mobile-table {
  display: none;
}

.single-abstract {
  padding: 0 32px;
  // override the default min width of accordion
  .MuiAccordion-root {
    box-shadow: none;
    border: 1px solid var(--warning-800);
    border-radius: 0.25rem !important;
    margin-bottom: 20px;
  }
  .MuiAccordion-rounded:first-child {
    min-width: unset;
  }
  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .status {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid;
    border-radius: 0.25rem;

    &.status-draft,
    &.status-submitted,
    &.status-awaiting_resubmission,
    &.status-under_review {
      color: var(--warning-900);
      background-color: var(--warning-100);
      border-color: var(--warning-400);
    }

    &.status-approved {
      color: var(--success-900);
      background-color: var(--success-100);
      border-color: var(--success-400);
    }

    &.status-rejected {
      color: var(--error-900);
      background-color: var(--error-100);
      border-color: var(--error-400);
    }
  }

  .form-control.css-3iigni-container {
    padding: 0;
  }

  .form-group {
    background-color: white;
    padding: 16px;
    border-radius: 0.25rem;
  }

  .extra {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    .abstracts-meta {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex: 1;
      min-width: 330px;
    }
  }
  .author-abstract-main {
    color: var(--warning-800);
    background-color: var(--warning-100);
    border-color: var(--warning-400);
    padding: 20px;
    border-radius: 0.25rem;
  }
  .author-abstract {
    display: flex;
    flex-direction: column;
    border-left: 5px solid var(--primary-600);
    background: #f6f8fc;
    padding: 10px;
    margin-bottom: 20px;
  }
  .author-inner {
    position: relative;
    padding: 10px 0px;

    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    > div {
      flex: 1;
      flex-basis: 230px;
    }
    .icon-close-wrapper {
      max-width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }

  .MuiAccordionSummary-expandIcon svg {
    transition: transform 0.3s ease;
  }
  .expanded {
    transform: rotate(180deg);
  }
}
.form-control {
  border: 1px solid var(--gray-100);
  &::placeholder {
    color: var(--gray-100);
  }
}

.institute-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .institute {
    display: flex;
    align-items: center;
  }
  .btn.close {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.single-abstract-card-wrapper,
.single-abstract {
  .button-holder {
    :first-child {
      margin-right: 16px;
    }
  }

  .navigate-back-abstracts {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-500);
    margin-bottom: 28px;

    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }

    &:hover {
      color: var(--primary-600);
      text-decoration: underline;
    }
  }
}

.loader-container {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.abstract-paywall {
  width: 100%;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-700);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.abstract-subheader {
  margin-bottom: 16px;
  color: var(--gray-400);
}

.abstracts-wrapper {
  width: 100%;
  .kmb-loader {
    padding-top: 0 !important;
  }
  .no-abstracts-found {
    margin-top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray-700);
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
  }

  .abstracts-header {
    width: 100%;
    height: 47px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    row-gap: 12px;
    position: static;
    border-bottom: 1px solid var(--gray-100);
    flex: 1;
  }

  .abstract-card-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: center;
    justify-content: center;
  }

  .abstracts-not-published {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: var(--gray-300);
  }
}
.custom-tabs {
  &.MuiTabs-root {
    .MuiTabs-scroller,
    .MuiTabs-fixed {
      > span {
        background-color: var(--primary-600);
      }

      .MuiTabs-flexContainer {
        column-gap: 24px;

        button {
          min-width: max-content;
          padding: 6px 0;

          .MuiTab-wrapper {
            color: var(--gray-500);
            text-transform: none;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }

          &.Mui-selected {
            border-bottom: 2px solid var(--primary-600);

            .MuiTab-wrapper {
              color: var(--primary-600);
            }
          }
        }

        .MuiButtonBase-root,
        .MuiTab-root,
        .MuiTab-textColorInherit,
        .Mui-selected {
          button {
            min-width: max-content;
          }
        }
      }
    }
  }
}
.abstracts-wrapper,
.single-abstract-card-wrapper {
  padding: 0 32px;
}

@media (max-width: 834px) {
  .single-abstract,
  .abstracts-wrapper,
  .single-abstract-card-wrapper {
    padding: 0 16px;
  }

  .single-abstract-card-wrapper {
    .navigate-back-abstracts {
      justify-content: center;
    }
  }
}

@media (max-width: 1400px) {
  .abstracts-wrapper {
    .toolbar-container {
      .viewing-tools-wrapper {
        column-gap: 32px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .abstracts-wrapper {
    .toolbar-container {
      row-gap: 16px;
      flex-flow: column wrap;

      .viewing-tools-wrapper {
        width: 100%;
        overflow: initial;
        flex-wrap: wrap;
        height: max-content;
        row-gap: 16px;

        .MuiInputBase-root,
        .MuiOutlinedInput-root,
        .search-bar {
          width: 100%;
          min-width: 100%;
          height: 44px;
        }

        .MuiInputBase-root,
        .MuiOutlinedInput-root {
          .MuiSelect-root,
          .MuiSelect-select,
          .MuiSelect-selectMenu,
          .MuiSelect-outlined,
          .MuiInputBase-input,
          .MuiOutlinedInput-input {
            padding: 12.5px 32px 12.5px 14px;
            margin: 6px 0;

            .selected-checkmark {
              display: none !important;
            }
          }
        }
      }

      .paginator-container {
        width: 100%;

        .paginator-wrapper {
          width: 100%;
          justify-content: space-between;

          .page-indicator {
            margin-right: 0;
          }

          .mobile-back {
            display: unset;
          }

          .page-navigation {
            .mobile-next {
              display: unset;
            }

            .desktop-back,
            .desktop-next {
              display: none;
            }
          }
        }
      }
    }
  }
}

.MuiList-root.MuiMenu-list.MuiList-padding {
  padding: 0 !important;
}

.MuiList-padding {
  padding: 0 !important;
}

.MuiListItem-button:hover {
  background-color: var(--primary-25) !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: white !important;
}

.MuiButtonBase-root,
.MuiListItem-root,
.MuiMenuItem-root,
.MuiMenuItem-gutters,
.MuiListItem-gutters,
.MuiListItem-button {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  height: 44px;
}

.selected-checkmark {
  color: var(--primary-600);
  display: none;
  height: 20px !important;
  width: 20px !important;

  &.visible {
    display: flex;
  }
}

@media (max-width: 991px) {
  .component-abstractmanagement {
    .mobile-table {
      display: inline-block;
      width: 100%;

      .card {
        padding: 20px;
        margin-bottom: 20px;

        .button {
          margin-top: 20px;
          height: 36px;
          border-radius: 4px;
          background-color: var(--primary-600);
          font-size: 17px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 25px;
          text-align: center;
          color: white;
          font-family: Lato;
          border: none;
        }

        .card-name {
          height: 25px;
          color: var(--gray-700);
          font-family: Lato;
          font-size: 18px;
          letter-spacing: 0;
          font-weight: bold;
          line-height: 25px;
          padding-bottom: 40px;
        }

        .card-info-container {
          display: flex;
          flex-wrap: wrap;

          .name {
            display: none;
          }

          .col {
            padding-left: 0;
          }

          .cosmetic {
            display: none;
          }

          .col-name {
            color: var(--gray-700);
            font-family: Lato;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 17px;
            padding: 10px 10px 10px 0;
          }

          .col-entry {
            height: auto;
            color: var(--gray-300);
            font-family: Lato;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 21px;
          }

          .icon-agenda-added1,
          .icon-agenda-added,
          .icon-no-video,
          .icon-video {
            font-size: 30px;
          }

          .icon-attach-no,
          .icon-attach {
            font-size: 37px;
          }

          .icon-agenda-added1,
          .icon-agenda-added {
            cursor: pointer;
          }
        }
      }
    }

    .table-container {
      .kmb-date,
      .kmb-authors,
      .kmb-topics,
      .kmb-type {
        display: none;
      }
    }
  }
}
