.pdf-document-wrapper {
  overflow-y: scroll;
  flex: 1 1;
  width: 618px;

  .react-pdf__Document {
    height: 100%;
    width: 100%;
    max-height: 1px;

    /* Weird number right? Empirically, this element needs the "max-height" property set to any "px" value (not % or vh etc) to calculate the appropriate max height by itself. !!! UNSET or INHERIT dont work !!! */

    .react-pdf__Page {
      display: block;
      justify-content: center;
      align-items: center;
      margin-bottom: 2px;
      box-shadow: 0 3px 14px 0 var(--gray-25), 0 4px 6px -2px var(--gray-25);
      height: 792px;
      min-height: 792px;
      max-height: 792px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .pdf-load-error {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--gray-500);
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;

    .error-icon {
      color: red;
    }
  }
}

@media (max-width: 960px) {
  .pdf-document-wrapper {
    width: 100%;
  }
}
