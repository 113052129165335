.text-404 {
  display: flex;
  flex-wrap: wrap;
  width: 98vw;
  height: 90vh;
  align-content: center;
  text-align: center;
}

.subtext-404 {
  flex-basis: 100%;
  position: relative;
  z-index: 2;
  color: white;
  font-size: 20px;
  padding-bottom: 5px;
}

.background-404 {
  background-color: black;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  opacity: 0.85;
}

.img-404 {
  position: absolute;
  z-index: 3;
  padding: 20px;
}
