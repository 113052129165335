.select-advanced {
  position: relative;

  .upper-area {
    border: 1px solid #dddfff;
    border-radius: 3px;
    background-color: white;
    padding: 10px 15px;
    outline-style: none;
    cursor: pointer;
    position: relative;
    font-size: 16px;
    //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon-arrow-down,
    .icon-arrow-up {
      font-size: 30px;
    }

    .selected-content {
      overflow: hidden;
    }

    > span.arrow {
      position: absolute;
      right: 8px;
      top: 50%;
      margin-top: -2.5px;
      font-size: 17px;
      color: #d5d9df;
    }
  }

  &.multiselect {
    .bottom-area {
      padding: 0;
    }

    .options-wrapper {
      li {
        color: #383b7f;
        cursor: pointer;

        &.selected {
          background-color: #e8e8e9;
        }
      }
    }
  }

  .options-wrapper {
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow: auto;
    font-size: 14px;
    box-shadow: 0 2px 5px 0 rgba(82 97 115 / 50%);

    li {
      display: block;
      color: #435670;
      padding: 11px 20px;

      &:hover {
        background-color: #f3f3f3;
      }
    }
  }

  .bottom-area {
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 0 0 0 rgba(82 97 115 / 50%);
    padding: 15px;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1;

    input[type='text'] {
      height: 30px;
    }

    .checkboxes-wrapper,
    .range-wrapper {
      > span {
        font-size: 11px;
        color: #a7b7ce;
        cursor: pointer;
        margin-bottom: 10px;
        display: inline-block;
        //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-user-select: none;
        user-select: none;
      }
    }

    .range-wrapper {
      padding-left: 15px;

      > span {
        display: block;
        text-align: right;
        margin-bottom: 0;
      }

      &::before {
        content: '';
        width: 24px;
        display: block;
        height: 50px;
        position: absolute;
        left: 15px;
        top: 28px;
        border: 1px solid #dddfff;
        border-right: none;
        border-radius: 6px;
      }

      > .rdt {
        margin-bottom: 15px;

        &:last-child {
          margin: 0;
        }

        &::after {
          background-color: #f6f6f6;
          color: #1da4cf;
          content: '\39';
          font-family: medlive !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -moz-osx-font-smoothing: grayscale;
          position: absolute;
          top: 1px;
          right: 1px;
          width: 28px;
          height: calc(100% - 2px);
          padding: 6px;
          border-left: 1px solid #dddfff;
        }
      }
    }

    .checkbox-wrapper {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    label {
      //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-user-select: none;
      user-select: none;
      margin-bottom: 0;
    }
  }
}
