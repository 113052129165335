.rpp {
  text-align: left;
  color: rgba(0 0 0 / 54%);
  font-size: 13px;
  display: flex;
  align-items: flex-start;

  > div {
    display: inline-block;
    padding-right: 12px;

    &:last-child {
      padding-right: 0;
    }
  }
}

.pagination {
  display: block;
  margin: 10px 0;
  text-align: right;

  & > div {
    display: flex;
    align-items: center;

    & > div {
      width: 50%;
      min-width: 50%;
    }

    .right-area {
      text-align: right;

      & > div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .left-area {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
    }
  }

  .page-controls {
    color: #919da4;
    font-size: 14px;
    font-weight: 400;
    padding-right: 21px;
  }

  .pagination-lectical {
    color: #919da4;
    font-size: 14px;
    font-weight: 400;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  button {
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    color: #6e7c90;
    font-weight: 700;
    margin: 0 2px;
    background-color: transparent;
    border: none;
    outline: none;
  }

  .upper-area {
    border: none;
    color: #1da4cf;
    font-size: 13px;
    box-shadow: 0 2px 24px 0 rgba(0 0 0 / 10%);
    border-radius: 4px;
    padding: 0;
    height: 35px;
    width: 48px;

    .icon-arrow-down,
    .icon-arrow-up {
      font-size: 23px;
    }

    & .selected-content {
      font-size: 14px;
      display: block;
      width: 100%;
      text-align: right;
    }
  }

  .bottom-area {
    margin-top: 5px;
    width: 48px;
  }

  .select-advanced {
    width: 43px;
    display: inline-block;

    .options-wrapper {
      max-height: 100px;
      padding-top: 4px;
      padding-bottom: 4px;
      box-shadow: 0 2px 24px 0 rgba(0 0 0 / 10%);

      li {
        padding: 0 !important;
        padding-top: 3px !important;
        text-align: center;
        font-size: 13px;
      }
    }
  }

  .arrow {
    top: 48% !important;
  }

  li {
    display: inline-block;

    &.current-page {
      button {
        background-color: #949494;
        color: white;
        border-radius: 4px;
      }
    }

    &.previous-page,
    &.next-page {
      .icon-arrow-right,
      .icon-arrow-left {
        color: #3596ee;
        padding: 3px;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 4px;
        background-color: white;
        box-shadow: 0 2px 24px 0 rgba(0 0 0 / 10%);
        padding-left: 0 !important;
        padding-right: 0 !important;
        height: 35px;
        width: 35px;
        font-size: 48px;

        &::after {
          display: inline-block;
          font-size: inherit;
          text-rendering: auto;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .pagination {
    .row {
      flex-wrap: nowrap;
    }
  }
}
