.component-speakers {
  .speaker-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;

    thead {
      display: none;
    }

    .speaker-item {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      > div:first-child {
        gap: 32px;
        display: flex;
        align-items: center;
        width: 350px;
      }

      > div:last-child {
        flex: 1;
        gap: 32px;
        display: flex;
        align-items: center;
      }
    }

    .speaker-card {
      width: 30%;
      height: 450px;
      text-overflow: ellipsis;
      box-shadow: rgba(0 0 0 / 20%) 0 1px 2px 0;
      background-color: white;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      word-break: break-word;

      .short-bio {
        padding: 10px;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1.5;
      }

      img {
        width: 100%;
        object-fit: cover;
        height: 285px;
      }

      .view-more {
        position: absolute;
        right: 10px;
        bottom: 5px;
      }
    }
  }

  .mobile-table {
    display: none;
  }

  padding: 40px 32px !important;

  tbody {
    tr {
      cursor: pointer;
    }
  }

  td.kmb-resume {
    max-width: 300px;
    font-size: 14px;
    color: #a6a7ac;
  }

  td.kmb-name {
    color: #777e82;
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;

    p {
      color: #919da4;
      font-family: Lato;
      font-size: 13px;
      letter-spacing: 0;
      font-weight: 400;
    }
  }

  td.kmb-cosmetic {
    width: 70px;

    i {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      position: relative;
      background-color: #eee;
      display: block;
      padding: 3px;

      &::before {
        position: absolute;
        top: 17px;
        left: 23px;
        font-size: 20px;
      }
    }
  }

  .profile-mobile {
    display: none;
  }

  .profile {
    svg {
      width: 100%;
      height: 100%;
      background: var(--gray-50);
      padding: 17px;
      border-radius: 100%;
    }

    .person-thumbnail {
      height: 260px;
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      img {
        position: relative;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        padding: 3px;
        border-radius: 100%;
      }
    }

    .back-cut {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: white;
      top: 0;
      left: 0;
      z-index: -1;
    }

    h2 {
      color: #404f54;
      font-family: Lato;
      font-size: 35px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 41px;
    }

    .resume {
      color: #434c4f;
      font-family: Lato;
      font-size: 16px;
    }

    .info {
      color: #505c60;
      font-family: Lato;
      font-size: 15px;
    }

    .upper-area {
      display: flex;
      padding: 45px;
      align-items: center;
      position: relative;
      z-index: 3;

      .left-side {
        flex: 0 0 300px;
        text-align: center;
        padding: 0 45px 0 0;

        i {
          color: #64656e;
          font-size: 100px;
        }
      }
    }

    .bottom-area {
      padding: 15px 45px;

      a {
        color: white;
        display: inline-block;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}

.table-container {
  .cosmetic-container {
    padding: 3px;
  }

  .person-thumbnail {
    width: 70px;
    display: block;

    svg {
      width: 100%;
      height: 100%;
      background: var(--gray-50);
      padding: 17px;
      border-radius: 100%;
    }

    img {
      display: block;
      border-radius: 50%;
      max-width: 100%;
    }
  }
}

@media (max-width: 991px) {
  .profile {
    display: none;
  }

  .component-speakers,
  .component-videos {
    padding-left: 20px !important;
    padding-right: 20px !important;

    .speeches {
      .session-list {
        display: none;
      }
    }

    .profile-mobile {
      display: flex;
      width: 100%;
      min-height: 250px;
      background-color: white;
      padding: 24px;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .speaker-name {
        color: #404040;
        font-family: Lato;
        font-size: 17px;
        font-weight: bold;
        padding-top: 30px;
      }

      .resume {
        color: #919da4;
        font-family: Lato;
        font-size: 15px;
        letter-spacing: 0;
        min-height: 150px;
        margin-top: 30px;
        padding-bottom: 20px;
      }

      .info {
        color: #434c4f;
        font-family: Lato;
        font-size: 15px;
      }

      button {
        border-radius: 4px;
        background-color: var(--primary-600);
        flex-basis: 100%;
        height: 45px;
        color: white;
        font-family: Lato;
        font-size: 16px;
        font-weight: bold;
      }

      .person-thumbnail {
        height: 64px;
        border-radius: 100%;
        display: inline-block;
        padding-right: 15px;

        i {
          font-size: 70px;
          color: #919da4;
          width: 84px;
          text-align: center;
        }

        img {
          max-width: 100%;
          position: relative;
          object-fit: cover;
          height: 84px;
          width: 84px;
          padding: 3px;
          border-radius: 100%;
        }
      }
    }

    .mobile-table {
      display: inline-block;
      width: 100%;

      .card {
        padding: 20px;
        margin-bottom: 20px;
        min-height: 250px;

        .button {
          height: 45px;
          border-radius: 4px;
          background-color: var(--primary-600);
          font-size: 17px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 25px;
          text-align: center;
          color: white;
          font-family: Lato;
        }

        .card-name {
          height: 25px;
          color: #404f54;
          font-family: Lato;
          font-size: 18px;
          letter-spacing: 0;
          font-weight: bold;
          line-height: 25px;
          padding-bottom: 40px;
        }

        .card-info-container {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 70px;

          .sessionFiles {
            text-align: center;
          }

          .icon-speaker {
            font-size: 30px;
          }

          .col {
            padding-left: 0;
          }

          .col-name {
            color: #404f54;
            font-family: Lato;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 17px;
            padding: 10px 10px 10px 0;
            font-weight: 500;
          }

          .col-entry {
            color: #919da4;
            font-family: Lato;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 21px;
          }
        }
      }
    }
  }

  .speaker-table {
    display: none;
  }

  .component-speakers .profile {
    .upper-area {
      display: block;

      .left-side {
        padding: 0;
      }

      .right-side {
        margin-top: 30px;
      }
    }
  }
}
