.cost-sum-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;

  .cost-sum {
    text-align: right;
    color: var(--gray-700);
    display: flex;
    justify-content: flex-end;

    .cost-wrapper {
      display: flex;
    }

    .cost-line-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .price-header {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      height: 24px;
      justify-content: flex-end;
      align-items: center;
      display: flex;
      margin-bottom: 16px;
      color: var(--gray-500);
    }

    .price-header.total {
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      color: var(--gray-700);
    }

    .price-display {
      margin-left: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      height: 24px;
      width: 95px;
      margin-bottom: 16px;
      color: var(--gray-700);
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .total.price-header {
      margin-bottom: 0;
    }

    .price-display.total {
      font-size: 20px;
      line-height: 30px;
    }

    .tax-note {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray-500);
    }
  }
}

.additional-products-text {
  width: 100%;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: var(--gray-500);
  padding-bottom: 20px;
}

.id-wrapper {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--gray-700);
}

.total-price-wrapper {
  min-width: 84px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
  color: var(--gray-700);
}

.product-info-wrapper {
  .name-wrapper {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--gray-700);
    margin-bottom: 8px;
  }

  .description-wrapper {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-500);
    overflow: hidden;
    margin-bottom: 10px;
  }

  .payments-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .payment-icon {
      margin-right: 4px;
    }
  }

  .product-error {
    padding-top: 4px;
    color: var(--error-600);
  }
}

.price-wrapper {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--gray-700);
}

.quantity-number {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--gray-700);
  top: 2px;
  width: 30px;
  text-align: center;
  position: relative;
}

.quantity-icon.disabled,
.quantity-number.disabled {
  color: var(--gray-100);
}

.not-available-message {
  min-width: 95px;
  max-width: 200px;
  color: var(--gray-300);

  .click-here {
    text-transform: capitalize;
    text-decoration: underline;

    :hover {
      color: var(--gray-800);
    }
  }
}

.round-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  color: white;
  background-color: #007bff;
  text-transform: none;
  font-weight: normal;
  border: none;
  border-radius: 5px;
  display: flex;
}
