.backdrop {
  width: 100%;
  max-width: 100% !important;
  height: 100%;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  background-color: rgba(0 0 0 / 50%);
  backdrop-filter: blur(3px) grayscale(35%);
  //TODO: remove webkit prefix when official support comes for firefox
  -webkit-backdrop-filter: blur(3px) grayscale(35%);
}
