.book-room {
  padding: 18px 16px;
  background-color: white;
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 30px;
  min-height: 300px;
  border-radius: 10px;

  .book-room-slider {
    max-width: 40%;
    width: 334px;
    min-height: 329px;
    max-height: 329px;

    .image-placeholder {
      background-color: var(--gray-25);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      .icon-presentation {
        color: var(--gray-200);
        width: 100px;
        height: 100px;
      }
    }

    .slider-container {
      align-items: start;
    }
  }

  .book-room-info-wrapper {
    width: 60%;
    display: flex;
    flex-flow: column wrap;
    position: relative;
    flex: 1;

    .room-name {
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 24px;
      color: var(--gray-700);
    }

    .show-availability {
      padding-top: 6px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      width: 100%;
      height: 22px;
      color: var(--gray-300);

      &.invisible {
        visibility: hidden;
      }
    }

    .room-info {
      padding-top: 24px;
      display: flex;
      flex-wrap: wrap;

      .room-info-field {
        width: 50%;
        min-width: 180px;
        margin-bottom: 16px;

        .room-info-title {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          color: var(--gray-300);
        }

        .room-info-item {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          color: var(--gray-700);
        }

        .description {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 240px;
          width: 50%;
        }
      }
    }

    .not-available {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      position: relative;
      bottom: 0;
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      height: 40px;
      width: 100%;
      margin-top: 12px;
      color: var(--gray-200);
      border: 1px solid var(--gray-100);
      background-color: var(--gray-50);
    }

    .book-room-notes {
      width: 100%;

      .notes-title {
        margin-bottom: 6px;
      }

      .notes-text-area {
        width: 100%;
        height: 54px;
        resize: none;
        border: 1px solid var(--gray-25);
        border-radius: 10px;

        &:focus-visible {
          outline: 1px solid var(--gray-200);
        }
      }
    }

    .reservation-not-paid {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 8px;
      margin-top: 20px;

      .go-to-payments-button {
        background-color: var(--primary-600);
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        height: 40px;
        width: calc(50% - 4px);
      }

      .cancel-reservation-button {
        width: 100%;
        width: calc(50% - 4px);
        background-color: white;
        border: 1px solid var(--primary-600);
        color: var(--primary-600);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
    }

    .reservation-paid {
      color: var(--gray-700);
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      width: 100%;
      text-align: center;
      margin-top: 16px;
      margin-bottom: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .create-reservation {
      width: 100%;

      .book-now-button {
        background-color: var(--primary-600);
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        height: 45px;
        width: 100%;
        margin-top: 12px;
      }

      .not-available-button {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        height: 40px;
        width: 100%;
        margin-top: 12px;
        color: var(--gray-300);
        border: 1px solid var(--gray-100);
        background-color: var(--gray-50);
      }
    }
  }
}

@media (max-width: 1200px) {
  .book-room {
    flex-direction: column;
    border-radius: 16px;
    padding: 20px;
    background-color: var(--gray-25);

    .book-room-slider {
      width: 100%;
      max-width: 100%;
      min-height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 16px;
    }

    .book-room-info-wrapper {
      width: 100%;
      max-width: 100%;

      .not-available {
        position: initial;
      }
    }
  }
}
