.hotel-filters {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 350px;
  background-color: var(--gray-25);
  border-radius: 10px;
  padding: 20px 25px;
  height: max-content;
  position: relative;
  z-index: 2;
  .reset-button {
    cursor: pointer;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 8px;

    &:hover {
      color: var(--primary-600);
      text-decoration: underline;
      text-decoration-color: var(--primary-600);
    }

    .reset-button-icon {
      width: 22px;
      color: var(--primary-600);
    }
  }
  .MuiIconButton-root:hover {
    background-color: none;
  }

  &.inner {
    justify-content: space-between;
  }

  &.sticky {
    top: 140px;
    position: sticky;
  }

  .tools-wrapper {
    row-gap: 20px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    column-gap: 16px;

    .date-selector {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .max-guests-wrapper,
    .hotel-rank-wrapper {
      width: 100%;

      .max-guests-title,
      .hotel-rank-wrapper {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
      }

      .max-guests-picker,
      .hotel-rank-picker {
        font-style: normal;
        font-weight: normal;
        font-size: 16px !important;
        line-height: 25px;
        height: 47px;
        width: 100%;
        background-color: white;
        border-radius: 6px;
        color: var(--gray-700);

        div {
          height: 45px;

          .filter-stars {
            height: 20px;
            font-weight: 600;
            display: table-cell;
          }
          .upper-area {
            border-radius: 6px;
            border: none;
          }

          .bottom-area > div > ul {
            background-color: white;
          }
        }
      }
    }
  }

  .check-availability {
    background-color: var(--primary-600);
    color: white;
    width: 100%;
    margin-top: 6px;
    height: 45px;
  }
}

.DateRangePicker.DateRangePicker_1 {
  width: 100%;
}

.DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2 {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 6px;
  border: none;
}

.DateInput_input.DateInput_input_1 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
}

@media (max-width: 1200px) {
  .hotel-filters {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    column-gap: 20px;
    position: relative;
    top: 0;
    margin-bottom: 16px;

    &.inner {
      height: max-content;
    }

    &.sticky {
      position: relative;
      top: initial;
    }

    .hotel-filter-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(50% - 20px);
      min-width: 310px;
      flex: 1;
    }
  }
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: var(--primary-600) !important;
  border-color: var(--primary-600) !important;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover,
.CalendarDay__selected_span,
.CalendarDay__selected_span_3 {
  background-color: var(--primary-300) !important;
  border-color: var(--primary-50) !important;
  color: white !important;
}

.DateInput,
.DateInput_1 {
  border-radius: 6px !important;

  input {
    border-radius: 6px !important;
  }
}

@media (max-width: 450px) {
  .hotel-filters {
    .hotel-filter-wrapper {
      width: 100%;
      min-width: 100%;
      flex: 1;
    }
  }
}
