@import "partials/loader";
@import "partials/splash";
@import "partials/buttons";
@import "partials/select";
@import "partials/sidebar";
@import "partials/kmb-loader";
@import "partials/menu";
@import "partials/main";
@import "partials/pagination";
@import "partials/breadcrumbs";
@import "partials/table";
@import "partials/sessions";
@import "partials/animations";
@import "partials/agenda";
@import "partials/speakers";
@import "partials/notifications";
@import "partials/account";
@import "partials/hotels";
@import "partials/tabs";
@import "partials/datetime";
@import "partials/abstracts";
@import "partials/radio";
@import "partials/dropzone";
@import "partials/user-notifications";
@import "partials/mobile";
@import "partials/payments";
@import "partials/modal";
@import "partials/videos";
@import "partials/chat";
@import "partials/registration";
@import "partials/products";
@import "partials/exhibition";
@import "partials/backdrop";
@import "partials/stepper-connector";
@import "partials/stepper-icon";
@import "partials/cancel-registration-modal";
@import "partials/app-topbar";
@import "partials/app-mobile-topbar";
@import "partials/user-menu";
@import "partials/user-menu-mobile";
@import "partials/hotel-filters";
@import "partials/google-map";
@import "partials/hotel-card";
@import "partials/hotels-inner";
@import "partials/book-room";
@import "partials/warning-bar";
@import "partials/404";
@import "partials/abstract-card";
@import "partials/share-component";
@import "partials/message";
@import "partials/file-viewer";
@import "partials/abstract-info";
@import "partials/abstract-header";
@import "partials/pdf-viewer";
@import "partials/my_account";
@import "partials/recaptcha";
@import "partials/toolbar";
@import "partials/logoutter";
@import "partials/products-page";
@import "partials/confirmationModal";

@font-face {
  font-family: icomoon;
  src: url("~/public/fonts/icomoon.eot?iymvxs");
  src: url("~/public/fonts/icomoon.eot?iymvxs#iefix")
      format("embedded-opentype"),
    url("~/public/fonts/icomoon.ttf?iymvxs") format("truetype"),
    url("~/public/fonts/icomoon.woff?iymvxs") format("woff"),
    url("~/public/fonts/icomoon.svg?iymvxs#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icomoon !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
}

.icon-fill-1::before {
  content: "\e930";
  color: #fcb138;
}

.icon-location::before {
  content: "\e931";
}

.icon-star-full::before {
  content: "\e9d9";
}

.icon-exit::before {
  content: "\ea15";
  padding-left: 3px;
}

.icon-bubble::before {
  content: "\e96b";
}

.icon-signout1::before {
  content: "\e92d";
  color: #979797;
}

.icon-cart::before {
  content: "\e93a";
}

.icon-bell-regular::before {
  content: "\e92b";
}

.icon-bell-slash-regular::before {
  content: "\e92c";
}

.icon-download2::before {
  content: "\e9c5";
}

.icon-user1::before {
  content: "\e971";
}

.icon-eye1::before {
  content: "\e9ce";
}

.icon-eye-blocked1::before {
  content: "\e9d1";
}

.icon-compass::before {
  content: "\e949";
}

.icon-user::before {
  content: "\e972";
}

.icon-bell::before {
  content: "\e952";
}

.icon-eye::before {
  content: "\e9cf";
}

.icon-eye-blocked::before {
  content: "\e9d2";
}

.icon-send .path1::before {
  content: "\e929";
  color: rgb(0 0 0);
}

.icon-send::before {
  content: "\e92a";
}

.icon-agenda-added1::before {
  content: "\e902";
}

.icon-error-2::before {
  content: "\e903";
}

.icon-check::before {
  content: "\e904";
}

.icon-warning-2::before {
  content: "\e905";
  color: #404f54;
}

.icon-agenda-blue ::before {
  content: "\e923";
  color: rgb(64 64 64);
}

.icon-play-btn .path1::before {
  content: "\e927";
  color: rgb(58 58 58);
  opacity: 0.6514;
}

.icon-play-btn .path2::before {
  content: "\e928";
  margin-left: -1em;
  color: white;
}

.icon-add::before {
  content: "\e900";
}

.icon-agenda-added::before {
  content: "\e901";
}

.icon-arrow-down::before {
  content: "\e906";
}

.icon-arrow-left::before {
  content: "\e907";
}

.icon-arrow-right::before {
  content: "\e908";
}

.icon-arrow-up::before {
  content: "\e909";
}

.icon-attach-no::before {
  content: "\e90a";
}

.icon-attach::before {
  content: "\e90b";
}

.icon-caret-down::before {
  content: "\e90c";
}

.icon-caret-left::before {
  content: "\e90d";
}

.icon-caret-right::before {
  content: "\e90e";
}

.icon-caret-up::before {
  content: "\e90f";
}

.icon-close::before {
  content: "\e910";
}

.icon-dollar::before {
  content: "\e911";
  color: #566270;
}

.icon-edit::before {
  content: "\e912";
}

.icon-filter::before {
  content: "\e914";
  color: #566270;
}

.icon-guest::before {
  content: "\e915";
}

.icon-menu::before {
  content: "\e916";
}

.icon-no-video::before {
  content: "\e917";
  color: #979797;
}

.icon-pdf::before {
  content: "\e918";
}

.icon-presentation-copy::before {
  content: "\e919";
  color: #fff;
}

.icon-presentation::before {
  content: "\e91a";
}

.icon-remove::before {
  content: "\e91b";
  color: #566270;
}

.icon-search::before {
  content: "\e91c";
}

.icon-seasson::before {
  content: "\e91d";
}

.icon-signout::before {
  content: "\e91e";
  color: #979797;
}

.icon-speaker::before {
  content: "\e91f";
}

.icon-upload-file::before {
  content: "\e920";
}

.icon-upload::before {
  content: "\e921";
}

.icon-video::before {
  content: "\e922";
}

* {
  font-family: Lato, sans-serif !important;
}

body {
  overflow-x: hidden;
  font-family: Lato;
}

html,
body,
#root,
.app-wrapper {
  height: 100%;
}

#root {
  background-color: white;
}

.icon-attach-no {
  color: #acb3bc;
}

.speech-bubble {
  position: relative;
  background: #fff;
  border-radius: 0.4em;
  padding: 15px;
  color: #368cfe !important;
}

.no-select {
  //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-user-select: none;
  user-select: none;
}

.fa-user-md::before {
  box-shadow: 0 5px;
}

.play-btn-container {
  font-size: 22px;
  position: absolute;
  width: 70px;
  height: 70px;
  justify-content: center;
  background-color: #3a3a3a;
  display: flex;
  align-items: center;
  border-radius: 100%;
  opacity: 0.6;

  .icon-play-btn {
    width: 0 !important;
    height: 0 !important;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid white;
    border-radius: 0 !important;
    opacity: 1 !important;
    margin-left: 5px !important;
  }
}

.bt-container {
  padding: 0;

  .row {
    .right-side {
      top: 80px;
    }
  }
}

.red > * {
  box-shadow: inset 0 11px 5px -10px #dc3545, inset 0 -11px 5px -10px #dc3545;
}

.reversed {
  transform: rotate(-180deg);
}

.text-area:focus {
  box-shadow: none;
  border-color: var(--gray-300);
}

.main-area {
  display: flex;
  flex-flow: column;
  align-items: center;

  & > .container {
    margin: 0;
    width: 100%;
    padding: 0;
    flex: 1;

    &:not(.component-generalinfo) {
      max-width: var(--desktop-view);
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &.component-login {
      padding-top: 0;
      padding-bottom: 0;
      width: 100%;
      max-width: 100%;

      .component-inner {
        height: calc(100vh - 80px);

        .component-inner-child {
          height: 100%;
          width: 100%;
          max-width: 100%;

          > div {
            height: 100%;
          }
        }
      }
    }

    &.component-billing {
      max-width: unset;
      background-color: #f6f8fc;

      display: flex;
      align-items: flex-start;
      justify-content: center;

      .component-inner {
        max-width: var(--desktop-view);
        width: 100%;
        padding: 0 32px;
      }
    }

    &.component-generalinfo {
      max-width: unset !important;

      & .content-info {
        max-width: var(--desktop-view);
        margin: auto;
      }
    }
  }
}

@media (max-width: 834px) {
  .bt-container {
    padding: 0;

    .row {
      .right-side {
        top: 0px;
      }
    }
  }
  .component-generalinfo .component-inner .component-inner-child > div {
    padding: 0 16px !important;
  }

  .component-schedule
    .component-inner
    .component-inner-child
    > div
    > .container {
    padding: 0 16px !important;
  }

  .component-sponsors .sponsor-section,
  .component-ebag .sponsor-section {
    padding: 0 16px !important;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: var(--desktop-view);
    padding: 0 32px;
  }
}

.app-wrapper {
  > div:not(.notifications) {
    height: 100%;
  }
}

.btn.cancel {
  border: 1px solid var(--primary-600);
  background: white;
  color: var(--primary-600);
}

.vertical-center {
  white-space: nowrap;
  height: 100%;
  text-align: center;
}

.vertical-center > * {
  vertical-align: middle;
  white-space: normal;
}

.arrow {
  border: solid #3596ee;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;

  &.arrow-up {
    transform: rotate(-135deg);
  }

  &.arrow-down {
    transform: rotate(45deg);
  }

  &.arrow-right {
    transform: rotate(-45deg);
  }

  &.arrow-left {
    transform: rotate(-135deg);
  }
}

:root {
  --desktop-view: 1280px;
  --tablet-view: 834px;
  --mobile-view: 375px;
  --primary-25: #e6f1ff;
  --primary-50: #cce2ff;
  --primary-100: #b3d4ff;
  --primary-200: #9ac5fe;
  --primary-300: #80b7fe;
  --primary-400: #67a8fe;
  --primary-500: #4d9afe;
  --primary-600: #348cfe;
  --primary-700: #1b7dfe;
  --primary-800: #016ffe;
  --primary-900: #0164e4;
  --gray-25: #eaeaeb;
  --gray-50: #d4d4d7;
  --gray-100: #bfbfc2;
  --gray-200: #95959a;
  --gray-300: #7f7f86;
  --gray-400: #6a6a72;
  --gray-500: #55555d;
  --gray-600: #3f3f49;
  --gray-700: #2a2a35;
  --gray-800: #262630;
  --gray-900: #22222a;
  --error-25: #fffbfa;
  --error-50: #fef3f2;
  --error-100: #fee4e2;
  --error-200: #fecdca;
  --error-300: #fda29b;
  --error-400: #f97066;
  --error-500: #f04438;
  --error-600: #d92d20;
  --error-700: #b42318;
  --error-800: #912018;
  --error-900: #7a271a;
  --warning-25: #fffcf5;
  --warning-50: #fffaeb;
  --warning-100: #fef0c7;
  --warning-200: #fedf89;
  --warning-300: #fec84b;
  --warning-400: #fdb022;
  --warning-500: #f79009;
  --warning-600: #dc6803;
  --warning-700: #b54708;
  --warning-800: #93370d;
  --warning-900: #7a2e0e;
  --success-25: #f6fef9;
  --success-50: #ecfdf3;
  --success-100: #d1fadf;
  --success-200: #a6f4c5;
  --success-300: #6ce9a6;
  --success-400: #32d583;
  --success-500: #12b76a;
  --success-600: #039855;
  --success-700: #027a48;
  --success-800: #05603a;
  --success-900: #054f31;
  --blue-gray-25: #fcfcfd;
  --blue-gray-50: #f8f9fc;
  --blue-gray-100: #eaecf5;
  --blue-gray-200: #c8cce5;
  --blue-gray-300: #9ea5d1;
  --blue-gray-400: #717bbc;
  --blue-gray-500: #4e5ba6;
  --blue-gray-600: #3e4784;
  --blue-gray-700: #363f72;
  --blue-gray-800: #293056;
  --blue-gray-900: #101323;
  --blue-light-25: #f5fbff;
  --blue-light-50: #f0f9ff;
  --blue-light-100: #e0f2fe;
  --blue-light-200: #b9e6fe;
  --blue-light-300: #7cd4fd;
  --blue-light-400: #36bffa;
  --blue-light-500: #0ba5ec;
  --blue-light-600: #0086c9;
  --blue-light-700: #026aa2;
  --blue-light-800: #065986;
  --blue-light-900: #0b4a6f;
  --blue-25: #f5faff;
  --blue-50: #eff8ff;
  --blue-100: #d1e9ff;
  --blue-200: #b2ddff;
  --blue-300: #84caff;
  --blue-400: #53b1fd;
  --blue-500: #2e90fa;
  --blue-600: #1570ef;
  --blue-700: #175cd3;
  --blue-800: #1849a9;
  --blue-900: #194185;
  --indigo-25: #f5f8ff;
  --indigo-50: #eff4ff;
  --indigo-100: #e0eaff;
  --indigo-200: #c7d7fe;
  --indigo-300: #a4bcfd;
  --indigo-400: #8098f9;
  --indigo-500: #6172f3;
  --indigo-600: #444ce7;
  --indigo-700: #3538cd;
  --indigo-800: #2d31a6;
  --indigo-900: #2d3282;
  --purple-25: #fafaff;
  --purple-50: #f4f3ff;
  --purple-100: #ebe9fe;
  --purple-200: #d9d6fe;
  --purple-300: #bdb4fe;
  --purple-400: #9b8afb;
  --purple-500: #7a5af8;
  --purple-600: #6938ef;
  --purple-700: #5925dc;
  --purple-800: #4a1fb8;
  --purple-900: #3e1c96;
  --pink-25: #fef6fb;
  --pink-50: #fdf2fa;
  --pink-100: #fce7f6;
  --pink-200: #fcceee;
  --pink-300: #faa7e0;
  --pink-400: #f670c7;
  --pink-500: #ee46bc;
  --pink-600: #dd2590;
  --pink-700: #c11574;
  --pink-800: #9e165f;
  --pink-900: #851651;
  --rose-25: #fff5f6;
  --rose-50: #fff1f3;
  --rose-100: #ffe4e8;
  --rose-200: #fecdd6;
  --rose-300: #fea3b4;
  --rose-400: #fd6f8e;
  --rose-500: #f63d68;
  --rose-600: #e31b54;
  --rose-700: #c01048;
  --rose-800: #a11043;
  --rose-900: #89123e;
  --orange-25: #fffaf5;
  --orange-50: #fff6ed;
  --orange-100: #ffead5;
  --orange-200: #fddcab;
  --orange-300: #feb273;
  --orange-400: #fd853a;
  --orange-500: #fb6514;
  --orange-600: #ec4a0a;
  --orange-700: #c4320a;
  --orange-800: #9c2a10;
  --orange-900: #7e2410;
}
