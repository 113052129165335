@media (max-width: 992px) {
  body > #root {
    .header {
      padding-left: 40px;
      flex-wrap: wrap;
      overflow: auto;
      height: 100%;
    }

    overflow-x: hidden;
  }

  .app-wrapper {
    transition: transform 0.25s ease;
  }

  .mobile-toggled {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .sidebar {
    transform: translateX(-170%);
  }

  .component-schedule main,
  .component-session-inner {
    padding: 0;
  }

  .component-abstractmanagement main,
  .component-session-inner {
    padding: 25px;
  }

  main.container,
  .main-component.container {
    max-width: 100%;
  }
}
