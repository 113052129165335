.sidebar {
  padding-right: 0;
  width: 100%;

  > main {
    height: calc(100% - 91px);
    margin-top: 1px;
  }

  > i {
    display: none;
  }

  > header {
    padding: 15px;
    height: 72px;
    padding-top: 0;

    i {
      display: none;
    }

    .select-advanced {
      padding-left: 10px;

      .select-checkboxer-outer {
        position: relative;
      }

      .options-wrapper {
        li {
          color: #757575;
          font-size: 14px;
          text-align: right;
          border-radius: 4px;
          border-bottom: 1px solid #ddd;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .upper-area {
        background-color: transparent;
        border: none;
        color: black;
        display: block;
        text-align: center;
        padding-right: 0;

        .icon-arrow-down,
        .icon-arrow-up {
          display: none;
        }
      }

      .bottom-area {
        top: calc(100%);
        width: 170px;
        position: absolute;
        left: -95px;

        &::before {
          width: 10px;
          height: 10px;
          border-left: 11px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 15px solid white;
          position: absolute;
          top: -15px;
          right: 16px;
        }
      }
    }
  }
}

.sidebar-wrapper {
  background-color: transparent;

  .header {
    display: flex;
    z-index: 1;
    position: relative;
    margin: auto;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 0;

    .header-logo-container {
      display: flex;
      flex-grow: 1;
      max-width: 30%;

      .header-logo-desktop {
        height: 80%;
        align-self: center;
        object-fit: contain;
        width: 100%;
        object-position: left;
      }
    }

    .select-advanced {
      justify-content: flex-end;
      display: flex;
      align-items: center;
    }

    .register-login-container {
      cursor: pointer;

      .register {
        color: #6a6a6a;
        font-family: Lato;
        font-size: 15px;
        font-weight: bold;
        padding-right: 35px;
        padding-left: 20px;
      }

      .login {
        color: white;
        font-family: Lato;
        font-size: 15px;
        font-weight: bold;
        border-radius: 20px;
        background-color: #008cfe;
        padding: 13px 31px;
      }
    }
  }
}

header {
  > i {
    display: none;
    top: 50%;
  }
}

@media (max-width: 834px) {
  .register-login-container {
    display: none;
  }

  .sidebar {
    position: fixed;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 60%;

    &.toggled {
      > header {
        box-shadow: none;
        width: 100vw;
        justify-content: space-between;

        .header-logo-container {
          display: none;

          .header-logo-desktop {
            display: none;
          }
        }

        .select-advanced {
          display: none;
        }
      }

      i {
        display: block;
        color: black;
        font-size: 22px;
        padding-top: 10px;
        cursor: pointer;
      }
    }

    > main {
      height: 100%;
    }
  }

  .sidebar > header,
  header {
    > i {
      color: black;
      display: block;
      position: absolute;
      top: 20px;
      font-size: 22px;
      margin-top: -11px;
      z-index: 1;
      cursor: pointer;

      &.reversed {
        margin-top: -17px;
        left: 5px;
        color: black;
      }
    }
  }

  header > i {
    left: 25px;
  }

  .register-login-container-mobile {
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
}
