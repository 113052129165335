.line-wrapper-horizontal {
  top: 10px;
  left: calc(-50% + 12px);
  right: calc(50% + 12px);
  border-radius: 1px;
  border-top-width: 3px;
  border-top-style: solid;
  position: absolute;
  display: block;
}

.line-wrapper-vertical {
  top: calc(50% - 14px / 2 + 12px);
  position: relative;
  left: 11px;
  height: 14px;
  border-left: 2px solid;
  margin: 1px 0;
}

.line-wrapper {
  flex: 1 1 auto;
  border-color: var(--gray-200);
}
