.global-loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 992px) {
  .global-loader-container {
    padding-top: 0%;
  }
}
