.rdt {
  position: relative;
}

.rdtOpen .rdtPicker {
  display: block;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0 0 0 / 10%);
  border: 1px solid #f9f9f9;

  .rdtTimeToggle {
    text-align: center;
  }

  table {
    width: 100%;
    margin: 0;
  }

  td,
  th {
    text-align: center;
    height: 28px;
  }

  .rdtTimeToggle:hover {
    background: #eee;
    cursor: pointer;
  }

  td {
    cursor: pointer;

    &.rdtDay:hover,
    &.rdtHour:hover,
    &.rdtMinute:hover,
    &.rdtSecond:hover {
      background: #eee;
      cursor: pointer;
    }

    &.rdtNew,
    &.rdtOld {
      color: #999;
    }

    &.rdtToday {
      position: relative;

      &::before {
        content: '';
        display: inline-block;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #428bca;
        border-top-color: rgba(0 0 0 / 20%);
        position: absolute;
        bottom: 4px;
        right: 4px;
      }
    }

    &.rdtActive {
      background-color: #428bca;
      color: #fff;
      text-shadow: 0 -1px 0 rgba(0 0 0 / 25%);

      &:hover {
        background-color: #428bca;
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0 0 0 / 25%);
      }

      &.rdtToday::before {
        border-bottom-color: #fff;
      }
    }

    &.rdtDisabled {
      background: none;
      color: #999;
      cursor: not-allowed;

      &:hover {
        background: none;
        color: #999;
        cursor: not-allowed;
      }
    }

    span {
      &.rdtOld {
        color: #999;
      }

      &.rdtDisabled {
        background: none;
        color: #999;
        cursor: not-allowed;

        &:hover {
          background: none;
          color: #999;
          cursor: not-allowed;
        }
      }
    }
  }

  .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
  }

  th {
    border-bottom: 1px solid #f9f9f9;

    &.rdtSwitch {
      width: 100px;
    }

    &.rdtNext,
    &.rdtPrev {
      font-size: 21px;
      vertical-align: top;
    }
  }

  th.rdtDisabled {
    background: none;
    color: #999;
    cursor: not-allowed;

    &:hover {
      background: none;
      color: #999;
      cursor: not-allowed;
    }
  }

  thead tr:first-child th {
    cursor: pointer;

    &:hover {
      background: #eee;
    }
  }

  tfoot {
    border-top: 1px solid #f9f9f9;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }
  }

  thead button {
    width: 100%;
    height: 100%;
  }
}

.rdtNext span,
.rdtPrev span {
  display: block;
  //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-user-select: none;
  user-select: none;
}

td {
  &.rdtMonth,
  &.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
  }

  &.rdtMonth:hover,
  &.rdtYear:hover {
    background: #eee;
  }
}

.rdtCounters {
  display: inline-block;

  > div {
    float: left;
  }
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter {
  height: 100px;
  width: 40px;

  .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
    //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-user-select: none;
    user-select: none;

    &:hover {
      background: #eee;
    }
  }

  .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;

  input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
  }
}

.rdtTime td {
  cursor: default;
}
