.btn {
  border-radius: 4px;
  font-weight: 500;

  > i {
    margin-right: 5px;
  }

  &.btn-primary {
    background-color: var(--primary-600);
    color: white;
    border: none;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.btn-reversed {
    background-color: white;
    border: 1px solid var(--primary-600);
    color: var(--primary-600);
  }

  &.btn-orange {
    background-color: white;
    border: 1px solid #ff7043;
    color: #ff7043;
  }
}
