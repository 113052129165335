.abstract-types {
  margin-bottom: 18px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  column-gap: 10px;

  .abstract-type {
    color: var(--gray-700);
    background-color: var(--gray-50);
    width: max-content;
    padding: 2px 10px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .abstract-file-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
  }

  .abstract-pub-id {
    color: var(--gray-600);
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  }
}

.abstract-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: var(--gray-600);
  margin-bottom: 4px;
}

.abstract-topics {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-200);
  margin-bottom: 16px;
}

@media (max-width: 834px) {
  .abstract-topics {
    margin-bottom: 16px;
  }

  .abstract-title {
    width: 100%;
    word-break: break-word;
    word-wrap: break-word;
    hyphens: auto;
    max-width: 100%;
  }
}
