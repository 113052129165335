.warning-bar {
  height: auto;
  margin-top: -40px;
  max-height: 80px;
  background: var(--warning-400);
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  z-index: 3;
  width: 100%;
  position: sticky;
  top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: auto;
  @media (max-width: 1200px) {
    margin-bottom: 40px;
  }
  &.hidden {
    background-color: white;
    position: relative;
    top: -40px;
    width: 100%;
    display: none;
  }

  .warning-bar-inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: var(--desktop-view);
    padding: 5px 32px;

    .wrapper-responsive {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 15px;

      .warning-message {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .warning-icon {
        color: white;
        width: 24px;
        height: 24px;
      }
    }

    .go-to-payments-button {
      background-color: var(--warning-400);
      color: white;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      height: 30px;
      width: max-content;
      margin-left: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 16px;
      border-color: white;
    }
  }
}

@media (max-width: var(--desktop-view)) {
  .warning-bar {
    width: calc(100% - 15px);
  }
}
