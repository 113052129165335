@keyframes wrench {
  0% {
    transform: rotate(-12deg);
  }

  8% {
    transform: rotate(12deg);
  }

  10% {
    transform: rotate(24deg);
  }

  18% {
    transform: rotate(-24deg);
  }

  20% {
    transform: rotate(-24deg);
  }

  28% {
    transform: rotate(24deg);
  }

  30% {
    transform: rotate(24deg);
  }

  100%,
  75% {
    transform: rotate(0);
  }
}
