.products-wrapper {
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  // page components styles

  .link-button {
    cursor: pointer;
    background-color: white;
    border: none;
    color: var(--primary-600);

    &:hover {
      text-decoration: underline !important;
    }
  }
  .round-button {
    bottom: 10%;
    color: white;
    border-radius: 4px;
    font-weight: bold;
    border: 1px solid var(--primary-600);
    background: var(--primary-600);
    width: 100%;
    height: 40px;

    &:hover {
      cursor: pointer;
    }
    .button-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  .add-button {
    color: var(--primary-600);
    cursor: pointer;
    background-color: white;
    border: 1px solid var(--primary-600);
    text-transform: none;
    width: 130px;
    height: 40px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .add-button:hover {
    background-color: var(--primary-600);
    color: white;
  }

  // page components style end

  .products-header {
    margin-bottom: 20px;
    width: 100%;
    height: 47px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    row-gap: 12px;
    position: static;
    border-bottom: 1px solid var(--gray-100);
  }
  .card-container {
    display: flex;
    gap: 20px;

    .all-products-container {
      display: flex;
      flex-direction: column;
      flex: 3;
      gap: 20px;

      .card {
        border-radius: 4px;
        border: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 30px;
        gap: 10px;
        background-color: #ffffff;

        .product-name {
          font-size: 1.5em;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          @keyframes addClamp {
            0% {
              -webkit-line-clamp: unset;
            }
            100% {
              -webkit-line-clamp: 2;
            }
          }
          @keyframes addScroll {
            0% {
              overflow: hidden;
            }
            100% {
              overflow: auto;
            }
          }
          .custom-description {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #7f7f86;
            size: 16px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: max-height 0.5s cubic-bezier(0.4, 0, 0.2, 1) !important;
            max-height: 48px;
            animation: addClamp 1.5s forwards;

            &.expanded {
              animation: addScroll 0.7s forwards;
              -webkit-line-clamp: unset;
              max-height: 250px;
              overflow: auto;
            }
          }
          .price {
            font-weight: 700;
            font-size: 32px;
            min-width: 116px;
            text-align: right;
          }

          .quantity-controls {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }
    .hidden {
      display: none;
    }
    .mycart-container {
      flex: 1;
      background: #ffffff;
      flex-wrap: wrap;
      padding: 20px;
      min-height: 557px;
      height: fit-content;
      min-width: 387px;
      max-width: 387px;

      .cart {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        flex: 1;
        min-height: 517px;
        .cart-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          border-bottom: 1px solid var(--gray-100);
          padding-bottom: 20px;

          .cart-title {
            display: flex;
            justify-content: space-between;
            gap: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            align-items: center;
            color: var(--gray-700);

            h4 {
              margin: 0px;
              font-size: 20px;
            }
          }
          .clear-cart {
            cursor: pointer;
            color: #7f7f86;
            font-size: 12px;
            gap: 10px;
          }

          .clear-cart:hover {
            color: var(--primary-600);
          }
        }
        .cart-body {
          flex: 1;

          .cart-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            gap: 10px;

            .cart-item-details {
              display: flex;
              align-items: flex-start;
              flex-grow: 1;
              align-items: center;
              gap: 10px;
            }
            .cart-item-price {
              font-size: 18px;
              font-weight: 700;
              min-width: max-content;
            }
          }
          .empty-cart-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 90%;
          }
          .primary-message {
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 20px;
          }
          .secondary-message {
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 20px;
            text-align: center;
            color: #6a6a72;
          }
        }
        .cart-footer {
          margin-top: auto;
          text-align: center;
        }
      }
    }
  }
  .mobile-round-button {
    height: 40px;
    border-radius: 4px;
    border: 1px solid var(--primary-600);
    background-color: var(--primary-600);
    color: white;
    cursor: pointer;

    &:hover {
      background-color: var(--primary-600);
    }
    .mobile-button-content {
      font-size: 14px;
      line-height: 20px;
      display: flex;
      gap: 10px;
    }
    .round-button-content {
      color: var(--primary-600);
    }
  }
  .mobile-cart-title {
    display: flex;
    gap: 5px;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    h4 {
      font-size: 16px;
    }
    svg {
      align-self: flex-start;
    }
    .mobile-cart-info {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
  .mobile-cart-body {
    gap: 22px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;

    .mobilecart-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;

      .mobilecart-item-price {
        font-size: 18px;
        display: flex;
        align-items: center;
        font-weight: 700;
      }
    }
  }
  .mobilecart-item-details {
    align-items: center;
    display: flex;
    gap: 5px;
  }

  .custom-accordion {
    &-summary {
      display: flex;
      padding: 0 !important;
      min-height: 80px;
      transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: transparent;
    }
    &-details {
      padding: 0 !important;
      min-height: 80px;
      background-color: transparent;
      display: block !important;
    }
  }
  .description-wrapper {
    display: flex;
    align-items: center;
  }
  .price-button-wrapper {
    display: flex;
    gap: 30px;
    align-items: center;
  }
}

@media (max-width: 834px) {
  .products-wrapper {
    padding: 0 16px;
  }
}

@media (max-width: 1004px) {
  .products-wrapper {
    .card-container {
      display: flex;
      gap: 20px;
      .all-products-container {
        flex-basis: 100%;
        .card {
          flex-basis: 100%;
          border-radius: 4px;
          border: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          background-color: #ffffff;
          gap: 10px;
          padding: 0;
          .details {
            display: flex;
            align-items: center;
            gap: 24px;

            .price {
              font-weight: 700;
              font-size: 16px;
              line-height: 32px;
              text-align: right;
            }
          }
        }
      }
      .mycart-container {
        flex: 1 1;
        background-color: #ffffff;
        padding: 16px;
        flex-wrap: wrap;
        min-width: 387px;
      }
    }

    .product-name {
      font-size: 20px;
      font-weight: 700;
    }
  }
}

@media (max-width: 1004px) {
  .products-wrapper {
    display: flex;

    .card-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .products-wrapper {
    .card-container {
      .all-products-container {
        .card {
          padding: 30px;
          .details {
            flex-wrap: wrap;

            .price {
              font-weight: 700;
              font-size: 32px;
              text-align: left;
            }
          }

          .product-name {
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
          }
        }
      }
    }
    .description-wrapper {
      flex-direction: row;
      justify-content: space-between;
    }

    .price-button-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }

    .cart-mobile-hidden {
      display: block;
    }

    .cartmobile-container {
      position: sticky;
      bottom: 0;
      left: 0;

      display: flex;
      flex-basis: 100%;
      // height: 667px;
      border-radius: 4px;
      box-shadow: 0px -6px 30px 0px #2a2a351a;

      &::before {
        content: "";
        display: block;
        width: 40px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -30px;
        background-color: white;
        z-index: 1;
      }
      &::after {
        content: "";
        display: block;
        width: 40px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -40px;
        background-color: white;
        z-index: 1;
      }
    }

    .mobile-cart {
      z-index: 2;
      .MuiPaper-elevation1 {
        box-shadow: none;
      }
      width: 100%;
      // height: 667px;
      .mobile-cart-info h4 {
        margin: 0;
      }
      .empty-cart-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 90%;
      }
    }
  }
}
