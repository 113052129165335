.cancel-registration-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  border-radius: 15px;
  background-color: white;
  z-index: 105;
  position: fixed;
  top: 30%;
  min-height: 180px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  box-shadow: 5px 3px 20px gray;
  animation-name: grow-modal;
  animation-iteration-count: 1;
  animation-duration: 0.3s;
  width: 420px;
  max-width: 80vw;

  @keyframes grow-modal {
    from {
      transform: scale(0);
      opacity: 0;
    }

    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  .lectical {
    color: var(--gray-700);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 24px;
  }

  .warning-icon-wrapper {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    .warning-icon {
      height: 48px;
      width: 48px;
    }
  }

  .btn-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
      margin-right: 0;
      width: 49%;
    }

    .confirm {
      color: white;
    }

    .cancel {
      color: var(--gray-500);
      border-color: var(--gray-500);
    }
  }

  @media (max-width: 834px) {
    .btn-row {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-flow: column wrap;

      .confirm {
        width: 100%;
      }

      .cancel {
        margin-bottom: 12px;
        width: 100%;
      }
    }
  }
}

@media (max-width: 834px) {
  .cancel-registration-modal {
    top: 56%;
  }
}
