.component-exhibition {
  padding: 40px 32px !important;

  .navigate-back-exhibition {
    width: 85%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-500);
    margin-bottom: 28px;

    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }

    &:hover {
      color: var(--primary-600);
      text-decoration: underline;
    }
  }

  &.component-subpage {
    padding: 40px 16px !important;
    max-width: 1040px !important;
  }

  .row {
    grid-row-gap: 16px;
    margin-right: -8px;
    margin-left: -8px;

    & > div {
      padding: 0 8px;
    }
  }

  .filter-container {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 55px;
    gap: 40px;
    flex-wrap: wrap;

    input {
      width: 275px;
      height: 50px;
      background-color: white;
      //TODO: Once user-select receives native support for safari, this should be changed to not use webkit
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-user-select: none;
      user-select: none;
      outline: none !important;
      padding: 16px 10px;
      border: 1px solid var(--gray-50);
      border-radius: 4px;
    }

    .upper-area {
      width: 275px;
      height: 50px;
      border: 1px solid var(--gray-50);
      border-radius: 4px;
    }
  }

  .exhibitor-container {
    min-height: 400px;

    .normal {
      padding-top: 17px;
      grid-row-gap: 17px;
    }

    .exhibitor-card {
      height: 100%;
      border: 1px solid var(--gray-100);
      border-radius: 4px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      &.empty {
        position: relative;
        background-color: var(--gray-50);
        opacity: 0.5;
        cursor: not-allowed;
        border: 1px solid var(--gray-900);

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            to bottom left,
            transparent calc(50% - 1px),
            var(--gray-900),
            transparent calc(50% + 1px)
          );
          opacity: 0.8;
        }
      }

      img {
        padding: 20px;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .reduced-padding {
      padding: 0;
    }
  }

  .main-content {
    background-color: white;
    border-radius: 4px;

    .gmap {
      height: 100%;
      padding: 15px;
    }

    .exhibition-header {
      position: relative;
      height: 150px;
      width: 100%;
      border-radius: 4px;
      overflow: hidden;

      .background {
        width: 100%;
        object-fit: cover;
      }

      .logo {
        position: absolute;
        left: 50%;
        margin-left: -150px;
        width: 300px;
        height: 150px;
        top: 0%;
        margin-top: 0;
        object-fit: contain;
      }
    }

    .row {
      grid-row-gap: 15px;

      video {
        background: black;
        border-radius: 4px;
      }
    }

    .content-info {
      padding: 16px;

      .description {
        max-height: 100%;
        overflow: auto;
      }
    }

    a:hover {
      text-decoration: none;
    }
  }
}

@media (max-width: 834px) {
  .component-exhibition {
    padding: 40px 16px !important;
  }
}

@media (max-width: 991px) {
  .component-exhibition {
    .row {
      grid-row-gap: 16px;
    }
  }
}

@media (max-width: 702px) {
  .component-exhibition {
    .search-bar {
      width: 100%;

      .search-input {
        width: 100%;
      }
    }

    .multiselect {
      width: 100%;

      .upper-area {
        width: 100%;
      }
    }
  }
}

@media (max-width: 991px) {
  .empty {
    display: none;
  }
}
