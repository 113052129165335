.component-videos.main-component {
  padding: 40px 32px;

  .container {
    padding: 0 !important;
    width: 100%;
    max-width: 100% !important;
    margin: 0 !important;
  }
}

.component-videos .component-inner {
  .video-communication {
    display: flex;
    flex-direction: column;

    .form-group {
      height: max-content !important;
    }

    form {
      display: flex;
      flex-direction: column;
      position: relative;

      .submit-container {
        width: 100% !important;
        position: unset !important;
      }
    }
  }

  .video-communication > div {
    padding: 10px 5px 15px;
    border-radius: 10px;
    margin-bottom: 1rem;
    box-shadow: 0 -15px 163px 0 rgba(0 0 0 / 20%);
  }

  .video-container {
    margin: 0;
  }

  .video-gallery {
    padding: 8px;
    background-color: white;
    margin-bottom: 30px;
  }
}

.title {
  color: #404f54;
  font-family: Lato;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 41px;
  padding-left: 0;
}

.live {
  color: red;
  position: absolute;
  bottom: 20px;
  right: 25px;
}

.navigate-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px !important;
  border-radius: 15px;
  background-color: white;
  z-index: 5;
  position: fixed;
  top: 30%;
  min-height: 180px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  box-shadow: 5px 3px 20px gray;
  animation-name: grow-modal;
  animation-iteration-count: 1;
  animation-duration: 0.3s;
  width: 420px !important;
  max-width: 80vw !important;

  .btn-row {
    display: flex;
    justify-content: space-between;

    div {
      flex: 1;
    }
  }

  @keyframes grow-modal {
    from {
      transform: scale(0);
      opacity: 0;
    }

    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  .btn {
    width: 100px !important;
  }

  .confirm {
    color: white;
    margin-right: 20px;
  }
}

.react-jw-player-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.search-panel {
  display: flex;
  flex-grow: 1;
  margin-left: 0;
  margin-right: 0;

  input:focus,
  select:focus,
  textarea:focus,
  div:focus,
  button:focus {
    outline: none;
  }

  position: relative;
  top: 0;
  left: 0;
  min-height: 80px;
  height: auto;
  padding: 0 0 20px;

  .filter-title-videos {
    height: 50px;
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 6px;
  }

  i {
    color: #404f54;
    font-family: Lato;
    font-size: 34px;
    display: flex;
    align-items: center;

    span {
      color: #404f54;
      font-family: Lato;
      font-size: 13px;
      font-weight: 400;
    }
  }

  .title {
    color: #404f54;
    font-family: Lato;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 41px;
    padding-left: 0;
    display: flex;
    align-items: center;
  }

  & > div,
  & > div > div {
    padding: 0 0 0 10px;
    min-height: 40px;
    display: inline-flex;
    flex-grow: 1;
    position: relative;
    bottom: 0;
  }

  & > div input {
    color: #435670;
    position: relative;
    bottom: 0;
    padding-left: 15px;
    font-size: 14px;

    &::placeholder {
      color: #435670;
      opacity: 1;
    }

    width: 100%;
    border: none;
  }

  .clear-filters {
    justify-content: end;
    min-height: 27px;
    padding-top: 5px;
    font-size: 14px;

    span {
      cursor: pointer;
      color: var(--primary-600);
    }
  }

  .panel-select {
    border: none;
    width: 100%;
    padding: 0;

    .select-checkboxes-outer,
    .upper-area {
      width: 100%;
      height: 50px;
    }

    .upper-area {
      bottom: 0;
      position: relative;
      border: none;
      border-radius: 0;
      padding-right: 25px;
    }

    .bottom-area {
      position: absolute !important;
      top: 53px;
      max-height: 350px;
    }
  }
}

.single-video {
  padding: 15px 30px 30px;
  background: white;

  &.warning-bar-active {
    margin-top: 40px;
  }

  .form-control {
    border: 1px solid #dbe3ee;
  }

  .form-control:focus {
    box-shadow: none;
  }

  .video-title {
    margin-top: 30px;
    color: #404f54;
    font-family: Lato;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 5px;
  }

  .video-subtitle {
    color: #404f54;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0;
  }

  .cta-container {
    margin: 30px 0;
    padding: 5px 0;

    .cta-url-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      .cta-btn {
        height: auto;
        overflow-wrap: break-word;
        white-space: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: var(--primary-600);
      }
    }

    .cta-title {
      overflow-wrap: break-word;
      padding-left: 0;
      white-space: normal;
    }

    .cta-desc {
      padding-left: 0;
      overflow-wrap: break-word;
      font-size: 14px;
    }
  }

  .mobile-selector {
    display: flex;
    justify-content: space-around;
    padding-bottom: 25px;

    span {
      color: #404040;
      font-family: Lato;
      font-size: 18px !important;
      letter-spacing: 0;
      width: 50%;
      text-align: center;
      cursor: pointer;
      padding-bottom: 20px;
    }

    .active {
      border-bottom: 1px solid var(--primary-600);
      color: var(--primary-600);
    }
  }

  .video-chat-container {
    display: flex;
    justify-content: center;

    .player-container {
      position: relative;
      padding: 56.25% 0 0;
    }

    .player-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .chat-container {
      flex-grow: 1;
      height: 530px;
    }

    .video-container {
      flex-grow: 20;
      position: relative;
      max-width: 940px;
      margin: auto;
    }
  }

  .sponsor-container {
    margin-top: 30px;
    min-height: 150px;
    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-evenly;

    img {
      max-height: 150px;
      height: 100%;
      width: auto;
      object-fit: contain;
      cursor: pointer;
      transform: scale(1);
      transition: all ease 0.2s;
      max-width: 100%;
    }

    img:hover {
      transform: scale(1.05);
    }
  }
}

h3.title {
  color: #368cfe;
  text-align: center;
  padding: 30px;

  .fas.fa-wifi {
    transform: rotate(45deg);
  }
}

.kmb-jwp-container {
  color: #404040;
  padding: 8px;
  background: white;
  height: 100%;

  .thumbnail-container {
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 22px;
      color: white;
      position: absolute;
      width: 70px;
      height: 70px;
      justify-content: center;
      background-color: #3a3a3a;
      display: flex;
      align-items: center;
      border-radius: 100%;
      opacity: 0.6;
    }
  }

  .video-title {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 40px;
    white-space: nowrap;
    margin-top: 5px;
    color: #404f54;
    font-family: Lato;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
  }

  .video-info {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #404f54;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
  }

  .video-thumbnail {
    width: 100%;
    height: 100%;
    background: black;
    padding-top: 56.25%;
    position: relative;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: round;
    background-size: 100%;
  }

  .container {
    min-height: auto !important;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    color: white;
    opacity: 0;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .background {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0 86 131 / 5%);
      z-index: 0;
      box-shadow: inset 0 0 10px #c7c7c7;
    }

    .fas {
      position: relative;
      z-index: 1;
      text-shadow: 3px 3px #368cfe;
    }
  }

  .container:hover {
    opacity: 1;
  }
}

@media (max-width: 1300px) {
  .chat-container {
    height: 450px !important;
  }
}

@media (max-width: 1200px) {
  .chat-container {
    height: 400px !important;
  }
}

@media (max-width: 1100px) {
  .chat-container {
    height: 350px !important;
  }
}

@media (max-width: 991px) {
  .search-panel {
    padding-top: 0;

    .filter-title-videos {
      padding-right: 0;
    }

    > div {
      padding-right: 15px;
      padding-left: 0;

      :last-child {
        padding-right: 0;
      }
    }

    & > div input {
      max-height: 50px;
      min-height: 40px;
    }

    div:last-child {
      padding-right: 0;
    }

    .rooms {
      padding-right: 0;
    }
  }

  .single-video {
    background-color: white;

    .video-chat-container {
      flex-direction: column;
    }

    .mobile-selector {
      padding-bottom: 25px !important;

      span {
        font-size: 15px !important;
      }

      .active {
        border-bottom: 1px solid var(--primary-600);
        color: var(--primary-600);
      }
    }
  }

  .video-container {
    .player-container {
      min-height: 260px;
    }

    .chat-container {
      min-height: 260px;
    }
  }

  .component-videos {
    .component-inner {
      .container {
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin: 0;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 576px) {
  .component-videos.main-component {
    padding: 0;
  }

  .video-container {
    .player-container {
      min-height: auto;
    }
  }

  .single-video {
    .video-chat-container {
      .player-container-iframe {
        min-height: 40vh;
      }
    }
  }

  .search-panel {
    div:last-child {
      padding-right: 0;
    }
  }
}
