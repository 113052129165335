@media (max-height: 1024px) {
  .content-wrapper {
    padding-top: 16px !important;

    .abstract-types,
    .abstract-title,
    .abstract-topics {
      display: none;
    }
  }

  .file-bottom-info {
    display: none;
  }
}

.backdrop-file-viewer {
  z-index: 5 !important;
  backdrop-filter: blur(3px) grayscale(35%);

  .file-viewer-wrapper {
    width: max-content;
    max-width: 960px;
    height: 96vh;
    margin: 32px;
    background-color: white;
    display: flex;
    flex-direction: column;

    .top-toolbar {
      width: 100%;
      height: 64px;
      border-bottom: 1px solid var(--gray-50);
      display: flex;

      .toolbar-actions {
        flex: 1;
        display: flex;
        align-items: center;
        column-gap: 12px;
        margin-left: 30px;

        .download-file {
          background-color: white;
          border: 1px solid var(--gray-300);
          height: 40px;
          box-shadow: none;
          text-transform: none;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--gray-700);
        }

        .page-indicator {
          margin-left: 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .page-selector {
            width: max-content;
            margin-right: 6px;
            min-width: 16px;

            input {
              max-width: 28px;
              text-align: end;
              padding: 0;
            }
          }

          .total-page-text {
            width: max-content;
            min-width: 24px;
          }
        }
      }

      .close-action-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .close-file-viewer-wrapper:hover {
          background-color: unset;
          color: var(--primary-600);
        }
      }
    }

    .content-wrapper {
      padding: 34px 50px 16px;
      display: flex;
      flex-direction: column;
      flex: 1;
      border-bottom: 1px solid var(--gray-50);

      .image-viewer {
        width: 618px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .file-bottom-info {
      width: 100%;
      height: max-content;
      padding: 16px 50px 20px;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--gray-25);
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--gray-50);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--gray-100);
  }
}

@media (max-width: 960px) {
  .backdrop-file-viewer {
    .file-viewer-wrapper {
      width: 100%;
      height: 100%;
      margin: 0;

      .top-toolbar {
        .toolbar-actions {
          .page-indicator {
            flex: 1;
            justify-content: flex-end;
            align-items: center;
            display: flex;
            margin-right: 24px;
          }
        }
      }
    }
  }
}
