.confirmation-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204 204 204);
  background: white;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  max-width: 440px;
  max-height: 310px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;


.modal-content {
    border: none;
    padding: 20px 25px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

  .stay-button {
   background-color: #14da9e;
    background-color: var(--success);
    border: 1px solid #14da9e;
    border: 1px solid var(--success);
    box-shadow: none;
    color: #fff;
    font-weight: 500;
    padding: 8px 23px;
    text-transform: uppercase;
    width: 120px;
    cursor: pointer;
    border-radius: 4px;


    &:hover{
     opacity: .5;
    }
  }

  button.btn.btn-red {
    background-color: #f04438;
    border-color: #f04438;
    color: #fff;
    font-weight: 500;
    padding: 8px 23px;
    text-transform: uppercase;


  &:hover {
    opacity: .5;
  }
}

h5 {
  border-bottom:  1px solid var(--gray-200);
  padding: 8px;
  margin-bottom: 25px;
  font-size: 24px;
  padding-left: 0;
}

  button:last-child {
    margin-right: 0;
  }

  .button-container {
    display: flex;
    justify-content:flex-start;
    width: 100%;
    margin-top: 20px;
    gap: 20px;
}

}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0 0 0 / 75%);
  z-index: 10;
}
.MuiTypography-gutterBottom {
  text-align: justify;
  margin-bottom: 0.35em;
}


@media (max-width: 600px) {
  .confirmation-modal {
    width: 95%;
    max-width: none;
    max-height: none;
    height: auto;
    padding: 10px;
  }

  .modal-content {
    padding: 15px;
  }

  .stay-button,
   button.btn.btn-red {
    padding: 10px 15px;
    width:250px;
  }

  h5 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .button-container {
    flex-direction: row;
    align-items: center;
  }
}