.recaptcha-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100px;
  border: 1px solid var(--gray-50);
  background: var(--gray-25);
  border-radius: 4px;
  padding-top: 3px;
  min-width: max-content;
  width: 324px;

  &.login,
  &.questionnaire {
    margin: 16px 0 30px;
  }

  &.register {
    margin-top: 30px;
  }

  &.message {
    margin-top: -16px;
    margin-bottom: 16px;
  }
}

.forgot-pass-recaptcha-positioner,
.register-recaptcha-positioner {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
