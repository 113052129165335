.abstract-authors {
  margin-bottom: 6px;

  .abstract-authors-title {
    color: var(--gray-600);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  .abstract-authors-list {
    display: flex;
    flex-flow: row wrap;
    column-gap: 15px;

    .author-entry {
      width: max-content;
      display: flex;
      flex-direction: row;
      column-gap: 6px;
      align-items: center;

      .author-speech-bubble {
        height: 15px;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          padding: 0;
          height: 16px;
          width: 16px;

          span {
            margin-top: 1px;

            svg {
              width: 18px;
            }
          }
        }
      }

      .author-name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray-600);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .author-institute-index {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: var(--gray-300);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      &.corresponding-author {
        cursor: pointer;

        &:hover {
          .author-speech-bubble {
            .MuiIconButton-label > svg > path {
              fill: var(--primary-600);
            }
          }

          .author-name,
          .author-institute-index {
            color: var(--primary-600);
          }
        }
      }
    }
  }
}

.abstract-institutes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 6px;

  .institute-entry {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--gray-600);
  }
}

.file-bottom-info {
  .abstract-institutes {
    flex-flow: row wrap;
    justify-content: flex-start;
    column-gap: 14px;
  }
}

.correspondance-note {
  color: var(--gray-600);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
