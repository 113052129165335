ul.breadcrumbs {
  padding-left: 0;

  li {
    display: inline-block;
    font-size: 13px;
    color: #103356;

    &::after {
      content: '/';
      padding: 0 5px;
      color: #103356;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    &.clickable {
      color: var(--primary-600);
      cursor: pointer;
      font-size: 17px;
    }
  }
}
